import React, { useRef } from "react";
import { useEffect, useState } from "react";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useAuth } from "../Contexts/AuthContext";
import PageLink, { PageLinkProps } from "./Link";
import { getUserBalance } from "../Services/jwtService";
import numeral from "numeral";
import UserDetail from "./User Pages Components/common/NavbarItems/UserDetail";
import { useWallet } from "../Contexts/WalletContext";
import LoggedInUserBox from "./LoggedInUserBox";
import EmailConfirmationPopup from "./common/confirmation/email_confirmation";
import NavbarDropdown from "./Navbar/NavbarDropdown";
import { Common } from "./common/Common";

const Navbar = ({
  opener,
  setOpener,
  connectWalletFunction,
  showConnectWallet,
}: // account,
{
  opener?: boolean;
  setOpener?: React.Dispatch<React.SetStateAction<boolean>>;
  connectWalletFunction: (showState: boolean) => void;
  showConnectWallet: boolean;
  // account: undefined | String;
}) => {
  const location = useLocation();
  const params = useParams();
  const { portal } = params;
  const [currentRoute, setCurrentRoute] = useState("");
  const [userSideHamburger, setUserSideHamburger] = useState(false);
  const [hamburger, setHamburger] = useState(false);

  const {
    isConnecting,
    connect,
    token,
    account,
    disconnect,
    network,
    setNetwork,
    user,
  } = useWallet();

  useEffect(() => {
    if (opener) {
      setHamburger(opener);
    }
  }, [opener]);

  useEffect(() => {
    if (opener) {
      setHamburger(opener);
    }
  }, [opener]);

  const tickets = (user as { numberOfTickets: number })?.numberOfTickets;

  const telegram = (user as { telegram: string })?.telegram;

  const email = (user as { email: string })?.email;

  const username = (user as { userName?: string })?.userName;
  const [balance, setBalance] = useState(tickets * 0.99);

  const user_name = username;
  const account_code = "account code xxxxx";

  const [accountname, setAccountName] = useState("");

  // Retrieve username from localStorage
  const savedAccount = localStorage.getItem("account");
  useEffect(() => {
    if (savedAccount) {
      setAccountName(savedAccount); // Update state with the username
      // } else if (accountname != undefined && accountname != "") {
      //     setAccountName(accountname);
    }
  }, [savedAccount]);

  useEffect(() => {
    if (user) {
      setBalance(tickets * 0.99);
    }
  }, [user]);

  useEffect(() => {
    async function getRoute() {
      const route = location.pathname;
      await setCurrentRoute(route);
    }

    getRoute();
  }, [portal]);

  const navLinks: PageLinkProps[] = [
    {
      src: "/",
      content: "Home",
    },
    {
      src: "/competitions/all",
      content: "Competitions",
    },
    {
      src: "/howtoplay",
      content: "How to Play",
    },
    // {
    //   src: "/rng",
    //   content: "RNG",
    // },
    {
      src: "/winners",
      content: "Winners",
    },
    // {
    //   src: "#",
    //   content: "Free Giveaway",
    // },
  ];

  const userPagesnavLinks: PageLinkProps[] = [
    {
      src: "/buytickets",
      content: "Buy Tickets",
    },
    {
      src: "/wallet",
      content: "My Wallet",
    },
    {
      src: "/entries/live",
      content: "Entries",
    },
    {
      src: "/orders",
      content: "My Orders",
    },
    {
      src: "/account",
      content: "My Account",
    },
  ];

  const navigate = useNavigate();

  const logoutHandler = () => {
    disconnect(); // Ensure 'disconnect' is a valid function and properly imported or defined.
    navigate("/"); // Ensure 'navigate' is initialized using useNavigate().
  };

  const [shareDropdown, setShareDropdown] = useState(false);

  const handleShareDropdown = () => {
    setShareDropdown(!shareDropdown);
  };

  const handleClickOutsideShareDropdown = (e: any) => {
    if (e.target.closest(".share-dropdown")) {
      return;
    }
    setShareDropdown(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideShareDropdown);
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutsideShareDropdown
      );
    };
  }, []);

  return (
    <>
      {/* {account && token && !isConnecting && email == null ? ( */}
      {account && token && !isConnecting && email === null ? (
        <EmailConfirmationPopup></EmailConfirmationPopup>
      ) : (
        <></>
      )}
      <div
        id="navbar"
        className="//p-10 mr-[40px] ml-[20px] pt-0 md:hidden sm:hidden sticky top-2 z-[88] flex mt-[2em]"
      >
        <div className="flex items-center ml-[1.5em] gap-4">
          <Link to={"/"}>
            <img
              src="/Images/header_image.png"
              alt=""
              className="h-[73.57px] min-w-[145.26px]"
            />
          </Link>
        </div>
        <div className="h-[73.62px] w-full flex items-center justify-between bg-[#1A1A1A] //rounded-r-[5px] //px-[29px] px-2 rounded-r-xl">
          {/* <div className="flex items-center ml-[1.5em] gap-4">
                        <Link to={"/"}>
                            <img src="/Images/logo.png" alt="" className="" />
                        </Link>
                    </div> */}
          <div className="flex flex-1 justify-left lg:justify-center gap-8 font-['Sequel100WideVF45']">
            {navLinks &&
              navLinks.map((link, index) => (
                <PageLink
                  src={link.src}
                  content={link.content}
                  className={`${
                    link.src === currentRoute
                      ? "text-primary font-['Sequel100Wide95']"
                      : "font-['Sequel100WideVF45'] text-white"
                  } uppercase text-[15.26px] lg:text-[clamp(9px,0.9vw,12px)] `}
                  key={index}
                />
              ))}
          </div>
          <div className="flex justify-between items-center gap-4">
            <div
              className="share-dropdown flex items-center gap-2 relative"
              onClick={handleShareDropdown}
            >
              <img
                src="/Images/share_icon.svg"
                alt=""
                className="w-[32px] h-[32px] cursor-pointer "
              />
              <img
                src="/Images/down_icon.svg"
                alt=""
                className=" cursor-pointer "
              />
              {shareDropdown && (
                <ShareDropdown onClose={() => setShareDropdown(false)} />
              )}
            </div>
            {account && token ? (
              // <LoggedInUserBox
              //     accountname={account.substring(0, 12) + ".."}
              //     ticketsNum={"241"}
              //     logoutButtonHandler={disconnect}
              // ></LoggedInUserBox>
              <UserDetail
                // name={accountname?.substring(0, 12) + ".."}
                name={accountname}
                tickets={tickets}
                logoutButtonHandler={logoutHandler}
              />
            ) : (
              <button className="bg-primary m-[1em]  text-lg lg:text-[12px] font-semibold font-[MontBlancBold] w-[200px] h-[62px] rounded-[5px] ">
                <div
                  onClick={() => connectWalletFunction(true)}
                  className="flex flex-wrap justify-between flex-row  px-[30px]"
                >
                  <p
                    className="text-black font-['Sequel100Wide']
                                   uppercase text-l"
                  >
                    CONNECT <br />
                    WALLET
                  </p>
                  <img
                    src="/Images/download-icon.svg"
                    alt=""
                    className="sm:w-[20px] cursor-pointer rounded-full"
                  ></img>
                </div>
              </button>
            )}
          </div>{" "}
        </div>
        <div
          style={{ display: userSideHamburger ? "block" : "none" }}
          className=" mt-0 mb-0 relative   z-[88]"
        >
          <div className="flex flex-col gap-4 w-full p-12 absolute bg-[#2b2b2b]">
            <img
              src="/Images/coin_colored_half.svg"
              alt=""
              className="absolute bottom-0 right-0 opacity-50 select-none pointer-events-none "
            />
            <div className="flex items-center justify-between">
              <Link
                to={"/wallet"}
                className={`${
                  currentRoute === "/wallet"
                    ? "text-primary font-extrabold"
                    : "font-normal text-white"
                } uppercase text-[18px] `}
              >
                {" "}
                My Wallet
              </Link>
            </div>

            <div className="flex items-center justify-between">
              <Link
                to={"/entries/live"}
                className={`${
                  currentRoute === "/entries/live"
                    ? "text-primary font-extrabold"
                    : "font-normal text-white"
                } uppercase text-[18px] `}
              >
                {" "}
                Entries
              </Link>
            </div>

            <div className="flex items-center justify-between">
              <Link
                to={"/orders"}
                className={`${
                  "/orders" === currentRoute
                    ? "text-primary font-extrabold"
                    : "font-normal text-white"
                } uppercase text-[18px] `}
              >
                {" "}
                My Orders
              </Link>
            </div>
            <div className="flex items-center">
              <Link
                to={"/account"}
                className={`${
                  currentRoute === "/account"
                    ? "text-primary font-extrabold"
                    : "font-normal text-white"
                } uppercase text-[18px] `}
              >
                {" "}
                My Account
              </Link>
            </div>
            <div className="flex items-center justify-between">
              <Link
                to={"/buytickets"}
                className={`${
                  currentRoute === "/buytickets"
                    ? "text-primary font-extrabold"
                    : "font-normal text-white"
                } uppercase text-[18px] `}
              >
                {" "}
                Buy Tickets
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* for mobile screen */}

      <div className="xl:hidden flex md:flex lg:hidden">
        <div className=" items-center ml-[3%] gap-4 xl:block  hidden">
          <Link to={"/"}>
            <img
              src="/Images/header_image.png"
              alt=""
              className="h-[112px] min-w-[180px] w-[100%]  flex items-center gap-4"
            />
          </Link>
        </div>

        <div
          id="navbar-mobile"
          className="z-[89] xl:w-[80%] h-[99px] w-full //h-[112px] //pt-3  relative  bg-[#404040] xl:mr-[3%] rounded-r-[5px] xl:block flex justify-between items-center"
        >
          <Link className="ml-[3%]" to={"/"}>
            <img src="/Images/logo_small_header.svg" alt="" />
          </Link>
          <div className="w-min flex gap-[8px] justify-center items-center //z-[88] h-full">
            {user && network && network === "ethereum" ? (
              <img
                src="/Images/ethereum-top.svg"
                alt=""
                className="h-[31px] w-[31px]"
              />
            ) : network && network === "base" ? (
              <img
                src="/Images/base-top.svg"
                alt=""
                className="h-[31px] w-[31px]"
              />
            ) : network && network === "solana" ? (
              <img
                src="/Images/solana-top.svg"
                alt=""
                className="h-[31px] w-[31px]"
              />
            ) : network && network === "btc" ? (
              <img
                src="/Images/btc-top.svg"
                alt=""
                className="h-[31px] w-[31px]"
              />
            ) : (
              <Common.typography
                className="uppercase w-min text-right below-370:!text-[5px] below-370:ml-[10px]"
                fontSize="9.76px"
                fontSizeSmall="9.76px"
                color="#DDE404"
                fontFamily="Sequel100Wide-65"
              >
                Connect Wallet
              </Common.typography>
            )}
            {user ? (
              <img
                src="/Images/power_pink.svg"
                alt=""
                onClick={() => {
                  disconnect();
                }}
              />
            ) : (
              <img
                src="/Images/power_white.svg"
                alt=""
                onClick={() => connectWalletFunction(true)}
              />
            )}
            <div
              className="share-dropdown flex flex-row items-center justify-between gap-[8px] cursor-pointer relative"
              onClick={handleShareDropdown}
            >
              <img
                // src="/Images/icon_x.svg"
                src="/Images/share_icon.svg"
                alt=""
                className="min-w-[32px] h-[32px] cursor-pointer "
              />
              <img
                src="/Images/down_icon.svg"
                alt=""
                className=" cursor-pointer "
              />
              {shareDropdown && (
                <ShareDropdown onClose={() => setShareDropdown(false)} />
              )}
            </div>
          </div>
          <div className="flex items-left justify-center relative w-[99px] //bg-[#323232] h-full items-center">
            <div
              // className={hamburger ? "open" : "hamburger "}
              onClick={() => {
                if (hamburger) {
                  connectWalletFunction(false);
                }
                setHamburger(!hamburger);
              }}
            >
              {/* <span className="top"></span>
              <span className="middle"></span>
              <span className="bottom"></span> */}

              {hamburger ? (
                <img src="/Images/burger-menu-close.svg" alt="" />
              ) : (
                <img src="/Images/burger-menu.svg" alt="" />
              )}
            </div>
          </div>
        </div>
      </div>

      {hamburger && (
        <div className="//relative absolute inset-x-0 z-[88] mobileNav mt-0 //opacity-[0.95] h-full !backdrop-filter backdrop-blur-lg !bg-[#2b2b2b62]">
          <NavbarDropdown
            tickets={tickets}
            navLinks={navLinks}
            currentRoute={currentRoute}
            walletAddress={account as string}
            name={telegram}
            profileImage={user?.imageUrl || "/Images/No Image.svg"}
          />
        </div>
      )}
    </>
  );
};

const ShareDropdown = ({ onClose }: { onClose: () => void }) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="w-[39.19px] flex flex-col justify-center items-center gap-2 bg-[#5F5F5F] rounded-[7.78px] absolute -inset-x-2 top-0 min-h-[142px] py-1">
      <img className="h-[32px] w-[32px]" src="/Images/share_icon.svg" alt="" />
      <img
        className="h-[32px] w-[32px]"
        src="/Images/tg_icon.svg"
        alt=""
        onClick={() =>
          window.open("https://t.me/theprizeannouncements", "_blank")
        }
      />
      <img
        onClick={() => {
          window.open("https://x.com/theprize_io", "_blank");
        }}
        className="h-[32px] w-[32px]"
        src="/Images/x_icon.svg"
        alt=""
      />
    </div>
  );
};

export default Navbar;
