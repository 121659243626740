import React, { useEffect, useState } from "react";
import { Text } from "../react-responsive-utilities/src";
import { TicketData, TicketResponse } from "../NumberPicker";
import { getAvailableTickets } from "../../Services/userService";
import Entries from "../common/Entries/Entries";

const LiveCompetitionFAQDivNew = ({
  className,
  fontSize,
  isInstant,
}: {
  className: string;
  fontSize: string;
  isInstant: boolean;
}) => {
  const [entries, setEntries] = useState<TicketData[]>([]);
  const [active, setActive] = useState(-1);
  const [loading, setLoading] = useState<boolean>(false);
  const competitionId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];
  const [iteration, setIteration] = useState(1);
  const [displayedPageContents, setDisplayedPageContents] = useState<
    TicketResponse[]
  >([]);
  const initialCurrentPageContents: TicketResponse = {
    data: [],
    currentPage: 0,
    pageSize: 0,
    totalItems: 0,
    totalPages: 0,
  };
  const [currentPageContents, setCurrentPageContents] =
    useState<TicketResponse>(initialCurrentPageContents);
  const [items, setItems] = useState<number[]>([]);

  // Fetch tickets for a given page index
  // const fetchTickets = async (pageIndex: number) => {
  //   setLoading(true);

  //   const response = await getAvailableTickets(competitionId, {
  //     page: pageIndex,
  //     limit: 100,
  //   });

  //   setCurrentPageContents(response.data);
  //   setLoading(false);
  // };

  const fetchTickets = async (startFrom: number) => {
    setLoading(true);
    const response1 = await getAvailableTickets(competitionId, {
      page: startFrom,
      limit: 100,
    });
    const response2 = await getAvailableTickets(competitionId, {
      page: startFrom + 1,
      limit: 100,
    });
    const response3 = await getAvailableTickets(competitionId, {
      page: startFrom + 2,
      limit: 100,
    });
    const response4 = await getAvailableTickets(competitionId, {
      page: startFrom + 3,
      limit: 100,
    });
    const response5 = await getAvailableTickets(competitionId, {
      page: startFrom + 4,
      limit: 100,
    });
    setDisplayedPageContents([
      response1.data,
      response2.data,
      response3.data,
      response4.data,
      response5.data,
    ]);
    if (currentPageContents === initialCurrentPageContents)
      setCurrentPageContents(response1?.data);
    setCurrentPageContents(response1?.data);
    setLoading(false);
  };

  // Effect to fetch the initial set of tickets
  useEffect(() => {
    if (iteration === 1) {
      fetchTickets(1);
    } else fetchTickets(5 * iteration - 5);
  }, [iteration]);

  // Effect to update entries based on current page data
  useEffect(() => {
    const newEntries = Array.isArray(currentPageContents.data)
      ? currentPageContents.data.map((entry) => {
          return {
            Ticket: entry.Ticket,
            Date: entry.Date === null ? "N/A" : entry.Date,
            Name: entry.Name === null ? "N/A" : entry.Name,
          };
        })
      : [];
    setEntries(newEntries);
  }, [currentPageContents]);

  const faqs = [
    {
      id: 1,
      content: "1. What is ThePrize.io?",
      description:
        "ThePrize.io is a crypto-based competition platform where you can win exciting digital prizes and luxury items by entering various competitions using both cryptocurrency and soon fiat. ",
    },
    {
      id: 2,
      content: "2. How do I enter a competition?",
      description: `First, ensure you have opened an account on our website. Select the number of tickets you wish to purchase in your account section and BUY TICKETS. Then choose the competition you wish to enter. 

 <div class='h-4 w-4'></div>

        You can then select a specific ticket number or use our lucky dip. ENTER NOW and answer a simple question and confirm your ticket selection or follow the instructions on how to enter for free.
        <div class='h-4 w-4'></div>
        When you have paid your entry fee, you will be told if you have answered the question correctly. If you have, your name will be entered in the draw with all of the other correct entries. If you have not answered correctly, you will not be entered into the draw. 

        <div class='h-4 w-4'></div>

 Anyone entering for free will not be told whether they have answered the question correctly and will not receive confirmation that they are entered into the draw. 

 <div class='h-4 w-4'></div>

However, an entry list is published and therefore all entrants should check this to ensure they have been entered into the draw for the competition they have entered. 
        `,
    },
    {
      id: 3,
      content: "3. Does ThePrize.io have a gambling licence? ",
      description: `No, due to the way the platform is structured we are free from gambling regulations by law in Europe. Please see our <a href="/policies/t&c" class="text-blue-500 underline">Terms & Conditions</a>, <a href="/policies/acceptableuse" class="text-blue-500 underline">Acceptable Use Policy</a>, <a href="/policies/privacy" class="text-blue-500 underline">Privacy Policy</a> and <a href="/policies/termsofuse" class="text-blue-500 underline">Website Terms of Use</a>.`,
    },
    {
      id: 4,
      content: "4. What cryptocurrencies are accepted? ",
      description:
        "We accept a range of popular cryptocurrencies, including Bitcoin (BTC), Ethereum (ETH), Tron (TRX), Base (BASE) and Solana (SOL) with more being added soon. ",
    },
    {
      id: 5,
      content: "5. How are winners selected?",
      description:
        "Winners are chosen using Chainlink VRF RNG, ensuring a fair and transparent process. You will be able to view the tx hash of the draw on our site, and will be notified if you have won. ",
    },
    {
      id: 6,
      content: "6. When do you announce the winners?",
      description:
        "Winners are announced in the Telegram Announcements group and across our socials once the competition timer runs out or when all tickets are sold, you will also be notified in the MY ACCOUNT section if you have won. ",
    },
    {
      id: 7,
      content: "7. What happens if I win?",
      description:
        "If you win, you’ll receive your prize in the form of cryptocurrency directly to your digital wallet. We may also feature your username in our Winners Hall of Fame! If you have won a luxury item, we will contact you to arrange delivery.. ",
    },
    {
      id: 8,
      content: "8. Is there a limit to the number of tickets I can buy?",
      description:
        "The number of tickets you can purchase per competition may vary. Please check the specific competition's details for any limitations. ",
    },
    {
      id: 9,
      content: "9. Can I get a refund if I change my mind?",
      description:
        "All ticket purchases are final. Once you've entered a competition, refunds are not available, so please ensure you’re confident before buying your tickets. ",
    },
    {
      id: 10,
      content: "10. How do I contact customer support?",
      description: `For any inquiries or issues, you can reach our customer support team through the contact form on our website or by emailing us directly at <span class="text-blue-500 underline cursor-pointer" onclick="window.open('mailto:contact@theprize.io', '_blank')">contact@theprize.io</span>`,
    },
    {
      id: 11,
      content: "11. How do I stay updated on new competitions?",
      description: `To stay updated on our latest competitions and announcements, follow us on social media at <a href="https://x.com/theprize_io" class="text-blue-500 underline">X</a>, <a href="https://t.me/theprizeannouncements" class="text-blue-500 underline">Telegram</a>`,
    },
    {
      id: 12,
      content: "12. My question hasn’t been answered here?",
      description: `If you have any questions that have not been answered here, please email us at <a href="mailto:contact@theprize.io" class="text-blue-500 underline">contact@theprize.io</a> and we will happily answer them for you.`,
    },
  ];

  const changeDropdown = (index: number) => {
    if (active === index) {
      setActive(-1);
    } else {
      setActive(index);
    }
  };

  const isCurrentPage = (page: TicketResponse) => {
    return page === currentPageContents;
  };

  const changePage = (page: TicketResponse) => {
    setCurrentPageContents(page);
  };

  const pageContainer = () => {
    return (
      <>
        <div className="sm:hidden">
          <div className="flex flex-row flex-wrap gap-y-3 gap-x-2 justify-center mx-[20px]">
            {Array.isArray(displayedPageContents) &&
              displayedPageContents[0]?.data[0]?.Ticket &&
              displayedPageContents.map((item, index) => (
                <div
                  key={index}
                  onClick={() => changePage(item)}
                  className={`xl:w-[237px] lg:w-[237px] md:w-[237px] sm:w-[207px] min-w-[119px] flex-shrink:1 h-[47px] flex items-center justify-center rounded-[40px] border ${
                    isCurrentPage(item) ? "border-[#FFFFFF]" : "border-none"
                  } ${isCurrentPage(item) ? "bg-primary" : ""} `}
                  style={{
                    display: item?.data[0]?.Ticket ? "flex" : "none",
                    backgroundColor: !isCurrentPage(item)
                      ? "rgba(255, 255, 255, 0.15)"
                      : undefined,
                  }}
                >
                  <p
                    className={`font-['Sequel100Wide95'] text-[12px] font-normal ${
                      isCurrentPage(item) ? "text-[#280508]" : "text-[#FFFFFF]"
                    }`}
                    style={{
                      fontSize: "clamp(1px, 2.815621311vw, 14.18px)",
                    }}
                  >
                    {item?.data[0]?.Ticket} -{" "}
                    {item?.data[item?.data.length - 1]?.Ticket}
                  </p>
                </div>
              ))}
          </div>
        </div>
        <div className="xl:hidden lg:hidden md:hidden mb-10">
          <div className="//flex //flex-row grid grid-cols-1 sm_md_lg:grid-cols-2 //flex-wrap   gap-y-3 gap-x-2 justify-center ml-[20px] mr-[20px]">
            {Array.isArray(displayedPageContents) &&
              displayedPageContents[0]?.data[0]?.Ticket &&
              displayedPageContents.map((item, index) => (
                <div
                  key={index}
                  onClick={() => changePage(item)}
                  className={`xl:w-[237px] lg:w-[237px] md:w-[237px] sm:min-w-36 min-w-auto flex-shrink:1 h-[47px] flex items-center justify-center rounded-[40px] border ${
                    isCurrentPage(item) ? "border-[#FFFFFF]" : "border-none"
                  } ${isCurrentPage(item) ? "bg-primary" : ""} `}
                  style={{
                    display: item?.data[0]?.Ticket ? "flex" : "none",
                    backgroundColor: !isCurrentPage(item)
                      ? "rgba(255, 255, 255, 0.15)"
                      : undefined,
                  }}
                >
                  <p
                    className={`font-['Sequel100Wide95'] text-[12px] font-normal ${
                      isCurrentPage(item) ? "text-[#280508]" : "text-[#FFFFFF]"
                    }`}
                    style={{
                      fontSize: "clamp(1px, 2.815621311vw, 14.18px)",
                    }}
                  >
                    {item?.data[0]?.Ticket} -{" "}
                    {item?.data[item?.data.length - 1]?.Ticket}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    console.log("currentPageContents", currentPageContents);
  }, [currentPageContents]);

  const handleNext = () => {
    if (iteration === currentPageContents?.totalPages) {
      return;
    }
    if (
      displayedPageContents?.indexOf(currentPageContents) + 1 ===
      displayedPageContents?.length
    ) {
      const ispage = displayedPageContents
        ?.map((page) => page.totalPages)
        .includes(currentPageContents.currentPage);
      if (ispage) {
        return;
      }
      setIteration(iteration + 1);
    } else {
      const currentPageIndex =
        displayedPageContents?.indexOf(currentPageContents);
      setCurrentPageContents(displayedPageContents[currentPageIndex + 1]);
    }
  };

  const handlePrev = () => {
    if (iteration === 1) {
      return;
    }
    if (displayedPageContents?.indexOf(currentPageContents) === 0) {
      setIteration(iteration - 1);
    } else {
      const currentPageIndex =
        displayedPageContents?.indexOf(currentPageContents);
      setCurrentPageContents(displayedPageContents[currentPageIndex - 1]);
    }
  };

  return (
    <div className="w-full relative //mt-[3%] overflow-hidden">
      {!isInstant && <div className="pt-20"></div>}
      <div className="absolute -bottom-40 xl:-left-96 lg:-left-60 md:-left-40 sm:hidden w-1/2 h-1/2  rounded-full transform translate-x-1/4 translate-y-1/4"></div>
      {/* Entries */}
      {!isInstant && (
        <div className="mr-[3%] ml-[3%]">
          <Entries
            page={currentPageContents.currentPage}
            entries={entries}
            handleNext={handleNext}
            handlePrev={handlePrev}
            iteration={iteration}
            pageContainer={pageContainer}
          />
        </div>
      )}

      {/* FAQS */}
      <div className="flex flex-col items-center liveCompetitionPage  pt-0  sm:ml-0 //sm:p-3">
        <h1
          className={`pageHeading font-[Sequel100Wide95]`}
          style={{
            fontSize: "clamp(1px, 6.527976190476191vw, 45px)",
          }}
        >
          FAQS
        </h1>
      </div>
      <div className="flex flex-col gap-4 mx-[20%] sm_md:mx-[10%] mb-[52px]">
        {faqs.map((faq, index) => (
          <div
            key={faq.id}
            onClick={() => changeDropdown(index)}
            className="dropdown"
          >
            <h3
              className="w-full bg-primary flex items-center gap-8 text-[#1A1A1A] font-[Sequel100Wide95] h-[68px] pl-[1em] rounded-full cursor-pointer"
              style={{
                fontSize: "clamp(14px,3.496512479vw,24.82px)",
              }}
            >
              <img src="/Images/dropdown.png" alt="Dropdown Icon" />
              {faq.content}
            </h3>
            {active === index && (
              <div className="border-4 flex-wrap font-['Sequel100WideVF45'] border-primary mt-[-30px] border-t-0 flex w-full gap-4 items-end sm:flex-col pt-[4em] py-[2em] sm:px-[8%] rounded-b-[1.5rem]">
                <div className="text-white flex flex-col gap-4 sm:ml-0 w-[90%] m-auto sm:text-[10px]">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: faq.description,
                    }}
                  ></p>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LiveCompetitionFAQDivNew;
