import { useEffect } from "react";
import { useLocation } from "react-router-dom";

declare global {
  interface Window {
    Trustpilot?: {
      loadFromElement: (element: HTMLElement, trigger: boolean) => void;
    };
  }
}

const useTrustpilot = () => {
  const location = useLocation();

  useEffect(() => {
    const initializeTrustpilot = () => {
      const widgetElement = document.querySelector(".trustpilot-widget") as HTMLElement;
      if (window.Trustpilot && widgetElement) {
        window.Trustpilot.loadFromElement(widgetElement, true);
      }
    };

    setTimeout(initializeTrustpilot, 500);
  }, [location.pathname]); // Runs whenever the route changes
};

export default useTrustpilot;
