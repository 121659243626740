/**
 * Entry component renders a user entry with an image, title, description, and a button.
 * Structure:
 * - Main container
 * - Image container = 1
 * - user image = 1.1
 * - Information container = 2
 * - Title = 2.1
 * - Spacer Title and Description = 2.2
 * - Spacer Description and Button = 2.3
 * - Description Container = 2.4
 * - Description = 2.4.1
 * - Spacer Information and Button = 2.4.2
 * - Small Arrow Button = 2.4.3
 * - Button Container = 4
 * - Arrow Button = 4.1
 * - Spacer Button and Date = 4.2
 * - Date = 4.3
 * @param {EntryProps} props - The properties object.
 * @param {string} props.title - The title of the entry.
 * @param {string} props.description - The description of the entry.
 * @param {string} props.date - The date of the entry.
 * @param {boolean} [props.isLive] - The flag to show the live status of the entry.
 *
 * @returns {JSX.Element} The rendered Entry component.
 */

import { useNavigate } from "react-router-dom";
import HSpacer from "../../../common/Spacer/HSpacer";
import VSpacer from "../../../common/Spacer/VSpacer";

interface EntryProps {
  UID: string;
  title: string;
  description: string;
  date: string;
  isLive?: boolean;
  image?: string;
}
export default function Entry(props: EntryProps) {
  function formatISODate(isoDate: any) {
    const date = new Date(isoDate);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are 0-based in JavaScript
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const date = formatISODate(props.date);

  const formattedDate = formatISODate("2024-12-07T23:19:00.000Z");
  console.log(formattedDate); // Outputs: 7-12-2024

  const navigate = useNavigate();
  return (
    // Main component
    <div
      onClick={() => {
        props?.isLive
          ? navigate(`/view/entry/${props.UID}`)
          : navigate(`/view/result/${props.UID}`);
      }}
      className={`cursor-pointer ${
        props.isLive ? "border-[#DDE404]" : "border-[#EF008F]"
      } py-[23px] px-[35.86px] sm:!px-0 below-1440:py-[17px] below-1440:px-[13.44px] flex size-full  //max-w-[818px] max-h-[223px] below-1440:max-h-full below-1440:h-full overflow-hidden border-[2.28px] rounded-[20px] items-start justify-start`}
    >
      <div className="sm:h-[111px] m-auto sm:w-[109.48px] h-[183px] h-[177px]">
        {/* 1 - Image Container */}
        {/* backup class: max-w-[183px] size-full sm:h-auto h-full //h-[223px] //max-h-[177px] below-1440:min-w-[109.48px] below-1440:min-h-[111px] */}
        <div className="h-full w-[180px] sm:w-[100%] rounded-[15px] overflow-hidden">
          {/* 1.1 - user image */}
          <img
            // src="/Images/avatar.svg"
            src={props?.image || "/Images/No Image.svg"}
            alt="Entry Image"
            className=" below-1440:min-w-[109.48px] below-1440:min-h-[111px] object-cover w-full h-full"
          />
        </div>
      </div>
      <HSpacer small={22.09} big={49.07} />
      {/* 2 - Information Container */}
      <div className="min-w-[359px] below-1440:min-w-[178px] size-full max-h-[177px] below-1440:min-h-[111px] w-full">
        {/* 2.1 - Title */}
        <p className="!line-clamp-2 text-[35px] sm:!text-[18px] //text-[51.73px]  below-1440:text-[25px] font-[Sequel100Wide95] text-white leading-none">
          {props.title}
        </p>
        {/* 2.2 - Spacer Title and Description */}
        <VSpacer small={9} big={16.59} />
        <hr className="below-1440:max-w-[140.21px] below-1440:h-[4px]  max-w-[331.25px] h-[5.17px] bg-[#EF008F] rounded-full border-transparent" />
        {/* 2.3 - Spacer Description and Button */}
        <VSpacer small={10} big={15.52} />
        {/* 2.4 - Description Container */}
        <div className="flex w-full">
          <div className="flex flex-col justify-between h-full w-full">
            <div className="h-16 sm:!h-[3.5rem] sm:!text-[13px] below-1440:h-14 //py-8 items-end overflow-auto ">
              {/* 2.4.1 - Description */}
              <p className="text-[18.62px] sm:!text-[12px] below-1440:text-[18.62px]  font-[Sequel100WideVF45] text-white leading-none below-1440:min-w-[100px] below-1440:min-h-[42px] //text-wrap ">
                {props.description}
              </p>
              {/* 2.4.2 - Spacer Description and Button */}
              <HSpacer small={24.67} big={42.23} />
              {/* 2.4.3 - small arrow button*/}
            </div>
            <div className="flex w-full">
              <p className="text-[12px] hidden below-1440:block sm:!text-[12px]  mt-[12px] below-1440:text-[18px] font-[Sequel100WideVF45] text-white">
                {date}
              </p>
              <div className="w-full flex justify-end //border-2 items-end">
                <img
                  onClick={() =>
                    props?.isLive
                      ? navigate(`/view/entry/${props.UID}`)
                      : navigate(`/view/result/${props.UID}`)
                  }
                  src="/Images/arrow-next-circle.svg"
                  alt="Arrow"
                  className="hidden below-1440:block sm:!h-[19.46px] sm:!w-[20.26px] below-1440:size-8 cursor-pointer"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 3 - Spacer Information and Button */}
      {/* <HSpacer small={22.09} big={169.14} /> */}
      <HSpacer small={22.09} big={0} />
      {/* 4 - Button Container */}
      <div className="flex flex-col justify-center items-center  m-auto below-1440:hidden">
        {/* 4.1 - Arrow Button */}
        <img
          onClick={() =>
            props?.isLive
              ? navigate(`/view/entry/${props.UID}`)
              : navigate(`/view/result/${props.UID}`)
          }
          className="size-[29px] cursor-pointer mt-16"
          src="/Images/arrow-next-circle.svg"
          alt="Arrow"
        />
        <div>
          {/* 4.2 - Spacer Button and Date */}
          <VSpacer small={5} big={42.23} />
          {/* 4.3 - Date */}
          <p className="text-white text-[18.62px] font-[Sequel100WideVF45] below-1440:hidden text-nowrap">
            {/* {props.date} */}
            {date}
          </p>
        </div>
      </div>
    </div>
  );
}
