import React, { useEffect, useState } from "react";

const Link = () => {
  return <a href="contact@theprize.io" />;
};

const FAQ = ({
  className,
  fontSize,
}: {
  className: string;
  fontSize: string;
}) => {
  const [active, setActive] = useState(-1);

  const faqs = [
    {
      id: 1,
      content: "1. What is ThePrize.io?",
      description:
        "ThePrize.io is a crypto-based competition platform where you can win exciting digital prizes and luxury items by entering various competitions using both cryptocurrency and soon fiat. ",
    },
    {
      id: 2,
      content: "2. How do I enter a competition?",
      description: `First, ensure you have opened an account on our website. Select the number of tickets you wish to purchase in your account section and BUY TICKETS. Then choose the competition you wish to enter. 

 <div class='h-4 w-4'></div>

        You can then select a specific ticket number or use our lucky dip. ENTER NOW and answer a simple question and confirm your ticket selection or follow the instructions on how to enter for free.
        <div class='h-4 w-4'></div>
        When you have paid your entry fee, you will be told if you have answered the question correctly. If you have, your name will be entered in the draw with all of the other correct entries. If you have not answered correctly, you will not be entered into the draw. 

        <div class='h-4 w-4'></div>

 Anyone entering for free will not be told whether they have answered the question correctly and will not receive confirmation that they are entered into the draw. 

 <div class='h-4 w-4'></div>

However, an entry list is published and therefore all entrants should check this to ensure they have been entered into the draw for the competition they have entered. 
        `,
    },
    {
      id: 3,
      content: "3. Does ThePrize.io have a gambling licence? ",
      description: `No, due to the way the platform is structured we are free from gambling regulations by law in Europe. Please see our <a href="/policies/t&c" class="text-blue-500 underline">Terms & Conditions</a>, <a href="/policies/acceptableuse" class="text-blue-500 underline">Acceptable Use Policy</a>, <a href="/policies/privacy" class="text-blue-500 underline">Privacy Policy</a> and <a href="/policies/termsofuse" class="text-blue-500 underline">Website Terms of Use</a>.`,
    },
    {
      id: 4,
      content: "4. What cryptocurrencies are accepted? ",
      description:
        "We accept a range of popular cryptocurrencies, including Bitcoin (BTC), Ethereum (ETH), Tron (TRX), Base (BASE) and Solana (SOL) with more being added soon. ",
    },
    {
      id: 5,
      content: "5. How are winners selected?",
      description:
        "Winners are chosen using Chainlink VRF RNG, ensuring a fair and transparent process. You will be able to view the tx hash of the draw on our site, and will be notified if you have won. ",
    },
    {
      id: 6,
      content: "6. When do you announce the winners?",
      description:
        "Winners are announced in the Telegram Announcements group and across our socials once the competition timer runs out or when all tickets are sold, you will also be notified in the MY ACCOUNT section if you have won. ",
    },
    {
      id: 7,
      content: "7. What happens if I win?",
      description:
        "If you win, you’ll receive your prize in the form of cryptocurrency directly to your digital wallet. We may also feature your username in our Winners Hall of Fame! If you have won a luxury item, we will contact you to arrange delivery.. ",
    },
    {
      id: 8,
      content: "8. Is there a limit to the number of tickets I can buy?",
      description:
        "The number of tickets you can purchase per competition may vary. Please check the specific competition's details for any limitations. ",
    },
    {
      id: 9,
      content: "9. Can I get a refund if I change my mind?",
      description:
        "All ticket purchases are final. Once you've entered a competition, refunds are not available, so please ensure you’re confident before buying your tickets. ",
    },
    {
      id: 10,
      content: "10. How do I contact customer support?",
      description:
        // "For any inquiries or issues, you can reach our customer support team through the contact form on our website or by emailing us directly at",
        `For any inquiries or issues, you can reach our customer support team through our Telegram tech support bot 
        
        <span class="text-blue-500 underline cursor-pointer" onclick="window.open('https://t.me/ThePrize_Support_bot', '_blank')">@ThePrize_Support_bot</span>
        or by emailing us directly at <span class="text-blue-500 underline cursor-pointer" onclick="window.open('mailto:contact@theprize.io', '_blank')">contact@theprize.io</span>
        `,
    },
    {
      id: 11,
      content: "11. How do I stay updated on new competitions?",
      description:
        "To stay updated on our latest competitions and announcements, follow us on social media at:",
    },
    {
      id: 12,
      content: "12. My question hasn’t been answered here?",
      description: `If you have any questions that have not been answered here, please email us at <a href="mailto:contact@theprize.io" class="text-blue-500 underline">contact@theprize.io</a> and we will happily answer them for you.`,
    },
  ];

  const changeDropdown = (index: number) => {
    if (active === index) {
      setActive(-1);
    } else {
      setActive(index);
    }
  };

  return (
    <div
      className={`${className} w-full p-10 faqContainer sm:p-5 pt-3 relative z-[88]`}
    >
      <div className="flex flex-col items-center liveCompetitionPage  pt-0  sm:ml-0 sm:p-3">
        <h1
          className={`pageHeading font-[Sequel100Wide95]`}
          style={{
            fontSize: "clamp(1px, 6.527976190476191vw, 45px)",
          }}
        >
          FAQS
        </h1>
      </div>
      <div className="flex flex-col gap-4">
        {faqs.map((faq, index) => (
          <div
            onClick={() => {
              changeDropdown(index);
            }}
            className="dropdown"
          >
            <h3
              className="w-full bg-primary flex items-center gap-8 //text-[25px] //sm:text-[17px] text-[#1A1A1A] font-[Sequel100Wide95] h-[68px] pl-[1em] rounded-full cursor-pointer"
              style={{
                fontSize: "clamp(14px,3.496512479vw,24.82px)",
              }}
              key={index}
            >
              <img src="/Images/dropdown.png" alt="" />
              {faq.content}
            </h3>
            {active === index && (
              <div className="border-4 flex-wrap font-['Sequel100WideVF45'] border-primary mt-[-30px] border-t-0 flex w-full gap-4 items-end sm:flex-col pt-[4em] py-[2em] sm:px-[8%] rounded-b-[1.5rem]">
                <ol className="text-white  flex flex-col gap-4 sm:ml-0 w-[90%] m-auto sm:text-[10px] ">
                  {/* {faqs.map((des, index) => (
                    <li key={index}>{des.description}</li>
                  ))} */}
                  <li className="flex flex-row gap-2">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: faq.description,
                      }}
                    ></p>
                    {faq.id == 10 && (
                      // <p
                      //   className={"cursor-pointer"}
                      //   onClick={() => {
                      //     window.open("mailto:contact@theprize.io", "_blank");
                      //   }}
                      //   key={index}
                      //   style={{
                      //     textDecoration: "underline",
                      //   }}
                      // >
                      //   contact@theprize.io.
                      // </p>
                      <></>
                    )}
                    {faq.id == 11 && (
                      <>
                        <p
                          className={"cursor-pointer"}
                          onClick={() => {
                            window.open(
                              "https://t.me/theprizeannouncements",
                              "_blank"
                            );
                          }}
                          key={index}
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          Telegram
                        </p>
                        <p
                          className={"cursor-pointer"}
                          onClick={() => {
                            window.open("https://x.com/theprize_io", "_blank");
                          }}
                          key={index}
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          X
                        </p>
                      </>
                    )}
                  </li>
                </ol>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
