import { useNavigate } from "react-router-dom";
import HSpacer from "../../../common/Spacer/HSpacer";
import { Dispatch, Fragment, SetStateAction, useRef } from "react";
import { LinearProgress } from "@mui/material";
import Loadmore from "../buttons/Loadmore/Loadmore";

interface OrdersTableTicketsProps {
  isFetching: boolean;
  onScrolledToBottom: () => void;
  table: tableData[];
  loadmoreFunction: () => void;
  isLastPage: boolean;
}

const TableHeading = ({ text }: { text: string }) => (
  <th className="font-[Sequel100Wide-65] text-[20px] text-white text-center uppercase text-wrap flex items-center">
    {text}
  </th>
);

const TableData = ({
  text,
  isTicketsBought,
}: {
  text: string;
  isTicketsBought?: boolean;
}) => (
  <td
    className={`font-[Sequel100WideVF45] h-[50px] text-[20px] w-full flex justify-center  ${
      isTicketsBought ? "place-self-start" : "place-self-center"
    } `}
  >
    <p
      className={`leading-none w-fit  ${
        isTicketsBought ? "text-[#8a8a8a] text-left" : "text-white text-center"
      }`}
    >
      {text}
    </p>
  </td>
);
export default function OrdersTableTickets(props: OrdersTableTicketsProps) {
  const navigate = useNavigate();
  const navigateToDetail = (uid: string) => {
    navigate(`/buytickets`);
  };
  const scroller = useRef<HTMLDivElement | null>(null);
  const handleScroll = () => {
    const div = scroller.current;
    if (div) {
      const isEndReached =
        div.scrollHeight - div.scrollTop === div.clientHeight;
      if (isEndReached) {
        props.onScrolledToBottom();
        console.log("reached the end of records");
      }
    }
  };
  return (
    <div
      ref={scroller}
      onScroll={handleScroll}
      className="max-1280:hidden border-2 border-primary rounded-[20px] overflow-auto  w-full  "
    >
      {/* Headings */}
      <div className="grid grid-cols-4 gap-[43px] uppercase place-content-center place-items-center border-b-[2px] border-primary px-[clamp(0px,4vw,38px)] py-[29px]">
        <TableHeading text="Competition Name" />
        <TableHeading text="Date / Time" />
        <TableHeading text="Amount" />
        {/* <TableHeading text="Running total" /> */}
      </div>
      {/* Data */}
      <div className="px-[clamp(0px,4vw,38px)] py-[29px] w-full">
        <div className="scrollbar px-[clamp(15px,2.5vw,0px)] scrollbar-thumb-secondary scrollbar-track-[#3B3B3B] scrollbar-w-[4px] overflow-auto max-h-[586px] w-full">
          <div className="grid grid-cols-4 gap-x-[43px] *:min-h-1/2 *:h-fit ">
            {props.table
              ?.sort(
                (a, b) =>
                  new Date(b?.datetime).getTime() -
                  new Date(a?.datetime).getTime()
              )
              ?.map((data, index) => {
                return (
                  <Fragment key={index}>
                    <TableData text={data?.competitionname} />
                    <TableData
                      text={new Date(data?.datetime)
                        .toLocaleDateString("en-GB")
                        .replace(/\//g, ".")}
                    />
                    <TableData
                      text={Math?.trunc(
                        parseInt(data?.amountspent)
                      )?.toString()}
                    />
                    {/* <TableData text={data?.RunningTotal} /> */}
                    <div className="h-[50px] w-full 500 m-auto">
                      <button
                        onClick={() => navigateToDetail(data?.uid)}
                        className="bg-primary max-w-[139px] w-full h-[24px] uppercase rounded-[17.12px] flex justify-center items-center text-[20px] font-[Sequel100Wide95]"
                      >
                        Top Up
                      </button>
                    </div>
                  </Fragment>
                );
              })}
          </div>
          {props.isLastPage ? null : (
            <Loadmore setPage={props?.loadmoreFunction} />
          )}
        </div>
        <LinearProgress
          className={
            "w-full rounded-b-[30px] " +
            (props.isFetching ? "opacity-100" : "opacity-0")
          }
          color="secondary"
        />
      </div>
    </div>
  );
}
