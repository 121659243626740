import { ReactNode, useEffect, useState } from "react";
import HeroSlideActionsSmall from "./HeroSlideActionsSmall";
import {
  finishedCompetition,
  loadCompetition,
  navigateToResult,
  ticketSold,
} from "./heroFunctionalities";
import Coin from "../../Animated/Coin";
import { Text } from "../../react-responsive-utilities/src";
import VSpacer from "../../common/Spacer/VSpacer";
import HeroSlideActions from "./HeroSlideActions";
import Counter from "../../common/Counter/Counter";
import { useNavigate } from "react-router-dom";
import { Common } from "../../common/Common";
import { useWallet } from "../../../Contexts/WalletContext";
import PopupBox from "../../common/PopupBox/PopupBox";

interface HeroSlideProps {
  title: string;
  description: string;
  image: string;
  totalTicketsBought: number;
  competitionTicketSize: number;
  competitionEndDate: Date;
  competitionUID: string;
  competitionEnded: boolean;
  costPerTicket: number;
  isInstant: boolean;
  competition: any;
}

const Container = ({ children }: { children: ReactNode }) => (
  <div className="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-1 relative min-h-[565.94px] sm:min-h-auto md:min-h-auto overflow-hidden bg-[#1e1e1e] //sm:mb-[26px] //md:mb-[26px]">
    {children}
  </div>
);

const LeftContainer = ({ children }: { children: ReactNode }) => (
  <div className="relative overflow-hidden max-h-[565.94px] sm:max-h-[325.1px] md:max-h-[325.1px] bg-[#1e1e1e]">
    {children}
  </div>
);

const RightContainer = ({ children }: { children: ReactNode }) => (
  <div className="min-h-[567px] sm:min-h-[519.9px] md:min-h-[519.9px] bg-[#1e1e1e] px-[max(0vw,53.7px)] sm:px-[max(0vw,15px)] md:px-[max(0vw,15px)] lg:px-[max(0vw,15px)] *:m-[initial] *:text-left sm:*:m-auto md:*:m-auto sm:*:text-center md:*:text-center relative">
    {children}
  </div>
);

const Title = ({ title }: { title: string }) => (
  <Text
    textSize={[30, 30.0, 50.0]}
    className="text-[#FFFFFF] leading-none font-['Sequel100Wide95'] //leading-[35px] //leading-normal font-[60] block sm:text-center md:text-center"
  >
    {title}
  </Text>
);

const BuyAndSpinText = () => (
  <p className="text-[#EF008F] text-[17.18px] font-[Sequel100Wide95] uppercase xl:block lg:block hidden relative z-50">
    {" "}
    Buy tickets and spin to win!
  </p>
);

const BuyAndSpinTextSmall = () => (
  <Text
    textSize={[10, 16.0, 16.0]}
    className="text-[#EF008F] text-[17.18px] font-[Sequel100Wide95] uppercase md:block sm:block hidden relative z-50"
  >
    Buy tickets and spin to win!
  </Text>
);

const Description = ({ description }: { description: string }) => (
  <Text
    textSize={[14, 14.0, 19.96]}
    className="
      text-[#FFFFFF] font-['Sequel100WideVF45'] //leading-[17px] font-[10] block sm:text-center md:text-center"
  >
    {description}
  </Text>
);

export default function HeroSlide(props: HeroSlideProps) {
  const [isInsufficientAmount, setIsInsufficientAmount] = useState(false);
  const navigate = useNavigate();
  const { user, fetchUser, account } = useWallet();
  const [buyTicketPopup, setBuyTicketPopup] = useState(false);
  useEffect(() => {
    // if (user) {
    //   setIsInsufficientAmount(user?.numberOfTickets < value ? true : false);
    // }
    if (user) {
      if (props?.competition) {
        const userTickets = props?.competition?.userTickets ?? 0;
        const walletBalance = user?.numberOfTickets ?? 0;
        const minTickets = props?.competition?.minnumberoftickets ?? 0;

        // for user ticket 0
        if (userTickets === "0") {
          if (walletBalance < minTickets) {
            setIsInsufficientAmount(true);
            return;
          }
        }

        // for user ticket > 0
        if (parseInt(userTickets) > 0) {
          if (walletBalance === 0) {
            setIsInsufficientAmount(true);
            return;
          }
        }
      }
    }
  }, [
    user,
    props?.competition?.userTickets,
    props?.competition?.minnumberoftickets,
    props?.competition?.maxticketsperuser,
  ]);

  const handleBuyTicket = () => {
    navigate("/buy-tickets");
  };

  return (
    <Container>
      {buyTicketPopup && (
        <PopupBox
          text="You must buy tickets before you can enter a competition."
          buttonText="Buy Tickets"
          onClick={handleBuyTicket}
        />
      )}
      <LeftContainer>
        {props?.isInstant && (
          <div className="bg-secondary h-[60.96px] max-w-[552px] sm_md:max-w-full w-full rounded-br-3xl sm_md:rounded-br-none absolute flex justify-between items-center px-[22.93px] sm_md:px-[14.58px]">
            <img src="/Images/instant-win-wheel.svg" alt="" />
            <Common.typography
              fontFamily="Sequel100Wide95"
              fontSizeSmall="24.73px"
              fontSize="clamp(0px,3vw,38.89px)"
              color="white"
              className="uppercase"
            >
              Instant Win
            </Common.typography>
            <img src="/Images/instant-win-wheel.svg" alt="" />
          </div>
        )}
        <img
          src={
            props.image?.includes("http") ? props.image : "/Images/No Image.svg"
          }
          onError={(e) => {
            e.currentTarget.src = "/Images/No Image.svg";
          }}
          onEnded={(e) => {
            e.currentTarget.src = "/Images/No Image.svg";
          }}
          alt="hero"
          className="object-cover h-full w-full  "
        />
      </LeftContainer>
      <RightContainer>
        <VSpacer small={20} big={30} />
        <Title title={props.title} />
        {/* <VSpacer small={11.96} big={24} /> */}
        <VSpacer big={14.92} small={20.92} />
        <hr className="max-w-[320.18px] w-full border-b-[5px] border-t-0 border-[#EF008F] hidden sm:block md:block h-[3.14px] rounded-full relative z-50" />
        {/* <VSpacer small={12.9} big={0} /> */}
        <VSpacer big={13.58} small={16.55} />
        <Description description={props.description} />
        {/* <VSpacer small={28.16} big={22} /> */}
        <VSpacer big={34.1} small={28.16} />
        <hr className="max-w-[320.18px] w-full border-b-[5px] border-t-0 border-[#EF008F] block sm:hidden md:hidden relative z-50" />
        <VSpacer big={34.1} small={0} />
        {/* <VSpacer small={0} big={20.89} /> */}
        <Counter {...props} title="TIME REMAINING" />
        <VSpacer small={31.11} big={18.28} />
        <BuyAndSpinText />
        <VSpacer small={0} big={23.3} />
        <HeroSlideActionsSmall
          onClick={() => {
            if (isInsufficientAmount) {
              setBuyTicketPopup(true);
              return;
            }
            if (props.isInstant) {
              navigate(`/view/competition/instant-win/${props.competitionUID}`);
            } else {
              navigate(`/live-competition/${props.competitionUID}`);
            }
          }}
          value={ticketSold(
            props.totalTicketsBought,
            props.competitionTicketSize
          )}
          {...props}
        />

        <VSpacer small={17.11} big={0} />

        <HeroSlideActions
          onClick={() => {
            if (isInsufficientAmount) {
              setBuyTicketPopup(true);
              return;
            }
            if (props.isInstant) {
              navigate(`/view/competition/instant-win/${props.competitionUID}`);
            } else {
              navigate(`/live-competition/${props.competitionUID}`);
            }
          }}
          value={ticketSold(
            props.totalTicketsBought,
            props.competitionTicketSize
          )}
          {...props}
        />
        <BuyAndSpinTextSmall />
        <VSpacer small={26} big={32.48} />
        <div className="absolute -bottom-0 -right-0 sm:top-[50%] sm:right-[50%] md:top-[50%] md:right-[50%] sm:transform sm:translate-x-1/2 sm:-translate-y-1/2 md:transform md:translate-x-1/2 md:-translate-y-1/2 //opacity-[15%] ">
          {/* <Coin /> */}
          <img src="/Images/THEPRIZE_TokenLogo_EH-03.svg" alt="coin" />
        </div>
        {/* <div className="absolute top-[50%] right-[50%] transform translate-x-1/2 -translate-y-1/2 opacity-[15%] hidden sm:block md:block">
          <img src="/Images/small_coin.svg" alt="coin" />
        </div> */}
      </RightContainer>
    </Container>
  );
}
