import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InstantPayments from "../Auth Components/InstantPayments";
import { Common } from "../common/Common";
import PopupWithIcon from "../common/PopupWithIcon/PopupWithIcon";

interface PaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
  value?: number;
  openPaymentModal?: () => void;
  paymentStatus?: Array<
    | "initiated"
    | "approvalRequest"
    | "approval"
    | "signing"
    | "paymentRequest"
    | "completed"
    | "error"
  >;
  transactionHash?: string;
  errorMessage?: string;
  isApprovalStage?: boolean;
  isSigningStage?: boolean;
  paymentOption?: string;
  enterCompetition: () => void;
  setShowPopUp: (value: boolean) => void;
}

const PaymentModal: React.FC<PaymentModalProps> = ({
  isOpen,
  onClose,
  value,
  openPaymentModal,
  paymentStatus,
  transactionHash,
  errorMessage,
  isApprovalStage = true,
  isSigningStage = true,
  paymentOption,
  enterCompetition,
  setShowPopUp,
}) => {
  const [copiedText, setCopiedText] = useState("");
  const navigate = useNavigate();

  return (
    <PopupWithIcon
      showCloseButton
      open={isOpen}
      onClose={() => {
        onClose();
        // window.location.reload();
      }}
      children={
        <>
          <Common.typography
            fontFamily="Sequel100Wide95"
            fontSize="20px"
            fontSizeSmall="20px"
            color="#fff"
            className="uppercase text-center"
          >
            BUY TICKETS
          </Common.typography>
          <hr className="max-w-[478.99px] m-auto w-full mt-4 mb-6 border-[1px] border-[#fff] " />
          <InstantPayments
            setShowPopUp={setShowPopUp}
            openPaymentModal={openPaymentModal}
            value={value}
            skipable={false}
            enterCompetition={enterCompetition}
          />
        </>
      }
    ></PopupWithIcon>
  );
};

export default PaymentModal;
