import React, { useState, useEffect } from "react";
import { applyReferralCode, submitEmail } from "../../../Services/userService";
import { useWallet } from "../../../Contexts/WalletContext";
import PopupWithIcon from "../PopupWithIcon/PopupWithIcon";
import { Common } from "../Common";
import { useNavigate } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";

const EmailConfirmationPopup = () => {
  const [isOpen, setIsOpen] = useState(true); // Popup is shown by default on page load
  const [emailHandle, setEmailHandle] = useState("");
  const [affiliateCode, setAffiliateCode] = useState("");
  const [error, setError] = useState("");
  const [codeSuccessPopup, setCodeSuccessPopup] = useState(false);
  const [emailSuccessPopup, setEmailSuccessPopup] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [competitionFullPopup, setCompetitionFullPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  interface Ticket {
    ticket_number: string;
  }

  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [entryId, setEntryId] = useState("");
  const { user, fetchUser, account } = useWallet();
  const user_email = (user as { email: string })?.email;
  const [email, setEmail] = useState("");

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateAffiliateCode: any = (code: string) => {
    // const codeRegex = /^[A-Za-z0-9]{6}$/;
    // return codeRegex.test(code);
    // if (email == "") {
    //   return "email-error";
    // } else if (code === "") {
    //   return "empty-error";
    // } else {
    //   return true;
    // }
    return true;
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setEmailHandle(email);

    if (!validateEmail(email)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };

  const handleAffiliateCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const code = e.target.value;
    setAffiliateCode(code);
  };

  const handleConfirmAffiliateCode = async () => {
    // if (validateAffiliateCode(affiliateCode) === "email-error") {
    //   setError("Please enter an email first.");
    //   return;
    // } else if (validateAffiliateCode(affiliateCode) === "empty-error") {
    //   setError("Please enter an affiliate code.");
    //   return;
    // } else {
    //   setError("");
    // }

    try {
      const result = await applyReferralCode({
        referralcode: affiliateCode,
        walletaddress: account as string,
      });
      console.log("result", result);
      if (result) {
        // alert(result?.message);
        setTickets(result?.tickets);

        setCodeSuccessPopup(true);
        setIsOpen(false);
        setEntryId(result?.competition);
      }
    } catch (error: any) {
      console.log("error", error);
      console.log("data::::::::", error);
      if (
        error?.response?.data?.error ===
        "No promotional tickets remaining in this competition"
      ) {
        setCompetitionFullPopup(true);
        setIsOpen(false);
        return;
      } else {
        alert(error?.response?.data?.error);
      }
    }
  };

  const handleConfirmEmail = async () => {
    if (!validateEmail(emailHandle)) {
      setEmailError("Please enter a valid email.");
      return;
    }

    try {
      const result = await submitEmail({ email: emailHandle });
      console.log("result", result);
      if (result) {
        setEmail(emailHandle);
        // setIsOpen(false);
        // window.location.reload();
      }
    } catch (error: any) {
      console.log("error", error);
    }
  };

  const handleConfirm = async () => {
    setLoading(true);
    await handleConfirmEmail();
    if (affiliateCode.length > 0) {
      await handleConfirmAffiliateCode();
    } else {
      setEmailSuccessPopup(true);
      setIsOpen(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    document.body.classList.add("overflow-hidden");
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  const handleClickOutside = (event: MouseEvent) => {
    const popup = document.querySelector(".bg-primary");
    if (popup && !popup.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const navigate = useNavigate();

  return (
    <>
      {/* Popup */}
      {codeSuccessPopup && (
        <PopupWithIcon
          onClose={() => {
            setCodeSuccessPopup(false);
            window.location.reload();
          }}
          open={codeSuccessPopup}
          showCloseButton={true}
        >
          <div className="flex flex-col items-center p-2 text-white">
            <div className="p-6 w-full">
              <p className="text-center text-lg font-[Sequel100WideVF45] mb-4">
                PROMO COMP ENTRY
              </p>
              <hr className="text-white border-white w-full" />
            </div>
            <div className="w-full flex flex-col justify-center font-[Sequel100WideVF45] border-[#DDE404] border-[1px] h-[287px] sm:px-[20px] xl:m-[20px] lg:m-[20px] md:m-[20px]  px-[40px] rounded-lg mt-6">
              <div className="flex flex-col items-center justify-center gap-4">
                <Common.typography
                  fontFamily="Sequel100Wide95"
                  fontSize="25px"
                  fontSizeSmall="24px"
                  color="#DDE404"
                  className="uppercase text-center"
                >
                  SUCCESS!
                </Common.typography>
                <div>
                  <div className="flex flex-row gap-1">
                    <Common.typography
                      fontFamily="Sequel100WideVF45"
                      fontSize="16px"
                      fontSizeSmall="16px"
                      color="#fff"
                      className="uppercase text-center"
                    >
                      Ticket Numbers <span>{tickets?.join(", ")} </span>
                    </Common.typography>

                    <Common.typography
                      fontFamily="Sequel100WideVF45"
                      fontSize="16px"
                      fontSizeSmall="16px"
                      color="#fff"
                      className="text-center uppercase"
                    >
                      added.
                    </Common.typography>
                  </div>
                  <Common.typography
                    fontFamily="Sequel100WideVF45"
                    fontSize="16px"
                    fontSizeSmall="16px"
                    color="#fff"
                    className="text-center"
                  >
                    Good Luck
                  </Common.typography>
                </div>
                <div
                  onClick={() => {
                    // Navigate to the entry page
                    // navigate("/view/entry/" + entryId);
                    window.location.href = "/view/entry/" + entryId;
                  }}
                  className="w-full flex flex-col sm:mt-2 mt-6 sm:p-2 p-3 bg-[#DDE404] text-white text-sm rounded justify-center sm:mx-[20px]"
                  style={{ height: "50px" }}
                >
                  <div className="flex flex-row justify-center items-center w-full">
                    <div
                      className="w-full overflow-hidden cursor-pointer text-[#000] text-ellipsis line-clamp-3 font-[Sequel100Wide-65] sm:text-[16px] below-430:text-[12px] text-[18px] tracking-tighter"
                      style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        textAlign: "center",
                      }}
                    >
                      VIEW ENTRY
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PopupWithIcon>
      )}

      {emailSuccessPopup && (
        <PopupWithIcon
          onClose={() => {
            setEmailSuccessPopup(false);
            window.location.reload(); // Add page refresh on close
          }}
          open={emailSuccessPopup}
          showCloseButton={true}
        >
          <div className="flex flex-col items-center justify-center gap-4 p-10">
            <Common.typography
              fontFamily="Sequel100Wide95"
              fontSize="25px"
              fontSizeSmall="24px"
              color="#DDE404"
              className="uppercase text-center"
            >
              Email Confirmed
            </Common.typography>
            <Common.typography
              fontFamily="Sequel100WideVF45"
              fontSize="16px"
              fontSizeSmall="16px"
              color="#fff"
              className="text-center"
            >
              You have successfully confirmed your email.
            </Common.typography>

            <Common.button
              onClick={() => {
                setEmailSuccessPopup(false);
                window.location.reload(); // Add page refresh on button click
              }}
              className="uppercase text-center"
              backgroundColor="#DDE404"
              color="#000"
              fontSize="16px"
              fontSizeSmall="16px"
              fontFamily="Sequel100Wide95"
              roundness="10px"
            >
              OK
            </Common.button>
          </div>
        </PopupWithIcon>
      )}
      {competitionFullPopup && (
        <PopupWithIcon
          onClose={() => setCompetitionFullPopup(false)}
          open={competitionFullPopup}
          showCloseButton={true}
        >
          <div className="flex flex-col items-center justify-center gap-4 p-10">
            <Common.typography
              fontFamily="Sequel100Wide95"
              fontSize="25px"
              fontSizeSmall="24px"
              color="#DDE404"
              className="uppercase text-center"
            >
              Competition Full
            </Common.typography>
            <Common.typography
              fontFamily="Sequel100WideVF45"
              fontSize="16px"
              fontSizeSmall="16px"
              color="#fff"
              className="text-center"
            >
              The competition with this referral code is full.
            </Common.typography>

            <Common.button
              onClick={() => setCompetitionFullPopup(false)}
              className="uppercase text-center"
              backgroundColor="#DDE404"
              color="#000"
              fontSize="16px"
              fontSizeSmall="16px"
              fontFamily="Sequel100Wide95"
              roundness="10px"
            >
              OK
            </Common.button>
          </div>
        </PopupWithIcon>
      )}
      {isOpen && (
        <div className="fixed inset-0 flex sm:items-start sm:mt-5 items-center justify-center bg-black bg-opacity-50 z-[10010003300000]">
          {/* Loader */}
          <div className="absolute bg-primary rounded-lg shadow-lg p-6 xl:w-[40%] lg:w-[40%] md:w-[60%] sm:w-[90%] z-10">
            {/* {loading && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50  z-[2]">
                <FaSpinner
                  className="animate-spin text-white text-4xl"
                  style={{ fontSize: "40px" }}
                />
              </div>
            )} */}
            <img
              onClick={() => setIsOpen(false)}
              className="absolute top-[-14px] right-[-14px] cursor-pointer"
              // src="/Images/white-cross.svg"
              // alt="Close"
            />
            <div className="overflow-y-auto relative sm:max-h-[400px]">
              <span className="font-[Sequel100Wide] text-lg font-semibold mb-4">
                PLEASE NOTE:{" "}
              </span>
              <span className="font-[Sequel100WideVF45] text-lg font-semibold mb-4">
                Please enter your email so we can contact you in the event you
                win.
              </span>
              <div className="h-[20px]"></div>
              {/* Flex container to align the input and button in one line on XL screens only */}
              <div className="flex flex-col xl:flex-row gap-4 items-center w-full">
                <div className="w-full">
                  <span className="font-[Sequel100WideVF45] text-ssm font-semibold ">
                    Email (*Required)
                  </span>
                  <input
                    // disabled={email?.length > 0}
                    type="text"
                    value={emailHandle}
                    onChange={handleEmailChange}
                    placeholder="Enter Email"
                    className={`bg-white text-black px-4 py-2 rounded-md w-full //xl:w-[70%] sm:w-full lg:w-full h-[56px] font-[Sequel100wideVF45] ${
                      error ? "border border-red-500" : "border border-gray-300"
                    }
                disabled:opacity-50
                `}
                  />
                </div>
                {/* <button
                disabled={email?.length > 0}
                onClick={handleConfirmEmail}
                className="bg-[#212121] xl:py-[13px] rounded-[14px] border-2 border-white font-[Sequel100wide] text-white h-[56px] w-full xl:w-[30%] sm:w-full lg:w-full
                 disabled:bg-[#B6B6B6] disabled:border-[#B6B6B6]
                "
              >
                {email?.length > 0 ? "Confirmed" : "Confirm"}
              </button> */}
              </div>
              {/* {emailError && (
              <p className="text-red-500 text-sm mt-2">{emailError}</p>
            )} */}
              <div className="h-[20px]"></div>
              <span className="font-[Sequel100WideVF45] text-lg font-semibold ">
                Enter your affiliate code to get 5 FREE ENTRIES into our
                PROMOTED COMPETITION.
              </span>
              <div className="h-[20px]"></div>
              <div className="flex flex-col xl:flex-row gap-4 items-center">
                <div className="w-full">
                  <span className="font-[Sequel100WideVF45] text-ssm font-semibold ">
                    Affiliate Code (Optional)
                  </span>
                  <input
                    type="text"
                    value={affiliateCode}
                    onChange={handleAffiliateCodeChange}
                    placeholder="Enter Affiliate Code"
                    className={`bg-white text-black px-4 py-2 rounded-md w-full //xl:w-[70%] sm:w-full lg:w-full h-[56px] font-[Sequel100wideVF45] ${
                      error ? "border border-red-500" : "border border-gray-300"
                    }`}
                  />
                </div>
                {/* <button
                onClick={handleConfirmAffiliateCode}
                className="bg-[#212121] xl:py-[13px] rounded-[14px] border-2 border-white font-[Sequel100wide] text-white h-[56px] w-full xl:w-[30%] sm:w-full lg:w-full"
              >
                Confirm
              </button> */}
              </div>
              <div className="h-[20px]"></div>
              <button
                disabled={emailHandle?.length === 0 || loading}
                onClick={handleConfirm}
                className="
              disabled:opacity-50
              bg-[#212121] xl:py-[13px] rounded-[14px] border-2 border-white font-[Sequel100wide] text-white h-[56px] w-full //xl:w-[30%] sm:w-full lg:w-full uppercase justify-center flex items-center"
              >
                {/* Play Now */}
                {loading ? (
                  <div className="flex flex-row items-center justify-center">
                    {/* WE’RE WORKING ON IT */}
                    <Common.typography
                      fontFamily="Sequel100Wide95"
                      fontSize="22px"
                      fontSizeSmall="12px"
                      color="#fff"
                      className="uppercase text-center"
                    >
                      WE’RE WORKING ON IT
                    </Common.typography>
                    <img
                      className="animate-spin size-[26px] ml-2"
                      src={"/Images/Loader.svg"}
                      alt={""}
                    />
                  </div>
                ) : (
                  "Play Now"
                )}
              </button>
              {/* Error message */}
              {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
              {emailError && (
                <p className="text-red-500 text-sm mt-2">{emailError}</p>
              )}
              <div className="h-[20px]"></div>
              <p className="text-center text-xs">
                By entering you agree to receive competition information.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EmailConfirmationPopup;
