/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo, useRef } from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Contexts/AuthContext";
import { loginByEmailPassword } from "../../Services/jwtService";
import { getPaymentOptions, submitPayment } from "../../Services/userService";
import Button from "../Button";
import { CurrencyBoxProps } from "../CurrencyBox";
import { Common } from "../common/Common";
import Dropdown, { PaymentOption } from "../common/Dropdown/Dropdown";
import { useWallet } from "../../Contexts/WalletContext";
import {
  baseConfigs,
  ethereumConfigs,
  isProduction,
  solanaConfigs,
  tronConfigs,
} from "../../Config/blockchain.config";
import { getAddress, parseEther, toBigInt } from "ethers";
import {
  getBaseSignature,
  getEthereumSignature,
  getNowPaymentInvoiceLink,
  getTronSignature,
  getUnipaymentInvoiceLink,
} from "../../Services/transactionService";
import { Connection, LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import PaymentStatusModal from "../PaymentStatusModal/PaymentStatusModal";
import axios from "axios";
import ConfirmationPayment from "./ConfirmationPayment";
import { BN } from "@project-serum/anchor";
import { getAccount, getAssociatedTokenAddress } from "@solana/spl-token";
import { Modal } from "@mui/material";
import { Loader } from "../common/loader/Loader";
import { openGlobalModal } from "../Page Components/ClipboardModalContext";
import useTransac from "../../Contexts/TransacContext";
import { MoonPayBuyWidget } from "@moonpay/moonpay-react";
import socket from "../../Config/socket";
import { PaymentTransactionModal } from "../common/transaction-modal/PaymentTransactionModal";
import PopupWithIcon from "../common/PopupWithIcon/PopupWithIcon";

interface PaymentInterface {
  emailaddress?: string;
  password?: string;
  skipable?: boolean;
}

function Payments({
  emailaddress,
  password,
  skipable = true,
}: PaymentInterface) {
  const [currentFilter, setCurrentFilter] = useState<string>("CRYPTO");
  const [loading, setLoading] = useState(false);
  const [membershipsOptions, setMembershipsOptions] = useState<
    CurrencyBoxProps[]
  >([]);
  const [buyTicketResponse, setBuyTicketResponse] = useState<any>();
  const [isIFrameOpen, setIsIFrameOpen] = useState(false);
  const [popup, setPopup] = useState(false);
  const [moonpayOpen, setMoonpayOpen] = useState(false);
  const [unipaymentIframe, setUnipaymentIframe] = useState<string | undefined>(
    ""
  );

  useEffect(() => {
    if (buyTicketResponse && buyTicketResponse?.data?.checkoutLink?.length > 0)
      setIsIFrameOpen(true);
  }, [buyTicketResponse]);

  const [payAsYouGoOptions, setPayAsYouGoOptions] = useState<
    CurrencyBoxProps[]
  >([]);
  const [paymentMethod, setPaymentMethod] = useState<string>("Payment Method");
  const [paymentOption, setPaymentOption] =
    useState<string>("Number of tickets");
  const [paymentTokensList, setPaymentTokensList] = useState<
    Array<string> | undefined
  >();
  const [paymentToken, setPaymentToken] = useState<string | undefined>();
  const [paymentValue, setPaymentValue] = useState<string | undefined>();
  const [isModalOpen, setModalOpen] = useState(false);
  const [
    isCardTransactionStatusModalOpen,
    setIsCardTransactionStatusModalOpen,
  ] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState<
    Array<
      | "initiated"
      | "approvalRequest"
      | "approval"
      | "signing"
      | "paymentRequest"
      | "completed"
      | "error"
    >
  >(["initiated"]);
  const [transactionHash, setTransactionHash] = useState<string | undefined>();
  const [invoiceId, setInvoiceId] = useState<string | undefined>();
  const [unipayLoading, setUnipayLoading] = useState(false);

  const [paymentErrorMessage, setPaymentErrorMessage] = useState<
    string | undefined
  >();
  const [ethInUsd, setEthInUsd] = useState<number | undefined>(0);
  const [solanaInUsd, setSolanaInUsd] = useState<number | undefined>(0);
  const [btcInUsd, setBtcInUsd] = useState<number | undefined>(0);
  const [invoiceStatus, setInvoiceStatus] = useState<string | undefined>(
    "pending"
  );
  const [transactionData, setTransactionData] = useState<any>();
  const storedToken = localStorage.getItem("token");
  const { connector, network, account, providerType } = useWallet();
  const lastLoggedHash = useRef<any>(null);

  const paymentMethods: string[] = [
    "CRYPTO",
    "FIAT",
    // , "On-Ramp"
  ];

  const isAuthenticated = (): boolean => {
    const token = localStorage.getItem("token"); // Or your preferred method of storing the token
    return !!token; // Return true if token exists, otherwise false
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/"); // Redirect to the homepage if not authenticated
    }
  }, [navigate]);

  const numberOfTickets: PaymentOption[] = [
    {
      value: "1",
      price: "1.00",
      logo: "star",
      savings: "0.00",
    },
    {
      value: "5",
      price: "4.95",
      logo: "star",
      savings: "0.05",
    },
    {
      value: "10",
      price: "9.80",
      logo: "star",
      savings: "0.20",
    },
    {
      value: "15",
      price: "14.55",
      logo: "star",
      savings: "0.45",
    },
    {
      value: "20",
      price: "19.20",
      logo: "star",
      savings: "0.80",
    },
    {
      value: "25",
      price: "23.75",
      logo: "star",
      savings: "1.25",
    },
    {
      value: "50",
      price: "47.00",
      logo: "star",
      savings: "3.00",
    },
    {
      value: "100",
      price: "93.00",
      logo: "star",
      savings: "7.00",
    },
    {
      value: "250",
      price: "230.00",
      logo: "star",
      savings: "20.00",
    },
    {
      value: "500",
      price: "455.00",
      logo: "doubleStar",
      savings: "45.00",
    },
    {
      value: "1000",
      price: "900.00",
      logo: "tripleStar",
      savings: "100.00",
    },
    {
      value: "2500",
      // price: "2,255.00",
      price: "2255.00",
      logo: "uniqueStar",
      savings: "275.00",
    },
    {
      value: "5000",
      // price: "4,400.00",
      price: "4400.00",
      logo: "diamond",
      savings: "600.00",
    },
  ];

  function validatePayWithCard() {
    if (
      parseFloat(getPaymentOptionFromValue(paymentOption)?.price || "0") > 0
    ) {
      return true;
    }
    return false;
  }

  const login = async () => {
    if (!account) {
      return;
    }
    if (openGlobalModal) {
      openGlobalModal({
        text: "Please connect your wallet to enter competitions.",
      });
    }
  };

  const getPayments = async () => {
    setLoading(true);
    const response = await getPaymentOptions();
    if (response?.data?.length > 0) {
      const memberships = response?.data?.filter((item: any) =>
        item.unique_identifier.includes("sub")
      );

      const payAsYouGo = response?.data?.filter((item: any) =>
        item.unique_identifier.includes("one")
      );

      const filteredMemberships =
        currentFilter === "CRYPTO"
          ? memberships?.filter((x: any) => x.method === "CRYPTO")
          : memberships?.filter((x: any) => x.method === "Fiat");

      const filteredFiats =
        currentFilter === "Fiat"
          ? payAsYouGo?.filter((x: any) => x.method === "Fiat")
          : payAsYouGo?.filter((x: any) => x.method === "CRYPTO");

      // sort by price in ascending order
      setMembershipsOptions(
        filteredMemberships?.sort(
          (a: any, b: any) => parseFloat(a?.price) - parseFloat(b?.price)
        )
      );
      setPayAsYouGoOptions(
        filteredFiats?.sort(
          (a: any, b: any) => parseFloat(a?.price) - parseFloat(b?.price)
        )
      );
    }
    setLoading(false);
  };

  const getPaymentOptionFromValue = (value: string) => {
    return numberOfTickets.find((x) => x.value === value);
  };

  const handleMoonpay = () => {
    setMoonpayOpen(true);
  };

  useMemo(() => {
    if (network) {
      let paymentTokens: Array<string> = [];
      if (network === "ethereum") {
        paymentTokens = [...ethereumConfigs.AVAILABLE_PAYMENT_METHODS];
        setPaymentToken("ETH");
      } else if (network === "base") {
        paymentTokens = [...baseConfigs.AVAILABLE_PAYMENT_METHODS];
        setPaymentToken("ETH");
      } else if (network === "solana") {
        paymentTokens = [...solanaConfigs.AVAILABLE_PAYMENT_METHODS];
        setPaymentToken("SOL");
      } else if (network === "tron") {
        paymentTokens = [...tronConfigs.AVAILABLE_PAYMENT_METHODS];
        setPaymentToken("USDT");
      } else if (network === "btc") {
        paymentTokens = ["BTC"];
        setPaymentToken("BTC");
      }
      setPaymentTokensList(paymentTokens);
    }
  }, [network]);

  useEffect(() => {
    // if (invoiceStatus === 'completed') {
    //   console.log('Payment confirmed successfully!');
    // }
    console.log("invoiceStatus", invoiceStatus);
    console.log("paymentStatus", paymentStatus);
  }, [invoiceStatus, paymentStatus]);

  useMemo(() => {
    if (paymentOption !== "Number of tickets") {
      const paymentObj = getPaymentOptionFromValue(paymentOption);
      const price: string = paymentObj?.price ?? "";
      // setPaymentValue(price);
      setPaymentValue(
        process.env.REACT_APP_ENV !== "development" ? price : "0.0001"
      );
    }
  }, [paymentOption, paymentToken]);

  async function getUsdToEthConversion(usdAmount: number) {
    try {
      const response = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd&x_cg_demo_api_key=CG-zBv1pMHa1vPHydmRTrsbE313"
      );
      const ethPriceInUsd = response.data.ethereum.usd;

      // Calculate the amount of ETH for the given USD
      const ethAmount = usdAmount / ethPriceInUsd;

      console.log(`USD ${usdAmount} is approximately ETH ${ethAmount}`);
      return ethAmount;
    } catch (error) {
      console.error("Error fetching conversion rate:", error);
    }
  }

  async function getUsdToBTCConversion(usdAmount: number) {
    try {
      const response = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd&x_cg_demo_api_key=CG-zBv1pMHa1vPHydmRTrsbE313"
      );
      const btcPriceInUsd = response.data.bitcoin.usd;
      const btcAmount = usdAmount / btcPriceInUsd;
      console.log(`USD ${usdAmount} is approximately BTC ${btcAmount}`);
      return btcAmount;
    } catch (error) {
      console.error("Error fetching conversion rate:", error);
    }
  }

  async function getUsdToSolConversion(
    usdAmount: number
  ): Promise<number | undefined> {
    try {
      // Fetch the price of SOL in USD from CoinGecko
      const response = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd&x_cg_demo_api_key=CG-zBv1pMHa1vPHydmRTrsbE313"
      );
      const solPriceInUsd = response.data.solana.usd;

      // Calculate the amount of SOL for the given USD amount
      const solAmount = usdAmount / solPriceInUsd;

      console.log(`USD ${usdAmount} is approximately SOL ${solAmount}`);
      return solAmount;
    } catch (error) {
      console.error("Error fetching conversion rate:", error);
      return undefined;
    }
  }

  const sendTokensEthereum = async () => {
    setLoading(true);
    try {
      if (paymentToken === "ETH") {
        setModalOpen(true);
        setPaymentStatus(["initiated"]);
        const value = parseFloat(ethInUsd?.toString() ?? "0");
        // const value = "0.00001";
        const {
          CONTRACT_ADDRESS: TICKET_PAYMENT_CONTRACT_ADDRESS,
          ABI: TICKET_PAYMENT_ABI,
        } = (network === "ethereum" ? ethereumConfigs : baseConfigs)
          .TICKET_PAYMENT;

        let signature, nonce;

        if (network === "ethereum") {
          const res = await getEthereumSignature({
            chain: network ?? "",
            contractAddress: TICKET_PAYMENT_CONTRACT_ADDRESS,
            userWalletAddress: account ?? "",
          });
          signature = res.signature;
          nonce = res.nonce;
        } else {
          const res = await getBaseSignature({
            chain: network ?? "",
            contractAddress: TICKET_PAYMENT_CONTRACT_ADDRESS,
            userWalletAddress: account ?? "",
            paymentTokenAddress: "0x0000000000000000000000000000000000000000",
            payment: value.toString(),
          });
          signature = res.signature;
          nonce = res.nonce;
        }
        setPaymentStatus((prev) => [...prev, "signing"]);
        setPaymentStatus((prev) => [...prev, "paymentRequest"]);

        const paymentTx = await connector?.callContractMethod(
          TICKET_PAYMENT_CONTRACT_ADDRESS,
          TICKET_PAYMENT_ABI,
          "payment",
          [
            signature,
            nonce,
            "0x0000000000000000000000000000000000000000",
            parseEther(value.toString()),
          ],
          true,
          value.toString()
        );

        setTransactionHash(paymentTx.hash);
        await paymentTx.wait();
        setPaymentStatus((prev) => [...prev, "completed"]);

        return;
      }

      if (paymentToken && ["USDC", "USDT", "DAI"].includes(paymentToken)) {
        if (paymentToken === "USDT" && network === "base")
          throw new Error("USDT not supported on Base");
        setModalOpen(true);
        setPaymentStatus(["initiated"]);
        const config = network === "ethereum" ? ethereumConfigs : baseConfigs;
        const {
          CONTRACT_ADDRESS: TICKET_PAYMENT_CONTRACT_ADDRESS,
          ABI: TICKET_PAYMENT_ABI,
        } = config.TICKET_PAYMENT;
        // @ts-ignore
        const { CONTRACT_ADDRESS, DIGITS, ABI } = config[paymentToken];

        const value = BigInt(
          Math.floor(parseFloat(paymentValue?.toString() ?? "0") * 10 ** DIGITS)
        );

        setPaymentStatus((prev) => [...prev, "approvalRequest"]);
        // get allowance
        const allowance = await connector?.callContractMethod(
          CONTRACT_ADDRESS,
          ABI,
          "allowance",
          [account, TICKET_PAYMENT_CONTRACT_ADDRESS],
          false
        );
        console.log(allowance, "allowance");
        if (allowance < value) {
          const allowanceTx = await connector?.callContractMethod(
            CONTRACT_ADDRESS,
            ABI,
            "approve",
            [TICKET_PAYMENT_CONTRACT_ADDRESS, value],
            true
          );
          await allowanceTx.wait();
        }
        setPaymentStatus((prev) => [...prev, "approval"]);
        console.log("Approval granted");

        let signature, nonce;

        if (network === "ethereum") {
          const res = await getEthereumSignature({
            chain: network ?? "",
            contractAddress: TICKET_PAYMENT_CONTRACT_ADDRESS,
            userWalletAddress: account ?? "",
          });
          signature = res.signature;
          nonce = res.nonce;
        } else {
          const res = await getBaseSignature({
            chain: network ?? "",
            contractAddress: TICKET_PAYMENT_CONTRACT_ADDRESS,
            userWalletAddress: account ?? "",
            paymentTokenAddress: CONTRACT_ADDRESS,
            payment: value.toString(),
          });
          signature = res.signature;
          nonce = res.nonce;
        }

        setPaymentStatus((prev) => [...prev, "signing"]);
        setPaymentStatus((prev) => [...prev, "paymentRequest"]);

        const paymentTx = await connector?.callContractMethod(
          TICKET_PAYMENT_CONTRACT_ADDRESS,
          TICKET_PAYMENT_ABI,
          "payment",
          [signature, nonce, CONTRACT_ADDRESS, value],
          true
        );

        console.log(paymentTx, "receipt");
        setTransactionHash(paymentTx.hash);
        await paymentTx.wait();

        setPaymentStatus((prev) => [...prev, "completed"]);
      }
    } catch (err) {
      console.error(err);
      setPaymentStatus((prev) => [...prev, "error"]);
      setPaymentErrorMessage(
        "Error in payment, could be due to insufficient funds"
      );
    }
    setLoading(false);
  };

  const sendTokensTron = async () => {
    try {
      if (paymentToken !== "USDT") {
        throw new Error("Token not supported on tron");
      }
      setModalOpen(true);
      setPaymentStatus(["initiated"]);
      const { CONTRACT_ADDRESS, DIGITS, ABI } = tronConfigs[paymentToken];

      const {
        CONTRACT_ADDRESS: TICKET_PAYMENT_CONTRACT_ADDRESS,
        ABI: TICKET_PAYMENT_ABI,
      } = tronConfigs.TICKET_PAYMENT;

      const value = Number(parseFloat(paymentValue ?? "0") * 10 ** DIGITS);

      setPaymentStatus((prev) => [...prev, "approvalRequest"]);
      // get allowance
      await connector?.callContractMethod(
        CONTRACT_ADDRESS,
        ABI,
        "approve(address, uint256)",
        [TICKET_PAYMENT_CONTRACT_ADDRESS, value],
        true
      );
      setPaymentStatus((prev) => [...prev, "approval"]);
      console.log("Approval granted");

      const { signature, nonce } = await getTronSignature({
        contractAddress: TICKET_PAYMENT_CONTRACT_ADDRESS,
        userWalletAddress: account ?? "",
        paymentTokenAddress: CONTRACT_ADDRESS,
        payment: value,
      });
      setPaymentStatus((prev) => [...prev, "signing"]);
      setPaymentStatus((prev) => [...prev, "paymentRequest"]);

      const receipt = await connector?.callContractMethod(
        TICKET_PAYMENT_CONTRACT_ADDRESS,
        TICKET_PAYMENT_ABI,
        "payment(bytes, uint256, address, uint256)",
        [signature, nonce, CONTRACT_ADDRESS, value],
        true
      );
      setPaymentStatus((prev) => [...prev, "completed"]);
      setTransactionHash(receipt);
      console.log("Trx successfull");
    } catch (err) {
      console.log(err, "error");
      setPaymentStatus((prev) => [...prev, "error"]);
      setPaymentErrorMessage(
        "Error in payment, could be due to insufficient funds"
      );
    }
  };

  const sendTokensSolana = async () => {
    try {
      if (paymentToken === "SOL") {
        setModalOpen(true);
        setPaymentStatus(["initiated"]);
        const value = solanaInUsd?.toString() || "0";
        // const value = "0.00001";
        const { CONTRACT_ADDRESS: PROGRAM_ID } = solanaConfigs.TICKET_PAYMENT;
        const { STATE, TREASURY } = solanaConfigs.ACCOUNTS;
        const programId = PROGRAM_ID;
        const method = "paymentSol";
        const args = [new BN(LAMPORTS_PER_SOL * parseFloat(value))];
        const additionalKeys = [
          {
            pubkey: new PublicKey(STATE),
            propertyName: "state",
          },
          {
            pubkey: new PublicKey(TREASURY),
            propertyName: "treasury",
          },
          {
            pubkey: new PublicKey(account ?? ""),
            propertyName: "buyer",
          },
        ];

        console.log("Calling send contract");
        setPaymentStatus((prev) => [...prev, "paymentRequest"]);
        const receipt = await connector?.callContractMethod(
          programId,
          [],
          method,
          args,
          true,
          value,
          additionalKeys
        );

        console.log(receipt, "result from trx");
        setPaymentStatus((prev) => [...prev, "completed"]);
        setTransactionHash(receipt);

        return;
      }
      if (paymentToken && ["USDC", "USDT", "DAI"].includes(paymentToken)) {
        // if (providerType === "walletconnect") {
        //   alert("Token is not supported on walletconnect");
        //   throw new Error("Token is not supported on walletconnect");
        // }
        if (paymentToken !== "USDC" && !isProduction)
          throw new Error("Token not supported on devnet");

        setModalOpen(true);
        setPaymentStatus(["initiated"]);
        const value = paymentValue?.toString() ?? "0";
        const { RPC } = solanaConfigs;
        const { CONTRACT_ADDRESS: PROGRAM_ID } = solanaConfigs.TICKET_PAYMENT;
        const { STATE, TOKEN_PROGRAM } = solanaConfigs.ACCOUNTS;
        const { DIGITS, CONTRACT_ADDRESS, TREASURY_TOKEN_ACCOUNT_ADDRESS } =
          solanaConfigs[paymentToken as "USDC" | "USDT" | "DAI"];

        console.log(CONTRACT_ADDRESS, "token contract");
        const programId = PROGRAM_ID;
        const method = "paymentSpl";
        const connection = new Connection(RPC);

        const associatedTokenAccount = await getAssociatedTokenAddress(
          new PublicKey(CONTRACT_ADDRESS),
          new PublicKey(account ?? "")
        );
        // Check if token account exists for user else throw
        await getAccount(connection, associatedTokenAccount);

        const args = [
          new BN(parseFloat(value) * 10 ** DIGITS),
          new PublicKey(CONTRACT_ADDRESS),
        ];
        const additionalKeys = [
          {
            pubkey: new PublicKey(STATE),
            propertyName: "state",
          },
          {
            pubkey: new PublicKey(account ?? ""),
            propertyName: "buyer",
          },
          {
            pubkey: associatedTokenAccount,
            propertyName: "buyerTokenAccount",
          },
          {
            pubkey: new PublicKey(TREASURY_TOKEN_ACCOUNT_ADDRESS),
            propertyName: "treasuryTokenAccount",
          },
          {
            pubkey: new PublicKey(TOKEN_PROGRAM),
            propertyName: "tokenProgram",
          },
        ];

        console.log("Calling send contract");

        setPaymentStatus((prev) => [...prev, "paymentRequest"]);
        const receipt = await connector?.callContractMethod(
          programId,
          [],
          method,
          args,
          true,
          value,
          additionalKeys
        );

        setTransactionHash(receipt);

        const isConfirmed = await connection.confirmTransaction(
          receipt,
          "confirmed"
        );

        console.log(isConfirmed, "is confirmed");
        setPaymentStatus((prev) => [...prev, "completed"]);
        return;
      }
    } catch (err) {
      setPaymentStatus((prev) => [...prev, "error"]);
      console.log(err, "error");
      if (err instanceof Error) {
        // Handle the specific error type
        if (err.name === "TransactionExpiredTimeoutError") {
          console.log("The transaction timed out. Please try again.");
          setPaymentErrorMessage(
            `Trx took more than 30s, please check explorer for status.`
          );
        } else {
          setPaymentErrorMessage(err.message);
          console.log("An unexpected error occurred.");
        }
      } else {
        console.error("Unknown error:", err);
        setPaymentErrorMessage(
          "Error in payment, could be due to insufficient funds"
        );
      }
    }
  };

  const nowPaymentsPaymentNow = async () => {
    if (paymentMethod === "NORMAL") {
      setUnipayLoading(true);
      // handleMoonpay();
      const response = await getUnipaymentInvoiceLink({
        price_amount: parseFloat(
          getPaymentOptionFromValue(paymentOption)?.price || "0"
        ),
      });
      if (response?.url?.length > 0) {
        setUnipayLoading(false);
        await submitPayment({
          chain: "debit",
          token: "debit",
          price: parseFloat(
            getPaymentOptionFromValue(paymentOption)?.price || "0"
          ).toString(),
          transactionHash: response?.invoiceId || "",
          uniqueIdentifier: `direct-${paymentOption}`,
        });

        setUnipaymentIframe(response?.url);
        setInvoiceId(response?.invoiceId);
      } else {
        alert("Error in payment");
        console.log("res:::::::", response);
      }
    } else {
      if (!account) {
        if (openGlobalModal) {
          openGlobalModal({
            text: "Please connect your wallet to proceed.",
          });
        }
        return;
      }
      if (paymentOption === "Number of tickets") {
        alert("Please select appropriate number of tickets. payment method.");
        return;
      }
      if (paymentMethod !== "CRYPTO") {
        alert("Only crypto payment method is supported.");
        return;
      }
      if (network === "ethereum" || network === "base") {
        await sendTokensEthereum();
      } else if (network === "solana") {
        await sendTokensSolana();
      } else if (network === "tron") {
        await sendTokensTron();
      }
      if (paymentToken === "BTC") {
        const date = new Date();
        const body = {
          chain: paymentToken || "",
          token: paymentToken!,
          price: btcInUsd?.toString() || "0",
          transactionHash: transactionHash || "",
          uniqueIdentifier: `direct-${paymentOption}`,
        };
        setLoading(true);
        const res = await submitPayment(body);
        setLoading(false);
        setBuyTicketResponse(res);
        console.log(res, "result from payment submission");
      }
    }
  };

  const paymentNow = async () => {
    if (paymentMethod === "NORMAL") {
      setUnipayLoading(true);
      const response = await getUnipaymentInvoiceLink({
        price_amount: parseFloat(
          getPaymentOptionFromValue(paymentOption)?.price || "0"
        ),
      });
      if (response?.url?.length > 0) {
        // setInvoiceId(response?.invoiceId);
        setUnipayLoading(false);
        await submitPayment({
          chain: "debit",
          token: "debit",
          price: parseFloat(
            getPaymentOptionFromValue(paymentOption)?.price || "0"
          ).toString(),
          transactionHash: response?.invoiceId || "",
          uniqueIdentifier: `one-off-${paymentOption}`,
        });
        setUnipaymentIframe(response?.url);
        setInvoiceId(response?.invoiceId);
      } else {
        setUnipayLoading(false);
        alert("Error in payment");
        console.log("res:::::::", response);
      }
    } else {
      setUnipayLoading(false);
      if (!account) {
        if (openGlobalModal) {
          openGlobalModal({
            text: "Please connect your wallet to proceed.",
          });
        }
        return;
      }
      if (paymentOption === "Number of tickets") {
        alert("Please select appropriate number of tickets. payment method.");
        return;
      }
      if (paymentMethod !== "CRYPTO") {
        alert("Only crypto payment method is supported.");
        return;
      }
      if (network === "ethereum" || network === "base") {
        await sendTokensEthereum();
      } else if (network === "solana") {
        await sendTokensSolana();
      } else if (network === "tron") {
        await sendTokensTron();
      }
      if (paymentToken === "BTC") {
        const date = new Date();
        const body = {
          chain: paymentToken || "",
          token: paymentToken!,
          price: btcInUsd?.toString() || "0",
          transactionHash: transactionHash || "",
          uniqueIdentifier: `one-off-${paymentOption}`,
        };
        setLoading(true);
        const res = await submitPayment(body);
        setLoading(false);
        setBuyTicketResponse(res);
        console.log(res, "result from payment submission");
      }
    }
  };

  const handleFinalPayment = async () => {
    if (!paymentToken) {
      return;
    }
    if (paymentToken === "SOL") {
      const body = {
        chain: network || "",
        token: paymentToken!,
        price: solanaInUsd?.toString() || "0",
        transactionHash: transactionHash || "",
        uniqueIdentifier: `one-off-${paymentOption}`,
      };
      if (transactionHash && transactionHash?.length > 0) {
        const res = await submitPayment(body);
        setBuyTicketResponse(res);
        console.log(res, "result from payment submission");
      } else {
        alert("error");
      }
    }
    if (paymentToken === "ETH") {
      const body = {
        chain: network || "",
        token: paymentToken!,
        price: ethInUsd?.toString() || "0",
        transactionHash: transactionHash || "",
        uniqueIdentifier: `one-off-${paymentOption}`,
      };
      if (transactionHash && transactionHash?.length > 0) {
        const res = await submitPayment(body);
        setBuyTicketResponse(res);
        console.log(res, "result from payment submission");
      } else {
        alert("error");
      }
    }
    if (paymentToken && ["USDC", "USDT", "DAI"].includes(paymentToken)) {
      const body = {
        chain: network || "",
        token: paymentToken!,
        price: paymentValue?.toString() || "0",
        transactionHash: transactionHash || "",
        uniqueIdentifier: `one-off-${paymentOption}`,
      };
      if (transactionHash && transactionHash?.length > 0) {
        const res = await submitPayment(body);
        setBuyTicketResponse(res);
        console.log(res, "result from payment submission");
      } else {
        alert("error");
      }
    }

    if (paymentToken === "debit") {
      setIsCardTransactionStatusModalOpen(true);
      const body = {
        chain: "debit",
        token: "debit",
        price: parseFloat(
          getPaymentOptionFromValue(paymentOption)?.price || "0"
        ).toString(),
        transactionHash: invoiceId || "",
        uniqueIdentifier: `one-off-${paymentOption}`,
      };
      if (invoiceId && invoiceId?.length > 0) {
        try {
          const res = await submitPayment(body);
          setBuyTicketResponse(res);
          setUnipaymentIframe("");

          setTimeout(() => {
            setInvoiceStatus("completed");
            console.log(res, "result from payment submission");
          }, 5000);
        } catch (err) {
          console.log(err);
          setPaymentErrorMessage(
            "Error in payment, could be due to insufficient funds"
          );
          setInvoiceStatus("error");
        }
      } else {
        //alert("error");
      }
    }
  };

  // useEffect(() => {
  //   if (transactionHash && transactionHash?.length > 0) {
  //     console?.log("transaction hash: ", transactionHash);
  //     handleFinalPayment();
  //   }
  // }, [transactionHash]);

  useEffect(() => {
    if (!isModalOpen) {
      // @ts-ignore
      setPaymentStatus("initiated");
      setPaymentErrorMessage("");
      setTransactionHash("");
      setInvoiceStatus("pending");
    }
  }, [isModalOpen]);

  useEffect(() => {
    getPayments();
  }, [currentFilter]);

  const getConvertedValues = async () => {
    const ethInUsd = await getUsdToEthConversion(
      parseFloat(getPaymentOptionFromValue(paymentOption)?.price || "0")
    );
    const solanaInUsd = await getUsdToSolConversion(
      parseFloat(getPaymentOptionFromValue(paymentOption)?.price || "0")
    );

    const btcInUsd = await getUsdToBTCConversion(
      parseFloat(getPaymentOptionFromValue(paymentOption)?.price || "0")
    );

    setBtcInUsd(btcInUsd);
    setEthInUsd(parseFloat(ethInUsd!.toFixed(18)));
    setSolanaInUsd(parseFloat(solanaInUsd!.toFixed(9)));
  };

  useEffect(() => {
    if (paymentOption !== "Number of tickets") {
      getConvertedValues();
      // Reset payment method if we switch to an invalid option
      if (!validatePayWithCard() && paymentMethod === "NORMAL") {
        setPaymentMethod("CRYPTO");
        setPaymentToken(undefined);
      }
    }
  }, [paymentOption, paymentMethod, paymentToken]);

  const paymentCoins = ["ETH", "USDT", "USDC", "BTC"];
  // const { launchTransak } = useTransac();

  // const handleLaunchTransak = () => {
  //   launchTransak({
  //     fiatAmount: parseFloat(
  //       getPaymentOptionFromValue(paymentOption)?.price || "0"
  //     ),
  //     walletAddress: account ?? "",
  //     network: network ?? "",
  //   });
  // };
  useEffect(() => {
    const body = {
      quoteCurrency:
        paymentToken === "USDT" ||
        paymentToken === "USDC" ||
        paymentToken === "DAI"
          ? parseFloat(
              getPaymentOptionFromValue(paymentOption)?.price || "0"
            ).toString()
          : undefined,
      currencyCode:
        network === "base"
          ? paymentToken?.toLowerCase() + "_base"
          : network === "solana"
          ? paymentToken?.toLowerCase() + "_sol"
          : network === "tron"
          ? paymentToken?.toLowerCase() + "_tron"
          : paymentToken?.toLowerCase(),
      baseCurrencyAmount:
        paymentToken === "ETH"
          ? ethInUsd?.toString()
          : paymentToken === "BTC"
          ? btcInUsd?.toString()
          : paymentToken === "SOL"
          ? solanaInUsd?.toString()
          : undefined,
    };
    console.log("logging all parameters: ", body);
  }, [paymentOption, paymentToken, ethInUsd, btcInUsd, solanaInUsd, network]);

  const availablePaymentMethods = (network === "ethereum" &&
    ethereumConfigs?.AVAILABLE_PAYMENT_METHODS) ||
    (network === "base" && baseConfigs?.AVAILABLE_PAYMENT_METHODS) ||
    (network === "solana" && solanaConfigs?.AVAILABLE_PAYMENT_METHODS) ||
    (network === "tron" && tronConfigs?.AVAILABLE_PAYMENT_METHODS) || ["BTC"];

  useEffect(() => {
    // Initialize WebSocket connection
    const ws = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}`);

    ws.onopen = () => {
      console.log("WebSocket connected");

      // Send the subscription event
      ws.send(
        JSON.stringify({
          event: "subscribeToPurchases",
          token: storedToken,
        })
      );
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log(data, "WebSocket response");

      if (data?.payment_status === "confirmed") {
        // open processing transaction modal
        setIsCardTransactionStatusModalOpen(true);
      }

      // Handle purchase updates
      if (data.event === "purchaseUpdate") {
        setTransactionData(data?.data);
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket Error:", error);
    };

    ws.onclose = () => {
      console.log("WebSocket disconnected");
    };

    // Cleanup on component unmount
    return () => {
      ws.close();
    };
  }, [invoiceId]);

  useEffect(() => {
    if (
      transactionData?.TRANSACTION_HASH &&
      lastLoggedHash.current?.TRANSACTION_HASH !==
        transactionData.TRANSACTION_HASH
    ) {
      console.log("transactionData: ", transactionData);
      if (invoiceId === transactionData?.TRANSACTION_HASH) {
        handleFinalPayment();
      }

      if (paymentMethod === "CRYPTO") {
        console.log("transactionData crypto payment: ", transactionData);
        if (transactionHash === transactionData?.TRANSACTION_HASH) {
          setInvoiceStatus("completed");
        }
      }
      lastLoggedHash.current = transactionData;
    }
  }, [transactionData]);

  useEffect(() => {
    if (transactionHash && transactionHash?.length > 0) {
      console?.log("transaction hash: ", transactionHash);
      handleFinalPayment();
    }
  }, [transactionHash]);

  return (
    <div className="w-full flex gap-4 flex-col //sm:mx-2 //sm:w-[302px]">
      {unipayLoading && (
        <PopupWithIcon
          open={unipayLoading}
          onClose={() => setUnipayLoading(false)}
        >
          <div className="w-full flex flex-col flex-1 gap-4 items-center justify-center sm:p-4 xl:py-16 lg:py-16 md:py-16">
            <div className="flex flex-col items-center mt-5">
              {/* animate image it to spining */}
              <img
                className="animate-spin"
                src={"/Images/Loader.svg"}
                alt={""}
              />
            </div>
            <Common.typography
              fontFamily="Sequel100Wide95"
              fontSize="22px"
              fontSizeSmall="22px"
              color="#fff"
              className="uppercase text-center"
            >
              WE’RE WORKING ON IT
            </Common.typography>
          </div>
        </PopupWithIcon>
      )}
      {moonpayOpen &&
        (getPaymentOptionFromValue(paymentOption)?.price ?? "").length > 0 && (
          <div
            onClick={() => setMoonpayOpen(false)}
            className="w-full h-full fixed top-0 left-0 bg-black bg-opacity-50 z-[51] flex justify-center items-center"
          >
            {/* <MoonPayBuyWidget
              variant="embedded"
              theme="dark"
              // baseCurrencyCode="usd"
              currencyCode={paymentToken?.toLowerCase()}
              showOnlyCurrencies={
                network === "ethereum"
                  ? ["eth", "usdc", "usdt"].join(",")
                  : network === "base"
                  ? ["usdc_base"].join(",")
                  : network === "solana"
                  ? ["usdc_sol", "usdt_sol"].join(",")
                  : network === "tron"
                  ? ["usdt_tron"].join(",")
                  : ["btc"].join(",")
              }
              quoteCurrencyAmount={
                paymentToken === "USDT" ||
                paymentToken === "USDC" ||
                paymentToken === "DAI"
                  ? parseFloat(
                      getPaymentOptionFromValue(paymentOption)?.price || "0"
                    ).toString()
                  : undefined
              }
              baseCurrencyAmount={
                paymentToken === "ETH"
                  ? // ? ethInUsd?.toString()
                    "50"
                  : paymentToken === "BTC"
                  ? btcInUsd?.toString()
                  : paymentToken === "SOL"
                  ? solanaInUsd?.toString()
                  : undefined
              }
              // defaultCurrencyCode={paymentToken?.toLowerCase()}
              visible={moonpayOpen}
            /> */}
            <MoonPayBuyWidget
              variant="embedded"
              theme="dark"
              quoteCurrencyAmount={
                paymentToken === "USDT" ||
                paymentToken === "USDC" ||
                paymentToken === "DAI"
                  ? parseFloat(
                      getPaymentOptionFromValue(paymentOption)?.price || "0"
                    ).toString()
                  : undefined
              }
              // currencyCode={paymentToken?.toLowerCase()}
              currencyCode={
                network === "base"
                  ? paymentToken?.toLowerCase() + "_base"
                  : network === "solana"
                  ? paymentToken?.toLowerCase() + "_sol"
                  : network === "tron"
                  ? paymentToken?.toLowerCase() + "_tron"
                  : paymentToken?.toLowerCase()
              }
              baseCurrencyAmount={
                paymentToken === "ETH"
                  ? ethInUsd?.toString()
                  : paymentToken === "BTC"
                  ? btcInUsd?.toString()
                  : paymentToken === "SOL"
                  ? solanaInUsd?.toString()
                  : undefined
              }
              showOnlyCurrencies={
                network === "ethereum"
                  ? ["eth", "usdc", "usdt"].join(",")
                  : network === "base"
                  ? ["usdc_base"].join(",")
                  : network === "solana"
                  ? ["usdc_sol", "usdt_sol"].join(",")
                  : network === "tron"
                  ? ["usdt_tron"].join(",")
                  : network === "btc"
                  ? ["btc"].join(",")
                  : ["eth", "usdc", "usdt"].join(",")
              }
              visible={moonpayOpen}
              onTransactionCompleted={async () => {
                setMoonpayOpen(true);
                sendTokensEthereum();
              }}
            />
          </div>
        )}
      {/* <Loader show={loading} /> */}
      <div className="w-full flex gap-4 sm:flex-col justify-center //sm:mx-2 //sm:w-[302px]">
        <div className="dropdown xl:w-full //xl:max-w-[425px] xl:max-w-[607px] sm_md:w-full m-auto">
          <div className="w-full uppercase rounded-[10px] bg-primary flex items-center justify-between gap-8 h-[56px] sm_md:h-[39px] p-[1em] cursor-pointer">
            <Common.typography
              fontFamily="Sequel100Wide95"
              fontSize="20px"
              fontSizeSmall="16px"
              color="#1A1A1A"
            >
              {/* PAY AS YOU GO */}
              Discount Packages
            </Common.typography>
          </div>

          <div className="border-4 xl:px-[13px] px-[15px] w-full items-left flex-wrap border-primary mt-[-20px] border-t-0 flex gap-4 //items-end sm:flex-col pt-[3em] py-[2em] justify-center sm:px-[15px] rounded-b-[1.5rem] flex-col">
          <div className="max-w-[100%] m-auto">
            <Common.typography
              fontFamily="Sequel100Wide-65"
              fontSize="14px"
              fontSizeSmall="14px"
              color="#FFFFFF"
            >
              SELECT THE QUANTITY OF TICKETS YOU WISH TO PURCHASE. THE OTHER
              FIELDS WILL FILL OUT AUTOMATICALLY.
            </Common.typography>
          </div>
            {/* Number of tickets */}
            <div className="relative z-50 ">
              <Dropdown
                key={1}
                options={numberOfTickets}
                selected={paymentOption}
                optionType="payments"
                setSelected={(value) => {
                  setPaymentOption(value);
                }}
              />
            </div>
            <div className="flex w-full gap-[11px] sm:flex-col">
              {/* Price per ticket */}
              <Common.textfield
                className="placeholder:text-[#fff] placeholder:uppercase !text-white xl:!text-[#fff] lg:!text-[#FFF]"
                fontFamily="Sequel100Wide-65"
                fontSizeBig="15px"
                fontSizeSmall="15px"
                textColor="#959595"
                heightBig="51px"
                heightSmall="45px"
                // maxWidthBig="194px"
                maxWidthBig="100%"
                maxWidthSmall="100%"
                placeholder="price per ticket"
                backgroundColor="#2c2c2c"
                roundness="10px"
                paddingX="17px"
                paddingXSmall="18px"
                value={
                  "PRICE PER TICKET: $" +
                  (
                    Number(
                      getPaymentOptionFromValue(paymentOption)?.price || 0
                    ) /
                    Number(getPaymentOptionFromValue(paymentOption)?.value || 1)
                  ) // Prevent division by zero
                    .toFixed(2)

                  // (getPaymentOptionFromValue(paymentOption)
                  //     ?.price || 0)
                }
                disabled
              />
              {/* Discount */}
              <Common.textfield
                className="placeholder:text-[#fff] placeholder:uppercase !text-white xl:!text-[#fff] lg:!text-[#FFF]"
                fontFamily="Sequel100Wide-65"
                fontSizeBig="15px"
                fontSizeSmall="15px"
                textColor="#959595"
                heightBig="51px"
                heightSmall="45px"
                // maxWidthBig="194px"
                maxWidthBig="100%"
                maxWidthSmall="100%"
                placeholder="discount"
                backgroundColor="#2c2c2c"
                roundness="10px"
                paddingX="17px"
                paddingXSmall="18px"
                value={
                  "DISCOUNT: $" +
                  (getPaymentOptionFromValue(paymentOption)?.savings || 0)
                }
                disabled
              />
            </div>
            {/* Total Price */}
            <Common.textfield
              className="placeholder:text-[#fff] placeholder:uppercase !text-white xl:!text-[#fff] lg:!text-[#FFF]"
              fontFamily="Sequel100Wide-65"
              fontSizeBig="15px"
              fontSizeSmall="15px"
              textColor="#959595"
              heightBig="51px"
              heightSmall="45px"
              maxWidthBig="100%"
              maxWidthSmall="100%"
              placeholder="total price"
              backgroundColor="#2c2c2c"
              roundness="10px"
              paddingX="17px"
              paddingXSmall="18px"
              value={
                "TOTAL PRICE: $" +
                parseFloat(
                  getPaymentOptionFromValue(paymentOption)?.price || "0"
                ).toFixed(2)
              }
              disabled
            />
            {/* Payment Method */}
            {/* <Dropdown
              key={2}
              options={paymentMethods}
              selected={paymentMethod}
              optionType="normal"
              setSelected={(value) => {
                setPaymentMethod(value);
              }}
            /> */}
            <div className="flex flex-col justify-center items-center gap-[10px]">
              <Common.typography
                fontFamily="Sequel100WideVF45"
                fontSize="16px"
                fontSizeSmall="14px"
                color="#FFFFFF"
                className="uppercase"
              >
                Payment Method
              </Common.typography>
              <div className="flex gap-[13px] xl:gap-[10px] lg:gap-[10px] justify-center items-center w-full cursor-pointer">
                <button
                  disabled={validatePayWithCard() ? false : true}
                  onClick={() => {
                    setPaymentMethod("NORMAL");
                    setPaymentToken("debit");
                    // handleLaunchTransak();
                  }}
                  className={`max-w-[209px] h-[45px]  rounded-[10px] flex flex-col justify-center items-center p-1 flex-1 w-max ${
                    paymentMethod === "NORMAL"
                      ? "bg-secondary *:!text-white"
                      : "bg-white disabled:bg-[#2c2c2c] disabled:cursor-not-allowed"
                  }`}
                >
                  <Common.typography
                    fontFamily="Sequel100Wide-65"
                    fontSize="10px"
                    fontSizeSmall="10px"
                    color="#000000"
                    className="uppercase"
                  >
                    crypto &
                  </Common.typography>
                  <Common.typography
                    fontFamily="Sequel100Wide"
                    fontSize="17.44px"
                    fontSizeSmall="17.44px"
                    color="#000000"
                    className="uppercase"
                  >
                    Card
                  </Common.typography>
                </button>

                <div
                  onClick={() => {
                    setPaymentMethod("CRYPTO");
                    setPaymentToken(undefined);
                  }}
                  className={`max-w-[209px] h-[45px] rounded-[10px] flex flex-col justify-center items-center p-1 flex-1 w-max
                    ${
                      paymentMethod === "CRYPTO"
                        ? "bg-secondary *:!text-white"
                        : "bg-white"
                    }
                    `}
                >
                  <Common.typography
                    fontFamily="Sequel100Wide-65"
                    fontSize="10px"
                    fontSizeSmall="10px"
                    color="#000000"
                    className="uppercase"
                  >
                    Crypto
                  </Common.typography>
                  <Common.typography
                    fontFamily="Sequel100Wide"
                    fontSize="17.44px"
                    fontSizeSmall="17.44px"
                    color="#000000"
                    className="uppercase"
                  >
                    Wallet
                  </Common.typography>
                </div>
              </div>
            </div>

            {paymentMethod === "CRYPTO" && (
              <div className="flex gap-[11.69px] justify-center">
                {availablePaymentMethods.map((coin) => (
                  <div
                    onClick={() => setPaymentToken(coin)}
                    className="max-w-[65.48px] w-full cursor-pointer"
                  >
                    <div
                      className="max-w-[65.48px] h-[65.48px] w-full //min-w-0 bg-primary flex justify-center items-center rounded-[7.77px]"
                      style={{
                        border: paymentToken === coin ? "6px solid #fff" : "",
                      }}
                    >
                      <img src={`/Images/${coin}_Icon.svg`} alt={coin} />
                    </div>
                    <Common.typography
                      fontFamily="Sequel100Wide85"
                      fontSize="14px"
                      fontSizeSmall="12px"
                      color="#FFFFFF"
                      className="uppercase text-center"
                    >
                      {coin}
                    </Common.typography>
                  </div>
                ))}
              </div>
            )}
            {/* {paymentMethod === "CRYPTO" && (
              <>
                <Dropdown
                  key={2}
                  options={paymentTokensList ?? []}
                  selected={paymentToken ?? ""}
                  optionType="normal"
                  setSelected={(value) => {
                    setPaymentToken(value);
                  }}
                />
              </>
            )} */}
            <div className="flex flex-col gap-4 mx-2">
              {paymentToken === "ETH" && (
                <Common.typography
                  fontFamily="Sequel100Wide-65"
                  fontSize="14px"
                  fontSizeSmall="14px"
                  color="#FFFFFF"
                >
                  {/* {`$23.75 ≈ 0.003 ETH`} */}
                  {`$${
                    getPaymentOptionFromValue(paymentOption)?.price ?? "0"
                  } ≈ ${ethInUsd ? ethInUsd : "0"} ETH`}
                </Common.typography>
              )}

              {paymentToken === "BTC" &&
                paymentOption !== "Number of tickets" && (
                  <Common.typography
                    fontFamily="Sequel100Wide-65"
                    fontSize="14px"
                    fontSizeSmall="14px"
                    color="#FFFFFF"
                  >
                    {/* {`$23.75 ≈ 0.003 ETH`} */}
                    {`$${getPaymentOptionFromValue(paymentOption)?.price} ≈ ${
                      btcInUsd ? btcInUsd : ""
                    } BTC`}
                  </Common.typography>
                )}

              {paymentToken === "SOL" &&
                paymentOption !== "Number of tickets" && (
                  <Common.typography
                    fontFamily="Sequel100Wide-65"
                    fontSize="14px"
                    fontSizeSmall="14px"
                    color="#FFFFFF"
                  >
                    {/* {`$23.75 ≈ 0.003 ETH`} */}
                    {`$${getPaymentOptionFromValue(paymentOption)?.price} ≈ ${
                      solanaInUsd ? solanaInUsd : ""
                    } SOL`}
                  </Common.typography>
                )}
            </div>
            <button
              // onClick={paymentNow}
              onClick={nowPaymentsPaymentNow}
              className={`
              bg-[#dde406]
               xl:py-[13px] w-full rounded-[10px] xl:text-[20px] font-[Sequel100wide] h-[45px] xl:h-[51px] uppercase`}
              // disabled={paymentMethod === "NORMAL"}
            >
              Pay Now
            </button>
            <PaymentStatusModal
              value={parseFloat(
                getPaymentOptionFromValue(paymentOption)?.price || "0"
              ).toFixed(2)}
              isOpen={isModalOpen}
              onClose={
                transactionHash && transactionHash?.length > 0
                  ? () => window.location.reload()
                  : () => setModalOpen(false)
              }
              enterCompetition={() => {}}
              paymentOption={paymentOption}
              paymentStatus={paymentStatus}
              transactionHash={transactionHash}
              errorMessage={paymentErrorMessage}
              isApprovalStage={paymentToken !== "ETH" && network !== "solana"}
              isSigningStage={network !== "solana"}
              invoiceStatus={invoiceStatus}
              message="PROCESSING TRANSACTION"
            />

            <PaymentTransactionModal
              isOpen={isCardTransactionStatusModalOpen}
              onClose={
                invoiceId && invoiceId?.length > 0
                  ? () => window.location.reload()
                  : () => setIsCardTransactionStatusModalOpen(false)
              }
              errorMessage={paymentErrorMessage}
              paymentOption={paymentOption}
              transactionHash={invoiceId}
              paymentStatus={invoiceStatus}
              message="PROCESSING TRANSACTION"
            />
          </div>
        </div>
      </div>
      {skipable && (
        <div className="flex flex-row justify-center mt-4 w-full">
          <Button
            loading={loading}
            onClick={login}
            content="Skip"
            className={`w-[149px] h-[45px] xl:h-[51px] font-['MontBlancBlack'] text-[14px] xl:text-[20px] text-center text-[#2D2022] font-extrabold uppercase bg-primary  border-2 border-white`}
          />
        </div>
      )}

      {isIFrameOpen && buyTicketResponse?.data?.checkoutLink?.length > 0 && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[100000000002]">
          <div className="bg-primary p-2 rounded-lg shadow-lg w-[80%] h-[80%] relative ">
            <div className="w-full flex justify-between">
              <div className="w-full">
                <p className="font-[Sequel100Wide-65] text-center text-[clamp(8px,1.5vw,14px)]">
                  To complete your purchase please send the total amount of BTC
                  quoted below to our BTC address
                </p>
              </div>
              <div className="//w-full //flex //justify-end">
                <Button
                  onClick={() => setPopup(true)}
                  content="X"
                  className="w-10 h-10 rounded-full"
                />
              </div>
            </div>
            <iframe
              style={{
                pointerEvents: popup ? "none" : "auto",
              }}
              src={buyTicketResponse && buyTicketResponse?.data?.checkoutLink}
              title="Payment Iframe"
              className="w-full h-[93%]"
            ></iframe>

            {/* popup confirm before close */}
            {popup && (
              <ConfirmationPayment
                isOpen={popup}
                setIsOpen={setPopup}
                handleConfirm={() => {
                  setPopup(false);
                  setIsIFrameOpen(false);
                }}
              />
            )}
          </div>
        </div>
      )}
      {(unipaymentIframe?.length ?? 0) > 0 && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[100000000002]">
          <div className="bg-[#e5e5e5] p-2 rounded-lg shadow-lg xl:w-[50%] lg:w-[50%] md:w-[95%] sm:w-[95%] h-[80%] relative ">
            <div className="w-full flex justify-between">
              <div className="//w-full //flex //justify-end">
                <Button
                  onClick={() => setUnipaymentIframe("")}
                  content="X"
                  customStyle={{
                    backgroundColor: "#2D2022",
                    color: "#fff",
                  }}
                  className="w-10 h-10 rounded-full"
                />
              </div>
            </div>
            <iframe
              // style={{
              //   pointerEvents: popup ? "none" : "auto",
              // }}
              src={unipaymentIframe}
              title="Payment Iframe"
              className="w-full h-[93%]"
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
}

export default Payments;
