import React, { useState } from "react";
import { Common } from "../common/Common";
import VSpacer from "../common/Spacer/VSpacer";
import PageLink, { PageLinkProps } from "../Link";
import { Link, useNavigate } from "react-router-dom";
import EmailConfirmationPopup from "../common/confirmation/email_confirmation";

interface INavbarDropdown {
  navLinks: PageLinkProps[];
  currentRoute: string;
  tickets: number;
  walletAddress: string;
  name: string;
  profileImage: string;
}

export default function NavbarDropdown(props: INavbarDropdown) {
  return (
    <MainContainer>
      {props.walletAddress && (
        <>
          <ProfileInfo {...props} />
          <ButtonsGroup {...props} />
          <TicketCounter {...props} />
        </>
      )}
      <Navlinks {...props} />
    </MainContainer>
  );
}

const MainContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <div className="bg-[#232323] flex flex-col justify-center items-center">
    {children}
  </div>
);

const ProfileInfo = (props: any) => {
  const truncatedWallet = (str: string) =>
    str?.length > 15 ? `${str.slice(0, 10)}...${str.slice(-5)}` : str;
  const truncatedName = (str: string) =>
    str?.length > 8 ? `${str.slice(0, 8)}...` : str;
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="flex flex-row gap-x-[18px] w-full justify-center items-center mt-[28px]">
      {showPopup && <EmailConfirmationPopup />}
      <img
        onClick={() => navigate("/account")}
        className="max-w-[75px] h-[75px] rounded-[10px]"
        src={props?.profileImage}
        alt="Profile"
      />

      <div className="flex flex-col gap-y-[6px]">
        <div className="flex flex-row gap-x-[12px]">
          <img src="/Images/paper_plane.svg" alt="Paper Plane" />
          {props?.name ? (
            <Common.typography
              className="truncate"
              fontSize="18px"
              fontSizeSmall="18px"
              color="#FFFFFF"
              fontFamily="Sequel100Wide95"
            >
              {truncatedName(props?.name)}
            </Common.typography>
          ) : (
            <Common.typography
              className="uppercase cursor-pointer"
              fontSize="14px"
              fontSizeSmall="14px"
              color="#FF0B0B"
              fontFamily="Sequel100Wide95"
              onClick={() => setShowPopup(true)}
            >
              Enter tg handle
            </Common.typography>
          )}
        </div>
        <hr className="h-0 border-[2.88px]" />
        <Common.typography
          className=""
          fontSize="20.02px"
          fontSizeSmall="20.02px"
          color="#FFFFFF"
          fontFamily="Sequel100WideVF45"
        >
          {truncatedWallet(props?.walletAddress)}
        </Common.typography>
      </div>
    </div>
  );
};

const ButtonsGroup = (props: any) => {
  const navigate = useNavigate();
  return (
    <div className="grid grid-cols-1 gap-[20px] mt-[20px] w-[90%]">
      <Common.button
        backgroundColor="#DDE404"
        color="#000000"
        border="1px solid white"
        fontSize="16px"
        fontSizeSmall="16px"
        fontFamily="Sequel100Wide95"
        className="w-full uppercase"
        heightBig="41px"
        heightSmall="41px"
        maxWidthBig="100%"
        maxWidthSmall="100%"
        roundness="20px"
        onClick={() => navigate("/buytickets")}
      >
        Buy Tickets
      </Common.button>
      <div className="grid grid-cols-1 gap-[11px]">
        <div className="grid grid-cols-2 gap-[9px]">
          <Common.button
            backgroundColor={
              props?.currentRoute === "/entries/live"
                ? "#EF008F"
                : "transparent"
            }
            color="#FFFFFF"
            border="1px solid white"
            heightBig="32px"
            heightSmall="32px"
            maxWidthBig="100%"
            maxWidthSmall="100%"
            roundness="20px"
            fontFamily="Sequel100Wide95"
            fontSize="16px"
            fontSizeSmall="16px"
            className="uppercase"
            onClick={() => navigate("/entries/live")}
          >
            Entries
          </Common.button>
          <Common.button
            backgroundColor={
              props?.currentRoute === "/orders" ? "#EF008F" : "transparent"
            }
            color="#FFFFFF"
            border="1px solid white"
            heightBig="32px"
            heightSmall="32px"
            maxWidthBig="100%"
            maxWidthSmall="100%"
            roundness="20px"
            fontFamily="Sequel100Wide95"
            fontSize="16px"
            fontSizeSmall="16px"
            className="uppercase"
            onClick={() => navigate("/orders")}
          >
            Orders
          </Common.button>
        </div>
        <div className="grid grid-cols-2 gap-[9px]">
          <Common.button
            backgroundColor={
              props?.currentRoute === "/wallet" ? "#EF008F" : "transparent"
            }
            color="#FFFFFF"
            border="1px solid white"
            heightBig="32px"
            heightSmall="32px"
            maxWidthBig="100%"
            maxWidthSmall="100%"
            roundness="20px"
            fontFamily="Sequel100Wide95"
            fontSize="16px"
            fontSizeSmall="16px"
            className="uppercase"
            onClick={() => navigate("/wallet")}
          >
            Wallet
          </Common.button>
          <Common.button
            backgroundColor={
              props?.currentRoute === "/account" ? "#EF008F" : "transparent"
            }
            color="#FFFFFF"
            border="1px solid white"
            heightBig="32px"
            heightSmall="32px"
            maxWidthBig="100%"
            maxWidthSmall="100%"
            roundness="20px"
            fontFamily="Sequel100Wide95"
            fontSize="16px"
            fontSizeSmall="16px"
            className="uppercase"
            onClick={() => navigate("/account")}
          >
            Account
          </Common.button>
        </div>
      </div>
    </div>
  );
};

const TicketCounter = (props: any) => (
  <div className="bg-primary h-[44px] w-full flex justify-center items-center gap-[1.35px] mt-[20px]">
    <Common.typography
      className="uppercase"
      fontSize="18px"
      fontSizeSmall="18px"
      color="#00000"
      fontFamily="Sequel100WideVF45"
    >
      Tickets:
    </Common.typography>
    <Common.typography
      className="uppercase"
      fontSize="18px"
      fontSizeSmall="18px"
      color="#00000"
      fontFamily="Sequel100Wide95"
    >
      {props?.tickets
        ? props?.tickets >= 1000
          ? `${(props.tickets / 1000).toFixed(1)}k`
          : props.tickets
        : 0}
    </Common.typography>
    <img src="/Images/ticket2.svg" alt="Ticket icon" />
  </div>
);

const Navlinks = (props: any) => {
  return (
    <div className="flex flex-col gap-[13px] w-full p-[22px] bg-[#2b2b2b] relative">
      {props.navLinks &&
        props.navLinks.map((link: any, index: any) => (
          <div key={index} className="flex items-center justify-between pl-2">
            <Link
              to={link.src}
              className={`${
                link.src === props.currentRoute
                  ? "text-primary font-[Sequel100Wide95]"
                  : "font-[Sequel100WideVF45] text-white"
              } uppercase text-[18px] `}
            >
              {" "}
              {link.content}
            </Link>
            {link.src === props.currentRoute && (
              <img src="/Images/pointer.png" alt="" />
            )}
          </div>
        ))}
    </div>
  );
};
