import { copyToClipboard } from "../../../../utils/CopyToClipboard";
import { Common } from "../../../common/Common";

interface ITableSmallValue {
  text: string;
  copyable?: boolean;
  isTXHash?: boolean;
  isWinner?: boolean;
}

export default function TableSmallValue(props: ITableSmallValue) {
  const truncatedTitle =
    props.text.length > 12 ? props.text.slice(0, 10) + "..." : props.text;
  const handleCopy = () => {
    if (props?.isTXHash) {
      //navigator.clipboard.writeText(`https://basescan.org/tx/${props.text}`);
      //alert("Copied to clipboard");
      copyToClipboard(`https://basescan.org/tx/${props.text}`);
    } else {
      //navigator.clipboard.writeText(props.text);
      //alert("Copied to clipboard");
      copyToClipboard(props?.text ?? "");
    }
  };

  return (
    <div className="flex justify-end items-start gap-4">
      <Common.typography
        fontFamily="Sequel100Wide95"
        fontSize="14px"
        fontSizeSmall="14px"
        color={props?.isWinner ? "#DDE404" : "#ffffff"}
      >
        {truncatedTitle}
      </Common.typography>
      {props.copyable && (
        <Common.image
          src="/Images/copy-white.svg"
          alt="copy"
          width="24px"
          height="24px"
          heightSmall="24px"
          widthSmall="24px"
          objectFit="contain"
          objectPosition="center"
          onClick={handleCopy}
        />
      )}
    </div>
  );
}
