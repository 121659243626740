import TrustBoxContainer from "../../Page Components/TrustBoxContainer";

export default function TrustPilot({input} : {input?: string}) {
  return (
    <div className="flex flex-row gap-1 pt-[12px] items-center justify-center *:h-full *:w-full max-w-[200px] w-full min-w-0">
      <TrustBoxContainer input={input} isMobile={false} />
      {/* Excellent */}
      {/* <img src="/Images/Excellent.svg" alt="" /> */}
      {/* Stars */}
      {/* <img src="/Images/stars.svg" alt="" /> */}
      {/* Review Count */}
      {/* <img src="/Images/trustpilot_reviews.svg" alt="" /> */}
      {/* TrustPilot Logo */}
      {/* <img src="/Images/trustpilot_logo.svg" alt="" /> */}
    </div>
  );
}

// export default function TrustPilot() {
//   return (
//     <>
//       <div className="xl:hidden flex flex-col gap-4 items-center justify-center h-[100px] w-full mb-4">
//         <img
//           className="w-[422.57px]"
//           src="/Images/trustPilotSmall.svg"
//           alt=""
//         />
//       </div>
//       <div className="sm_md:hidden flex flex-col gap-4 items-center justify-center h-[100px] w-full">
//         <img className="w-[422.57px]" src="/Images/trustPilot.svg" alt="" />
//       </div>
//     </>
//   );
// }
