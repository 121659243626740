import axios from "axios";
import { Provider } from "react-redux";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.css";
import AuthProvider from "./Contexts/AuthContext";
import { WalletProvider } from "./Contexts/WalletContext";
import Routes from "./Routes/Routes";
import "./Styles/Dashboard.css";
import "./Styles/LandingPage.css";
import "./Styles/Slider.css";
import { store } from "./redux/store";
import { ErrorProvider } from "./Contexts/ErrorContext";
import { ModalProvider } from "./Components/Page Components/ClipboardModalContext";
import { MoonPayProvider } from "@moonpay/moonpay-react";
import { useEffect, useState } from "react";
import WebSocketComponent from "./Config/WebSocketComponent";

axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
const moonpay_api_key = `${process.env.REACT_APP_MOONPAY_API_KEY}`;
function App() {
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const images = document.images;
    let imagesLoadedCount = 0;

    if (images.length === 0) {
      setImagesLoaded(true);
      return;
    }

    const checkLoaded = () => {
      imagesLoadedCount++;
      if (imagesLoadedCount === images.length) {
        setImagesLoaded(true);
      }
    };

    for (let img of images) {
      if (img.complete) {
        checkLoaded();
      } else {
        img.addEventListener("load", checkLoaded);
        img.addEventListener("error", checkLoaded);
      }
    }

    return () => {
      for (let img of images) {
        img.removeEventListener("load", checkLoaded);
        img.removeEventListener("error", checkLoaded);
      }
    };
  }, []);

  if (!imagesLoaded) {
    return <></>;
  }
  return (
    <Provider store={store}>
      {/* <WebSocketComponent /> */}
      <ModalProvider>
        <ErrorProvider>
          <AuthProvider>
            <WalletProvider>
              <MoonPayProvider apiKey={moonpay_api_key} debug>
                <Routes />
              </MoonPayProvider>
            </WalletProvider>
          </AuthProvider>
        </ErrorProvider>
      </ModalProvider>
    </Provider>
  );
}

export default App;
