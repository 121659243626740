import React from "react";
import { Common } from "../Common";
import PopupWithIcon from "../PopupWithIcon/PopupWithIcon";

export const Loader = ({
  show = false,
  message = "",
  upperComponent,
  lowerComponent,
  showCloseButton,
  onClose,
}: {
  show?: boolean;
  message?: string;
  upperComponent?: React.ReactNode;
  lowerComponent?: React.ReactNode;
  showCloseButton?: boolean;
  onClose?: () => void;
}) => {
  return (
    show && (
      <div className="fixed top-0 left-0 w-screen h-screen z-[80] flex justify-center items-center flex-col">
        <PopupWithIcon
          showCloseButton={true}
          open={show}
          onClose={() => {
            if (onClose) {
              onClose();
            }
          }}
          children={
            <>
              {upperComponent && (
                <>
                  <Common.typography
                    fontFamily="Sequel100Wide95"
                    fontSize="20px"
                    fontSizeSmall="20px"
                    color="#fff"
                    className="uppercase text-center"
                  >
                    CONNECT A WALLET
                  </Common.typography>
                  <hr className="max-w-[478.99px] m-auto w-full mt-4 sm:mb-6 border-[1px] border-[#fff] " />
                </>
              )}

              <div className="w-full flex flex-col flex-1 gap-4 items-center justify-center sm:p-4 xl:py-16 lg:py-16 md:py-16">
                <div className="flex flex-col items-center mt-5">
                  {/* animate image it to spining */}
                  <img
                    className="animate-spin"
                    src={"/Images/Loader.svg"}
                    alt={""}
                  />
                </div>
                <Common.typography
                  fontFamily="Sequel100Wide95"
                  fontSize="22px"
                  fontSizeSmall="22px"
                  color="#fff"
                  className="uppercase text-center"
                >
                  WE’RE WORKING ON IT
                </Common.typography>
              </div>
            </>
          }
        ></PopupWithIcon>
        {/* {upperComponent && upperComponent}
        <CircularProgress
          style={{ color: "#DDE404" }}
          size={100}
          color="primary"
        />
        {message && (
          <p
            style={{
              color: "white",
              flexDirection: "column",
              fontWeight: "600",
              padding: "10px",
            }}
          >
            {" "}
            {message}
          </p>
        )}
        {lowerComponent && lowerComponent} */}
      </div>
      // <PopupWithIconNonClosing
      //   open={show}
      //   children={
      //     <>
      //       <Common.typography
      //         fontFamily="Sequel100Wide95"
      //         fontSize="20px"
      //         fontSizeSmall="20px"
      //         color="#fff"
      //         className="uppercase text-center"
      //       >
      //         CONNECT WALLET
      //       </Common.typography>
      //       <hr className="max-w-[478.99px] m-auto w-full mt-4 mb-6 border-[1px] border-[#fff] " />
      //       <div className="w-full flex flex-col flex-1 gap-4 items-center justify-center">
      //         <div className="flex flex-col items-center mt-5">
      //           {/* animate image it to spining */}
      //           <img
      //             className="animate-spin"
      //             src={"/Images/Loader.svg"}
      //             alt={""}
      //           />
      //         </div>
      //         <Common.typography
      //           fontFamily="Sequel100Wide95"
      //           fontSize="22px"
      //           fontSizeSmall="22px"
      //           color="#fff"
      //           className="uppercase text-center"
      //         >
      //           WE’RE WORKING ON IT
      //         </Common.typography>
      //       </div>
      //     </>
      //   }
      // ></PopupWithIconNonClosing>
    )
  );
};
