export default function Promo({
  affiliatecompetition,
}: {
  affiliatecompetition: number | undefined;
}) {
  return (
    <>
      {Boolean(affiliatecompetition) && (
        <div
          style={{ backgroundColor: "#EF008F" }}
          className="grid grid-cols-3 gap-16 grid-rows-1 w-[calc(100%-29.1px)] place-content-center place-items-center m-auto rounded-t-[26.75px] h-[40.68px]"
        >
          {/* <img
            src="/Images/gift.svg"
            alt=""
            className="xl_lg:h-[25.42px] md:h-[22.42px] sm:h-[20.42px]"
          /> */}
          <div></div>

          <p className="font-[Sequel100Wide95] text-nowrap text-white uppercase text-[20.07] sm:text-[clamp(0px,14px,15.72px)]">
            Promo Comp
          </p>

          {/* <img
            src="/Images/gift.svg"
            alt=""
            className="xl_lg:h-[25.42px] md:h-[22.42px] sm:h-[35%]"
          /> */}
          <div></div>
        </div>
      )}
    </>
  );
}
