import Button from "../../Button";
import { Common } from "../../common/Common";
import PrizeCard from "../../InstantWinCompetitionPage/PrizeCard/PrizeCard";
import PrizeCardsContainer from "../../InstantWinCompetitionPage/PrizeCardsContainer/PrizeCardsContainer";

export interface PopupPrizeData {
  uid: string;
  url: string;
  prize: string;
  description: string;
  competitionId: string;
  winningTicket: number;
  winningWalletAddress: string;
  priority: number;
}
export default function PopupPrizes({
  data,
  onClose,
}: {
  data: PopupPrizeData[];
  onClose: () => void;
}) {

  const sortedData = data.sort((a, b) => a.priority - b.priority); 

  return (
    <div className="border-2 border-primary p-4 py-10 justify-center items-center grid grid-cols-1 //grid-rows-3 w-[80%] h-fit max-h-[80%] overflow-auto rounded-[30px] fixed bg-black z-[99999999999999] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
      <div>
        <Common.typography
          fontFamily="Sequel100Wide95"
          fontSize="24px"
          fontSizeSmall="18px"
          color="#FFFFFF"
          className="text-center"
        >
          Congratulations! You have won the following prizes:
        </Common.typography>
        <Common.typography
          fontFamily="Sequel100Wide-65"
          fontSize="14px"
          fontSizeSmall="12px"
          color="#FFFFFF"
          className="text-center mt-4"
        >
          Our team will reach out to you shortly.
        </Common.typography>
      </div>

      <div className="grid grid-cols-2 mt-12 h-full sm_md_lg:grid-cols-1 max-1390:grid-cols-1 gap-4 p-4 //max-h-[48rem]   max-530:*:flex-col max-530:*:h-[250px] max-530:*:p-2 ">
        {sortedData?.map((prize, index) => (
          <PrizeCard
            description={prize.description}
            title={prize.prize}
            image={prize?.url}
            key={index}
            date={new Date("2022-09-09T00:00:00.000Z").toISOString()}
            winningTicket={prize?.winningTicket.toString()}
            winner={prize?.winningWalletAddress}
          />
        ))}
      </div>

      <div className="//mt-8 items-center justify-center h-fit flex gap-2 sm:flex">
        <Button
          onClick={onClose}
          className={`mt-4 text-[#ffffff] !bg-black font-[Sequel100Wide95] text-[24px]
              sm:text-[3vw]
              sm:w-[80%]
              uppercase text-center`}
          content={"Close"}
        />
      </div>
    </div>
  );
}
