import React, { useEffect } from "react";
import { useRef, useState } from "react";
import AdvertBox from "../Components/Page Components/AdvertBox";
import CompetitionDetails from "../Components/Page Components/CompetitionDetails";
import {
  CompetitionProps,
  Competitions,
} from "../Components/Page Components/Competitions";
import FAQ from "../Components/Page Components/FAQ";
import HowToPlay from "../Components/Page Components/HowToPlay";
import WithNavbarAndFooter from "../Layout/WithNavbarAndFooter";
import { useAppSelector } from "../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { CompetitionObject } from "../redux/reducers/competitionsSlice";
import { scrollTo } from "../utils/ScrollTo";
import { getUserListCompetition } from "../Services/userService";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import Button from "../Components/Button";
import PhoneStores from "../Components/Page Components/PhoneStores";
import Carousel from "../Components/common/Carousel/Carousel";
import CompetitionCard from "../Components/Page Components/CompetitionCard/CompetitionCard";
import { Loader } from "../Components/common/loader/Loader";
import LiveCompetitionFAQDivNew from "../Components/Page Components/LiveCompetitionFAQDivNew";
import AdvertBoxNew from "../Components/Page Components/AdvertBoxNew";
import PhoneStoresNew from "../Components/Page Components/PhoneStoresNew";

const CompetitionPage = (props: any) => {
  const connectWalletFunction = props?.connectWalletFunction;
  const [loadedLiveCompetition, setLoadedLiveCompetition] = useState(false);
  const [loadedInstantCompetition, setLoadedInstantCompetition] =
    useState(false);
  const [loadedLastChanceCompetition, setLoadedLastChanceCompetition] =
    useState(false);
  const [loadedDrawnCompetition, setLoadedDrawnCompetition] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    if (
      loadedLiveCompetition &&
      loadedInstantCompetition &&
      loadedLastChanceCompetition &&
      loadedDrawnCompetition
    ) {
      setShowLoader(false);
    }
  }, [
    loadedLiveCompetition,
    loadedInstantCompetition,
    loadedLastChanceCompetition,
    loadedDrawnCompetition,
  ]);
  const params = useParams();
  const [showCompetition, setShowCompetition] = useState(true);
  const [competition, setCompetition] = useState();
  const [currentFilter, setCurrentFilter] = useState("Live Competitions");
  // const itemsPerPage = 12;
  const [itemsPerPage, setItemsPerPage] = useState(12);
  // const competitions = useAppSelector((state) => state.competitions.data);
  const [loader, setLoader] = useState<boolean>(false);
  const [competitionsList, setCompetitionsList] = useState<CompetitionProps[]>(
    []
  );
  const [allCompetitionsLoaded, setAllCompetitionsLoaded] = useState(false);
  const [displayedAllCompetitions, setDisplayedAllCompetitions] = useState<
    CompetitionProps[]
  >([]);

  const [lastChanceCompetitions, setLastChanceCompetitions] = useState<
    CompetitionProps[]
  >([]);

  const [drawnCompetition, setDrawncompetitions] = useState<CompetitionProps[]>(
    []
  );

  const [instantWinCompetitions, setInstantWinCompetitions] = useState<
    CompetitionProps[]
  >([]);
  const [liveCompetitions, setLiveCompetitions] = useState<CompetitionProps[]>(
    []
  );

  const [liveCompetitionPage, setLiveCompetitionPage] = useState<number>(1);
  const [lastChanceCompetitionPage, setLastChanceCompetitionPage] =
    useState<number>(1);
  const [drawnCompetitionPage, setDrawnCompetitionPage] = useState<number>(1);
  const [instantWinCompetitionPage, setInstantWinCompetitionPage] =
    useState<number>(1);

  const navigate = useNavigate();
  const liveComp = useRef<HTMLDivElement>(null);
  const lastChance = useRef<HTMLDivElement>(null);
  const drawnComp = useRef<HTMLDivElement>(null);
  const instantWinComp = useRef<HTMLDivElement>(null);

  // const [compPages, setCompPages] = useState<number>(0);

  const [liveCompPages, setliveCompPages] = useState<number>(0);
  const [instantCompPages, setinstantCompPages] = useState<number>(0);
  const [lastChancecompPages, setlastChancecompPages] = useState<number>(0);
  const [drawnCompPages, setdrawnCompPages] = useState<number>(0);

  console.log("params", params);

  useEffect(() => {
    if (window.screen.width < 640) {
      setItemsPerPage(999);
    } else {
      setItemsPerPage(12);
    }
  }, [window.screen.width]);

  // useEffect(() => {
  //   getCompetitions();
  // }, [currentFilter]);

  const filterThePrizeCompetitions = (competitions: CompetitionProps[]) => {
    const filteredCompetitions = competitions?.filter((competition: any) => {
      if (
        competition?.category === "BITCOIN" ||
        competition?.category === "bitcoin" ||
        competition?.category === "WEN LAMBO" ||
        competition?.category === "WATCHES" ||
        competition?.category === "HIGH ROLLERS" ||
        competition?.category === "NFTS" ||
        competition?.category === "ALTCOINS" ||
        competition?.category === "INSTANT WINS" ||
        competition?.category === "CARS & WATCHES"
      ) {
        return competition;
      }
    });
    return filteredCompetitions;
  };

  const sortCompteitions = (competitions: CompetitionProps[]) => {
    const sortedCompetitionList = competitions?.sort(
      (a: CompetitionProps, b: CompetitionProps) => {
        let dateA = new Date(a?.competitionenddate || 0) as any;
        let dateB = new Date(b?.competitionenddate || 0) as any;
        return dateA - dateB;
      }
    );
    return sortedCompetitionList;
  };

  // const getCompetitions = async () => {
  //   try {
  //     setAllCompetitionsLoaded(false);
  //     setLoader(true);
  //     const liveCompetitionsBody = {
  //       category: "",
  //       page: liveCompetitionPage?.toString(),
  //       filter: "0",
  //     };
  //     const instantWinCompetitionsBody = {
  //       category: "",
  //       page: instantWinCompetitionPage?.toString(),
  //       filter: "1",
  //     };
  //     const lastChanceCompetitionsBody = {
  //       category: "",
  //       page: lastChanceCompetitionPage?.toString(),
  //       filter: "2",
  //     };
  //     const drawnCompetitionsBody = {
  //       category: "",
  //       page: drawnCompetitionPage?.toString(),
  //       filter: "3",
  //     };
  //     const liveCompetitionsResponse = await getUserListCompetition(
  //       liveCompetitionsBody
  //     );
  //     const instantWinCompetitionsResponse = await getUserListCompetition(
  //       instantWinCompetitionsBody
  //     );
  //     const lastChanceCompetitionsResponse = await getUserListCompetition(
  //       lastChanceCompetitionsBody
  //     );
  //     const drawnCompetitionsResponse = await getUserListCompetition(
  //       drawnCompetitionsBody
  //     );
  //     const liveCompetitions = filterThePrizeCompetitions(
  //       sortCompteitions(liveCompetitionsResponse?.data)
  //     );
  //     const instantWinCompetitions = filterThePrizeCompetitions(
  //       sortCompteitions(instantWinCompetitionsResponse?.data)
  //     );
  //     const lastChanceCompetitions = filterThePrizeCompetitions(
  //       sortCompteitions(lastChanceCompetitionsResponse?.data)
  //     );
  //     const drawnCompetitions = filterThePrizeCompetitions(
  //       sortCompteitions(drawnCompetitionsResponse?.data)
  //     );
  //     setLiveCompetitions(liveCompetitions);
  //     setInstantWinCompetitions(instantWinCompetitions);
  //     setLastChanceCompetitions(lastChanceCompetitions);
  //     setDrawncompetitions(drawnCompetitions);
  //     setLoader(false);
  //   } catch (error) {
  //     console.log("error", error);
  //     setLoader(false);
  //   }
  // };

  const getLiveCompetitions = async () => {
    try {
      setLoader(true);
      const liveCompetitionsBody = {
        category: "",
        page: liveCompetitionPage?.toString(),
        filter: "0",
      };
      const liveCompetitionsResponse = await getUserListCompetition(
        liveCompetitionsBody
      );

      setliveCompPages(liveCompetitionsResponse?.totalPages);

      const fetchedLiveCompetitions = filterThePrizeCompetitions(
        sortCompteitions(liveCompetitionsResponse?.data)
      );
      console.log("fetchedLiveCompetitions", fetchedLiveCompetitions);
      setLiveCompetitions(
        liveCompetitions?.length > 0
          ? [...liveCompetitions, ...fetchedLiveCompetitions]
          : fetchedLiveCompetitions
      );
      setLoader(false);
      setLoadedLiveCompetition(true);
    } catch (error) {
      console.log("error", error);
      setLoadedLiveCompetition(true);
    }
  };

  const getInstantWinCompetitions = async () => {
    try {
      setLoader(true);
      const instantWinCompetitionsBody = {
        category: "",
        page: instantWinCompetitionPage?.toString(),
        filter: "1",
      };
      const instantWinCompetitionsResponse = await getUserListCompetition(
        instantWinCompetitionsBody
      );

      setinstantCompPages(instantWinCompetitionsResponse?.totalPages);

      const fetchedInstantWinCompetitions = filterThePrizeCompetitions(
        sortCompteitions(instantWinCompetitionsResponse?.data)
      );
      setInstantWinCompetitions(
        instantWinCompetitions?.length > 0
          ? [...instantWinCompetitions, ...fetchedInstantWinCompetitions]
          : fetchedInstantWinCompetitions
      );
      setLoader(false);
      setLoadedInstantCompetition(true);
    } catch (error) {
      console.log("error", error);
      setLoadedInstantCompetition(true);
    }
  };

  const getLastChanceCompetitions = async () => {
    try {
      setLoader(true);
      const lastChanceCompetitionsBody = {
        category: "",
        page: lastChanceCompetitionPage?.toString(),
        filter: "2",
      };
      const lastChanceCompetitionsResponse = await getUserListCompetition(
        lastChanceCompetitionsBody
      );

      setlastChancecompPages(lastChanceCompetitionsResponse?.totalPages);

      const fetchedlastChanceCompetitions = filterThePrizeCompetitions(
        sortCompteitions(lastChanceCompetitionsResponse?.data)
      );

      console.log(
        "fetchedlastChanceCompetitions",
        fetchedlastChanceCompetitions
      );
      setLastChanceCompetitions(
        lastChanceCompetitions?.length > 0
          ? [...lastChanceCompetitions, ...fetchedlastChanceCompetitions]
          : fetchedlastChanceCompetitions
      );
      setLoader(false);
      setLoadedLastChanceCompetition(true);
    } catch (error) {
      console.log("error", error);
      setLoadedLastChanceCompetition(true);
    }
  };

  const getDrawnCompetitions = async () => {
    try {
      setLoader(true);
      const drawnCompetitionsBody = {
        category: "",
        page: drawnCompetitionPage?.toString(),
        filter: "3",
      };
      const drawnCompetitionsResponse = await getUserListCompetition(
        drawnCompetitionsBody
      );

      setdrawnCompPages(drawnCompetitionsResponse?.totalPages);

      const fetcheddrawnCompetitions = filterThePrizeCompetitions(
        drawnCompetitionsResponse?.data
      );
      setDrawncompetitions(
        drawnCompetition?.length > 0
          ? [...drawnCompetition, ...fetcheddrawnCompetitions]
          : fetcheddrawnCompetitions
      );
      setLoader(false);
      setLoadedDrawnCompetition(true);
    } catch (error) {
      console.log("error", error);
      setLoadedDrawnCompetition(true);
    }
  };

  useEffect(() => {
    getLiveCompetitions();
  }, [liveCompetitionPage]);

  useEffect(() => {
    getInstantWinCompetitions();
  }, [instantWinCompetitionPage]);

  useEffect(() => {
    getLastChanceCompetitions();
  }, [lastChanceCompetitionPage]);

  useEffect(() => {
    getDrawnCompetitions();
  }, [drawnCompetitionPage]);

  const fetchMoreCompetitions = async () => {
    if (allCompetitionsLoaded) return;
    const nextDisplayedItems = competitionsList.slice(
      0,
      displayedAllCompetitions?.length + itemsPerPage
    );
    setDisplayedAllCompetitions(nextDisplayedItems); // Update displayed items
    console.log("dsajda", nextDisplayedItems?.length, competitionsList?.length);
    setAllCompetitionsLoaded(
      nextDisplayedItems?.length === competitionsList?.length
    );
  };

  const handleLoadMoreClick = () => {
    fetchMoreCompetitions();
  };

  const scrollToTarget = (filter: string) => {
    // for desktop
    const navbarId = window.screen.width > 1024 ? "navbar" : "navbar-mobile";
    if (filter === "Last Chance Competitions") {
      if (lastChance.current) {
        scrollTo(lastChance, navbarId);
      }
    }
    if (filter === "Live Competitions") {
      if (liveComp.current) {
        scrollTo(liveComp, navbarId);
      }
    }
    if (filter === "Instant Win Competitions") {
      if (instantWinComp.current) {
        scrollTo(instantWinComp, navbarId);
      }
    }
    if (filter === "Drawn Competitions") {
      if (drawnComp.current) {
        scrollTo(drawnComp, navbarId);
      }
    }
  };

  useEffect(() => {
    if (params.all === "all") {
      setShowCompetition(false);
    } else {
      setShowCompetition(true);
    }
  }, [params]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function changeFilter(filter: string) {
    setCurrentFilter(filter);
  }

  function buyTicket() {}

  const filters = [
    {
      content: "Live Competitions",
    },
    {
      content: "Instant Win Competitions",
    },
    {
      content: "Last Chance Competitions",
    },
    {
      content: "Drawn Competitions",
    },
  ];

  const finishedCompetition = (competitionenddate?: Date) => {
    const currentDate = new Date();
    const competitionEndDate = new Date(competitionenddate ?? new Date());
    if (currentDate > competitionEndDate) {
      return true;
    } else {
      return false;
    }
  };

  function loadCompetition(competition?: any, UID?: string) {
    navigate(`/competition/${UID}`);
    setCompetition(competition);
  }

  const navigateToResult = (competition?: any, UID?: string) => {
    navigate(`/competitionResult/${UID}`);
  };

  const handleLoadMoreLiveCompetitions = () => {
    setLiveCompetitionPage(liveCompetitionPage + 1);
  };

  const handleLoadMoreLastChanceCompetitions = () => {
    setLastChanceCompetitionPage(lastChanceCompetitionPage + 1);
  };

  const handleLoadMoreDrawnCompetitions = () => {
    setDrawnCompetitionPage(drawnCompetitionPage + 1);
  };

  const handleLoadMoreInstantWinCompetitions = () => {
    setInstantWinCompetitionPage(instantWinCompetitionPage + 1);
  };

  return (
    <div className="hero ">
      <Loader show={showLoader} />
      {showCompetition ? (
        // competition details
        <div>
          <CompetitionDetails competition={competition} />

          <HowToPlay />

          <FAQ className={"faq"} fontSize={"text-[60px] sm:text-[28px]"} />
        </div>
      ) : (
        <div className="w-full flex flex-col items-center ">
          {/* list of competitions */}
          {/* Live competition */}
          <div className="flex flex-col items-center liveCompetition //bg-bg_small p-[28px] xl:p-10 pt-0 w-full">
            <div
              className={
                "grid grid-cols-4 gap-4 mb-0 gap-y-4 w-full sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 p-10 pb-0 sm:p-0 md:p-0 mt-3 //mb-[3em] sm_md_lg:mt-[30px] //bg-bg_small "
              }
            >
              {filters.map((filter, index) => (
                <button
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    changeFilter(filter.content);
                    scrollToTarget(filter.content);
                  }}
                  className={`${
                    filter.content === currentFilter
                      ? "bg-primary font-extrabold text-black border-2 border-white font-[Sequel100Wide95] "
                      : "bg-none text-white bg-[#4B4B4B] //bg-general_bg font-[Sequel100WideVF45]"
                  }
                  px-4
                  xl:h-[85.02px] h-max rounded-full   w-full //xl:px-16 sm_md:px-4 sm_md_lg:h-[46.15px] //p-3 sm:w-full md:w-full  transition-all sm:text-[4vw] md:text-[2vw] xl:text-[1rem] text-center uppercase mx-auto `}
                >
                  <p
                    className="text-center  sm:w-full md:w-full mx-auto lg:w-[80%]"
                    style={{
                      fontSize: "clamp(0px,3.496512479vw,20px)",
                    }}
                  >
                    {filter.content}
                  </p>
                </button>
              ))}
            </div>

            <h1
              className="pageHeading mb-0 font-[Sequel100Wide95] text-center"
              ref={liveComp}
              style={{
                fontSize: "clamp(1px,5.619128597vw,45px)",
              }}
            >
              LIVE COMPETITIONS
            </h1>
            {loader && (
              <div className="flex justify-center items-center w-full h-[200px]">
                <CircularProgress />
              </div>
            )}

            {!loader && liveCompetitions?.length === 0 && (
              <div className="w-full mt-[5em]">
                <div
                  className={`uppercase text-[2rem] font-[Sequel100WideVF55] sm:text-[20px] flex flex-col h-[10rem]  relative  w-full sm:w-full rounded-2xl text-center justify-center text-primary`}
                >
                  COMPETITIONS LAUNCHING SOON!
                </div>
              </div>
            )}
            <div
              // className="grid 1366:w-max 1366:scale-[0.97] grid-cols-3 xl:grid-cols-4 gap-8 gap-y-10 sm:grid-cols-2 md:grid-cols-2 sm:gap-4 w-full above-1367:w-[94%]  "
              className="xl:grid lg:grid max-1331:grid-cols-2 md:grid-cols-2 between-641-800:grid-cols-1 grid-cols-3 sm:grid-cols-1 lg:grid-cols-2 md:grid hidden max-911:hidden sm:min-w-[100%]  between-200-299:min-w-[100%]   xl:gap-x-[27.59px] xl:gap-y-[53px] gap-x-[13.63px] gap-y-[11.92px] m-auto w-max max-w-[100%] *:m-auto mt-[3em]"
            >
              {!loader &&
                liveCompetitions?.map((competition, index) => (
                  <CompetitionCard
                    {...competition}
                    isHighRoller={competition?.categroy === "HIGH ROLLERS"}
                    onClick={() => loadCompetition(competition?.UID)}
                    key={index}
                    width={"w-[244px]"}
                  />
                ))}
            </div>
            <div className="xl:hidden lg:hidden md:hidden max-911:block block mt-[40.37px]">
              <Carousel handleOnLastSlide={handleLoadMoreLiveCompetitions}>
                {liveCompetitions?.map((competition, index) => (
                  <CompetitionCard
                    {...competition}
                    isHighRoller={competition?.category === "HIGH ROLLERS"}
                    onClick={() => loadCompetition(competition?.UID)}
                    key={index}
                    width={"w-[244px]"}
                  />
                ))}
              </Carousel>
            </div>
            <div className="mb-2  xs_sm:hidden">
              {/* {liveCompPages > 1 ? ( */}
              {liveCompPages !== liveCompetitionPage ? (
                <Button
                  content="Load More"
                  onClick={handleLoadMoreLiveCompetitions}
                  className="text-black font-[Sequel100Wide95] text-[24px]
            sm:text-[3vw]
            sm:w-[80%]
            uppercase text-center px-[20px] py-[10px] mt-[23px] rounded-[30px]"
                />
              ) : null}
            </div>
          </div>
          {/* Instant Win Competition */}
          <div className="flex   flex-col items-center //mt-[3em] w-full ">
            <h1
              className="flex uppercase text-[40px] //font-[Sequel100WideVF] font-[Sequel100Wide95] items-center font-extrabold text-black  bg-white h-[180px] sm:text-[26px] justify-center md:text-[28px] w-full  pt-[-1em]  sm:pt-0 sm:text-center text-center"
              ref={instantWinComp}
              style={{
                fontSize: "clamp(1px,5.81vw,40px)",
              }}
            >
              Instant Win Competitions
            </h1>
            {instantWinCompetitions?.length === 0 && (
              <div className="w-full //font-[Sequel100WideVF55] font-[Sequel100Wide95] mt-[5em]">
                <div
                  className={` text-[2rem] uppercase font-[] sm:text-[20px] flex flex-col h-[10rem] relative  w-full sm:w-full rounded-2xl text-center justify-center text-primary`}
                >
                  COMPETITIONS LAUNCHING SOON!
                </div>
              </div>
            )}
            {/* <div className="grid 1366:w-max 1366:scale-[0.97] grid-cols-3 xl:grid-cols-4 gap-8 gap-y-10 w-full sm:grid-cols-2 md:grid-cols-2 sm:gap-4 py-10 p-[18px] above-1367:w-[94%]"> */}
            <div className="xl:grid lg:grid max-1331:grid-cols-2 md:grid-cols-2 between-641-800:grid-cols-1 grid-cols-3 sm:grid-cols-1 lg:grid-cols-2 md:grid hidden max-911:hidden sm:min-w-[100%]  between-200-299:min-w-[100%]   xl:gap-x-[27.59px] xl:gap-y-[53px] gap-x-[13.63px] gap-y-[11.92px] m-auto w-max max-w-[100%] *:m-auto mt-[3em] mb-[3em]">
              {!loader &&
                instantWinCompetitions?.map((competition, index) => (
                  <CompetitionCard
                    {...competition}
                    onClick={() => loadCompetition(competition?.UID)}
                    key={index}
                    width={"w-[244px]"}
                  />
                ))}
            </div>
            <div className="xl:hidden lg:hidden md:hidden max-911:block block mt-[40.37px]">
              <Carousel
                handleOnLastSlide={handleLoadMoreInstantWinCompetitions}
              >
                {instantWinCompetitions?.map((competition, index) => (
                  <CompetitionCard
                    {...competition}
                    onClick={() => loadCompetition(competition?.UID)}
                    key={index}
                    width={"w-[244px]"}
                  />
                ))}
              </Carousel>
            </div>
            <div className="mb-2  xs_sm:hidden">
              {/* {instantCompPages > 1 ? ( */}
              {instantCompPages !== instantWinCompetitionPage ? (
                <Button
                  content="Load More"
                  onClick={handleLoadMoreInstantWinCompetitions}
                  className="text-black font-[Sequel100Wide95] text-[24px]
            sm:text-[3vw]
            sm:w-[80%]
            uppercase text-center px-[20px] py-[10px] mt-[23px] rounded-[30px]"
                />
              ) : null}
            </div>
          </div>

          {/* Last Chance Competition */}
          <div className="flex   flex-col items-center //mt-[3em] w-full lastChance">
            <h1
              className="flex text-[40px] //font-[Sequel100WideVF] font-[Sequel100Wide95] items-center font-extrabold text-black  bg-primary h-[180px] sm:text-[26px] justify-center md:text-[28px] w-full  pt-[-1em]  sm:pt-0 sm:text-center text-center"
              ref={lastChance}
              style={{
                fontSize: "clamp(1px,5.81vw,40px)",
              }}
            >
              LAST CHANCE COMPETITIONS
            </h1>
            {lastChanceCompetitions?.length === 0 && (
              <div className="w-full //font-[Sequel100WideVF55] font-[Sequel100Wide95] mt-[5em]">
                <div
                  className={` uppercase text-[2rem] font-[] sm:text-[20px] flex flex-col h-[10rem] relative  w-full sm:w-full rounded-2xl text-center justify-center text-primary`}
                >
                  COMPETITIONS LAUNCHING SOON!
                </div>
              </div>
            )}
            {/* <div className="grid 1366:w-max 1366:scale-[0.97] grid-cols-3 xl:grid-cols-4 gap-8 gap-y-10 w-full sm:grid-cols-2 md:grid-cols-2 sm:gap-4 py-10 p-[18px] above-1367:w-[94%]"> */}
            <div className="xl:grid lg:grid max-1331:grid-cols-2 md:grid-cols-2 between-641-800:grid-cols-1 grid-cols-3 sm:grid-cols-1 lg:grid-cols-2 md:grid hidden max-911:hidden sm:min-w-[100%]  between-200-299:min-w-[100%]   xl:gap-x-[27.59px] xl:gap-y-[53px] gap-x-[13.63px] gap-y-[11.92px] m-auto w-max max-w-[100%] *:m-auto mt-[3em] mb-[3em]">
              {!loader &&
                lastChanceCompetitions?.map((competition, index) => (
                  <CompetitionCard
                    {...competition}
                    onClick={() => loadCompetition(competition?.UID)}
                    key={index}
                    width={"w-[244px]"}
                  />
                ))}
            </div>
            <div className="xl:hidden lg:hidden md:hidden max-911:block block mt-[40.37px]">
              <Carousel
                handleOnLastSlide={handleLoadMoreLastChanceCompetitions}
              >
                {lastChanceCompetitions?.map((competition, index) => (
                  <CompetitionCard
                    {...competition}
                    onClick={() => loadCompetition(competition?.UID)}
                    key={index}
                    width={"w-[244px]"}
                  />
                ))}
              </Carousel>
            </div>
            <div className="mb-2  xs_sm:hidden">
              {/* {lastChancecompPages > 1 ? ( */}
              {lastChancecompPages !== lastChanceCompetitionPage ? (
                <Button
                  content="Load More"
                  onClick={handleLoadMoreLastChanceCompetitions}
                  className="text-black font-[Sequel100Wide95] text-[24px]
            sm:text-[3vw]
            sm:w-[80%]
            uppercase text-center px-[20px] py-[10px] mt-[23px] rounded-[30px]"
                />
              ) : null}
            </div>
          </div>

          <div className="flex  flex-col items-center w-full drawnCompetition bg-bg_small ">
            <h1
              className="flex text-[40px] //font-[Sequel100WideVF] font-[Sequel100Wide95] items-center font-extrabold text-white //text-black  bg-secondary h-[180px] sm:text-[26px] justify-center md:text-[28px] w-full  pt-[-1em]  sm:pt-0 sm:text-center text-center"
              ref={drawnComp}
              style={{
                fontSize: "clamp(1px,5.81vw,40px)",
              }}
            >
              DRAWN COMPETITIONS
            </h1>
            {drawnCompetition?.length === 0 && (
              <div className="w-full //font-[Sequel100WideVF55] font-[Sequel100Wide95] mt-[5em]">
                <div
                  className={`uppercase text-[2rem] font-[] sm:text-[20px] flex flex-col h-[10rem] relative  w-full sm:w-full rounded-2xl text-center justify-center text-primary`}
                >
                  COMPETITIONS LAUNCHING SOON!
                </div>
              </div>
            )}
            {/* <div className="grid grid-cols-3 1366:w-max 1366:scale-[0.97] xl:grid-cols-4 gap-8 gap-y-10 w-full p-[18px] pt-10 sm:grid-cols-2 md:grid-cols-2 sm:gap-7 above-1367:w-[94%]"> */}
            <div className="xl:grid lg:grid max-1331:grid-cols-2 md:grid-cols-2 between-641-800:grid-cols-1 grid-cols-3 sm:grid-cols-1 lg:grid-cols-2 md:grid hidden max-911:hidden sm:min-w-[100%]  between-200-299:min-w-[100%]   xl:gap-x-[27.59px] xl:gap-y-[53px] gap-x-[13.63px] gap-y-[11.92px] m-auto w-max max-w-[100%] *:m-auto mt-[3em] mb-[3em]">
              {!loader &&
                drawnCompetition?.map((competition, index) => (
                  <CompetitionCard
                    isCompetitionFinished={true}
                    {...competition}
                    onClick={() => navigateToResult(competition?.UID)}
                    key={index}
                    width={"w-[244px]"}
                  />
                ))}
            </div>
            <div className="xl:hidden lg:hidden md:hidden max-911:block block mt-[40.37px]">
              <Carousel handleOnLastSlide={handleLoadMoreDrawnCompetitions}>
                {drawnCompetition?.map((competition, index) => (
                  <CompetitionCard
                    isCompetitionFinished={true}
                    {...competition}
                    onClick={() => navigateToResult(competition?.UID)}
                    key={index}
                    width={"w-[244px]"}
                  />
                ))}
              </Carousel>
            </div>
            <div className="mb-2  xs_sm:hidden">
              {/* {drawnCompPages > 1 || drawnCompetitionPage !== drawnCompPages ? ( */}
              {drawnCompPages !== drawnCompetitionPage ? (
                <Button
                  content="Load More"
                  // onClick={handleLoadMoreLiveCompetitions}
                  onClick={handleLoadMoreDrawnCompetitions}
                  className="text-black font-[Sequel100Wide95] text-[24px]
            sm:text-[3vw]
            sm:w-[80%]
            uppercase text-center px-[20px] py-[10px] mt-[23px] rounded-[30px]"
                />
              ) : null}
            </div>
          </div>
        </div>
      )}

      <div
        id="faqDiv"
        className="bg-gradient-to-br from-[#a51269] to-[#2b2b2b] via-[#2b2b2b] bg-[length:100%_100%] //py-12"
      >
        {/* <LiveCompetitionFAQDivNew
          className={"faq"}
          fontSize={"text-[60px] sm:text-[28px]"}
          isInstant={true}
        /> */}
        <div className="flex flex-row justify-center py-12">
          <AdvertBoxNew
            connectWalletFunction={connectWalletFunction}
            instant={true}
          />
        </div>
        <PhoneStoresNew
          instant={true}
          background="bg-gradient-to-r from-[#a51269] to-[#2b2b2b] h-screen "
        />
      </div>
    </div>
  );
};

export default WithNavbarAndFooter(CompetitionPage);
