import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import React, { useEffect } from "react";
import {
  IPrize,
  IPrizePerPageData,
} from "../../../Pages/InstantWinCompetitionPage";
import { Common } from "../../common/Common";
import PrizeCardNew from "../PrizeCard/PrizeCardNew";
import { getInstantWinPrizes } from "../../../Services/userService";
import VSpacer from "../../common/Spacer/VSpacer";
interface PrizeCardContainerProps {
  data?: Array<any>;
  getInstantPrizes?: (prize: string) => void;
  prizes: IPrize[];
  competitionid?: string;
}

function PrizeCardsContainer(props: PrizeCardContainerProps) {
  const [uncollapsedIndex, setUncollapsedIndex] = React.useState(-1);
  const competitionid = props.competitionid;
  const [totalItems, setTotalItems] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(-1);
  const [prizes, setPrizes] = React.useState<IPrize[]>([]);
  const [prizesPage, setPrizesPage] = React.useState(1);
  const [prizeLoading, setPrizeLoading] = React.useState(false);
  const [emptyEncounter, setEmptyEncounter] = React.useState(false);
  const [from, setFrom] = React.useState(20);

  const getInstantPrizes = async (prizeName?: string, pageNo?: number) => {
    try {
      if (competitionid) {
        if (totalPages === -1 || prizesPage <= totalPages) {
          setPrizeLoading(true);
          const result: IPrizePerPageData = await getInstantWinPrizes({
            competitionid,
            prize: prizeName,
            page: pageNo ?? prizesPage,
            limit: 20,
          })
            ?.then((res) => {
              if (res.data.length === 0) {
                setEmptyEncounter(true);
              }
              setPrizes(res.data);
              setTotalPages(res.totalPages);
              setTotalItems(res.totalItems);
              setPrizeLoading(false);
              return res;
            })
            ?.catch((error) => {
              console.log(error);
              setPrizeLoading(false);
            })
            ?.finally(() => {
              setPrizeLoading(false);
            });
        }
        if (pageNo) {
          setPrizesPage(pageNo);
        } else {
          setPrizesPage(prizesPage + 1);
        }
      } else {
        throw new Error("Competition ID is undefined");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCollapse = (index: number) => {
    if (uncollapsedIndex === index) {
      setUncollapsedIndex(-1);
    } else {
      setPrizes([]);
      setPrizesPage(1);
      setTotalItems(0);
      setTotalPages(-1);
      setUncollapsedIndex(index);
      // getInstantPrizes && getInstantPrizes(props?.data?.[index]?.prize);
    }
  };

  useEffect(() => {
    if (uncollapsedIndex == -1) {
      return;
    }
    getInstantPrizes &&
      getInstantPrizes(props?.data?.[uncollapsedIndex]?.prize, prizesPage);
  }, [uncollapsedIndex]);

  // useEffect(() => {
  //   console.log(
  //     "priority",
  //     props?.data?.sort((a, b) => a.priority - b.priority)
  //   );
  // }, [props?.data]);

  return (
    <div className="bg-[#1E1E1E] pt-6 flex flex-col justify-center items-center w-full text-center">
      <h1
        className={`pageHeading2 font-[Sequel100Wide95]`}
        style={{
          fontSize: "clamp(1px, 6.527976190476191vw, 45px)",
        }}
      >
        PRIZES
      </h1>
      <div
        className="gap-4 flex flex-col pr-[36px] sm_md:pr-2 sm_md:mt-[16.58px] max-h-[48rem] scrollbar-none overflow-auto m-6 sm_md:m-3"
        style={{ paddingRight: "0px", overscrollBehavior: "none" }}
      >
        {props?.data
          ?.sort((a, b) => a.priority - b.priority)
          ?.map((prize, index) => (
            <div className="border-primary border-2 rounded-2xl p-4 w-[936px] sm_md:w-[366px]">
              <div className="flex flex-row items-center gap-4 justify-between">
                <div className="flex flex-row items-center gap-4">
                  <img
                    src={prize.url}
                    alt=""
                    className="w-[138px] h-[89px] bg-white rounded-[10px] xs_sm:w-[101.06px] xs_sm:h-[65.18px]"
                  />
                  <div className="flex flex-col gap-2">
                    <p className="text-[#fff] text-[25px] xs_sm:text-[15px] font-['Sequel100Wide95'] leading-[29px] font-[218] text-left">
                      {prize.prize}
                    </p>
                    <div className="p-1 rounded-lg xs_sm:rounded-md bg-primary flex gap-2 items-center px-2 w-max">
                      <Common.typography
                        fontFamily="Sequel100Wide95"
                        fontSize="18px"
                        fontSizeSmall="10px"
                        color="#000"
                        className={`uppercase`}
                      >
                        {prize?.quantity - prize?.quantitywon}/{prize?.quantity}
                      </Common.typography>
                      <Common.typography
                        fontFamily="Sequel100WideVF55"
                        fontSize="18px"
                        fontSizeSmall="10px"
                        color="#000"
                        className={`uppercase`}
                      >
                        TO BE WON
                      </Common.typography>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <button
                    onClick={() => handleCollapse(index)}
                    className="p-2 rounded-lg"
                  >
                    {uncollapsedIndex === index ? (
                      <ArrowCircleUpIcon className="text-[#fff]" />
                    ) : (
                      <ArrowCircleDownIcon className="text-[#fff]" />
                    )}
                  </button>
                </div>
              </div>
              {uncollapsedIndex === index && prizes?.length > 0 ? (
                <div>
                  <hr className="border-gray-400 border-1 my-4 mx-2" />
                  <div className="overflow-x-auto scrollbar scrollbar-thumb-primary scrollbar-track-[#3B3B3B] scrollbar-w-[4px] scrollbar-h-[4px]">
                    <div className="grid grid-cols-4 gap-2 w-full xs_sm:w-[470px]">
                      {prizes?.map((prize: IPrize, index: number) => (
                        <PrizeCardNew
                          description={prize.description}
                          title={prize.prize}
                          image={prize?.imageUrl}
                          key={index}
                          date={new Date(
                            "2022-09-09T00:00:00.000Z"
                          ).toISOString()}
                          winningTicket={prize?.winningTicket || ""}
                          winner={prize?.winningWalletAddress || ""}
                        />
                      ))}
                    </div>
                    <VSpacer small={20} big={20} />
                  </div>
                  {/* create left right pagination */}
                  {totalPages > 1 && (
                    <div className="flex justify-center mt-4">
                      <button
                        onClick={() => {
                          if (prizesPage > 1) {
                            getInstantPrizes(
                              prizes?.[index]?.prize,
                              prizesPage - 1
                            );

                            // scroll to pagination_{prizesPage} horizontally
                            const element = document.getElementById(
                              `pagination_${prizesPage}`
                            );
                            if (element) {
                              element.scrollIntoView({
                                behavior: "smooth",
                                block: "end",
                                inline: "end",
                              });
                            }
                          }
                        }}
                        className="p-2 rounded-lg"
                      >
                        <img src="/Images/play_arrow_filled-left.svg" />
                      </button>
                      <div className="w-[150px] overflow-x-scroll">
                        <div className="flex flex-row min-w-max justify-center items-center">
                          {Array.from(
                            { length: totalPages },
                            (_, i) => i + 1
                          ).map((page, index) => (
                            <button
                              id={`pagination_${index}`}
                              key={index}
                              onClick={() =>
                                getInstantPrizes(prizes?.[index]?.prize, page)
                              }
                              className={`p-2 rounded-lg font-['Sequel100WideVF55'] ${
                                page === prizesPage
                                  ? "text-primary"
                                  : "text-[#fff]"
                              }`}
                            >
                              {page}
                            </button>
                          ))}
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          if (totalPages > prizesPage) {
                            getInstantPrizes(
                              prizes?.[index]?.prize,
                              prizesPage + 1
                            );

                            // scroll to pagination_{prizesPage} horizontally
                            const element = document.getElementById(
                              `pagination_${prizesPage}`
                            );
                            if (element) {
                              element.scrollIntoView({
                                behavior: "smooth",
                                block: "end",
                                inline: "end",
                              });
                            }
                          }
                        }}
                        className="p-2 rounded-lg"
                      >
                        <img src="/Images/play_arrow_filled-right.svg" />
                      </button>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          ))}
      </div>
    </div>
  );
}

export default PrizeCardsContainer;
