import React from "react";
import { Common } from "../../../common/Common";

export default function CompName({ text }: { text: string }) {
  return (
    <div className="w-[100%] m-auto bg-white h-[46.11px] rounded-[44.06px] flex justify-center items-center ">
      <Common.typography
        fontFamily="Sequel100Wide95"
        fontSize="30px"
        fontSizeSmall="24.7px"
        color="#0000000"
        className="text-center truncate px-4 !text-nowrap"
      >
        {text}
      </Common.typography>
    </div>
  );
}
