import React from "react";
import { useNavigate } from "react-router-dom";
import HSpacer from "../../common/Spacer/HSpacer";
import VSpacer from "../../common/Spacer/VSpacer";
import { CompetitionImages } from "../Competitions";
import Action from "./subComponents/Action";
import Button from "./subComponents/Button";
import CompName from "./subComponents/CompName";
import Counter from "./subComponents/Counter";
import HighRoller from "./subComponents/HighRoller";
import Instant from "./subComponents/Instant";
import Progress from "./subComponents/Progress";
import Promo from "./subComponents/Promo";

interface CompetitionCardProps {
  live?: boolean;
  time?: { days: number; hours: number; minutes: number; seconds: number };
  prizeName?: string;
  price?: number;
  ticketsLeft?: number;
  prizeIcon?: string;
  onClick?: (ticket: string) => void;
  timerBg?: string;
  borderColor?: string;
  finished?: boolean;
  width?: string;
  overlay?: boolean;
  UID?: string;
  categroy?: string;
  competitionenddate?: Date;
  competitionEndDateString?: string;
  competitioninformation?: string;
  competitionname?: string | undefined;
  competitionticketsize?: number | undefined;
  featured?: number;
  imageurl?: string;
  legalinformation?: string;
  manual?: string;
  totalticketsbought?: number | undefined;
  numberofticketsbought?: number | undefined;
  minnumberoftickets?: number | undefined;
  isHighRoller?: boolean;
  instant?: boolean;
  rollerAmount?: string;
  Winner?: string;
  isCompetitionFinished?: boolean;
  imageUrls?: CompetitionImages[];
  entryValue?: string;
  affiliatecompetition?: number;
}

export default function CompetitionCard(props: CompetitionCardProps) {
  const ticketSold = () => {
    if (props?.totalticketsbought && props?.competitionticketsize) {
      const sold = (
        (props?.totalticketsbought / props?.competitionticketsize) *
        100
      ).toFixed(0);
      return sold;
    } else {
      return 0;
    }
  };
  const navigate = useNavigate();
  function loadCompetition(UID?: string) {
    const isLogged = localStorage.getItem("token");
    // if (isLogged) {
    if (props?.instant && !props?.isCompetitionFinished) {
      navigate(`/view/competition/instant-win/${UID}`);
    } else if (props?.isCompetitionFinished) {
      navigate(`/finished-competition/${UID}`);
    } else {
      navigate(`/live-competition/${UID}`);
    }
    // }
    // if (props?.isCompetitionFinished && !props?.instant) {
    //     navigate(`/finished-competition/${UID}`);
    // }
  }
  return (
    <div>
      {/* // 1 - main div */}
      <div className="sm:h-[527px] h-[657px]  w-[425.39px] sm:w-[350px] max-350:w-[340px] flex flex-col justify-end relative">
        {/* 2 - counter */}
        <div
          className={` sm:h-[37px] sm:top-[1.4px] h-[45.04px]  w-[calc(100%-39.66px)] mx-auto absolute top-[-2px] left-0 right-0 ${
            (props?.competitionenddate &&
              new Date(props?.competitionenddate) < new Date()) ||
            props?.Winner
              ? "bg-[#EF008F]"
              : "bg-white"
          }  rounded-[26.75px] z-10`}
        >
          <Counter
            winner={props?.Winner}
            text={props?.competitionenddate?.toString() ?? ""}
          />
        </div>
        {/* 3 - second main div */}
        <div
          className={`h-[calc(100%-19px)] w-full rounded-[22.03px] overflow-hidden border-2 ${
            (props?.competitionenddate &&
              new Date(props?.competitionenddate) < new Date()) ||
            props?.Winner
              ? "border-[#EF008F]"
              : "border-white"
          }`}
        >
          {/* 4 - image */}
          <div
            className="sm:h-[50%] h-[55%] w-full bg-cover bg-no-repeat relative"
            //   style={{ backgroundImage: 'url("https://via.placeholder.com/150")' }}
            style={{
              backgroundImage: `url(${
                // props?.imageUrls && props?.imageUrls[0]?.imageurl
                props?.imageUrls &&
                props?.imageUrls[0]?.imageurl?.includes("http")
                  ? props?.imageUrls[0]?.imageurl
                  : "/Images/No Image.svg"
              })`,
            }}
          >
            <div className="absolute bottom-0 left-0 right-0 ">
              <HighRoller isHighRoller={props?.isHighRoller} />
              <Instant isInstant={props?.instant} />
              <Promo affiliatecompetition={props?.affiliatecompetition} />
            </div>
          </div>
          {/* 5 - details container */}
          <div className="sm:h-1/2 h-[45%] px-[14.39px] bg-[#343434] rounded-[22.03px] flex justify-center flex-col">
            <VSpacer small={18.12} big={22} />
            <CompName
              text={
                props?.competitionname?.length &&
                props?.competitionname?.length > 12
                  ? props?.competitionname?.slice(0, 12) + ".."
                  : props?.competitionname || "N/A"
              }
            />
            <VSpacer small={19.72} big={24} />
            <Progress ticketSold={ticketSold} />
            <VSpacer small={18.42} big={22.31} />
            <Action
              winner={props?.Winner}
              date={props?.competitionenddate?.toString() ?? ""}
              entryValue={
                props?.minnumberoftickets &&
                (props?.minnumberoftickets * 0.99)?.toFixed(2)
              }
              minnumberoftickets={props?.minnumberoftickets}
            />
            <VSpacer small={12.33} big={15.05} />
            <Button
              winner={props?.Winner}
              date={props?.competitionenddate?.toString() ?? ""}
              onClick={() => loadCompetition(props?.UID)}
            />
            <VSpacer small={18.12} big={22} />
          </div>
        </div>
      </div>
    </div>
  );
}
