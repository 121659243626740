import React, { useEffect } from "react";
import { useRef, useState } from "react";
import AdvertBox from "../Components/Page Components/AdvertBox";
import CompetitionDetails from "../Components/Page Components/CompetitionDetails";
import {
  CompetitionProps,
  Competitions,
} from "../Components/Page Components/Competitions";
import FAQ from "../Components/Page Components/FAQ";
import HowToPlay from "../Components/Page Components/HowToPlay";
import WithNavbarAndFooter from "../Layout/WithNavbarAndFooter";
import { useAppSelector } from "../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { CompetitionObject } from "../redux/reducers/competitionsSlice";
import { scrollTo } from "../utils/ScrollTo";
import { getCompetitionById } from "../Services/userService";
import moment from "moment";
import LiveCompetitionFAQDivNew from "../Components/Page Components/LiveCompetitionFAQDivNew";
import AdvertBoxNew from "../Components/Page Components/AdvertBoxNew";
import PhoneStoresNew from "../Components/Page Components/PhoneStoresNew";

const CompetitionDetailPage = (props: any) => {
  const connectWalletFunction = props?.connectWalletFunction;
  const [competition, setCompetition] = useState<any>();
  const params = useParams();
  const competitionid = params?.id;
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getCompetition = async (competitionid: string) => {
    try {
      setLoading(true);
      const result = await getCompetitionById({ competitionid });
      console.log(result);
      setCompetition(result.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const formattedDateTime = moment(competition?.competitionenddate)
    .utcOffset("+00:00")
    .format("DD-MM-YYYY @ h:mmA");

  useEffect(() => {
    console.log("params", competitionid);
    getCompetition(competitionid || "");
  }, [competitionid]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="hero ">
      <div className="w-full flex flex-col items-center ">
        {loading ? (
          <div className="loader"></div>
        ) : (
          <div className="flex flex-col items-center liveCompetition w-full">
            <CompetitionDetails
              getCompetition={getCompetition}
              competition={competition}
            />
          </div>
        )}
      </div>
      <div className="sm:p-2 md:p-2 lg:p-2">
        <div className="bg-black rounded-3xl xl:mx-12 mt-[48px] sm:mt-[10px] md:mt-[10px] lg:mt-[10px]">
          <h1 className="flex text-[45px] justify-center font-['Sequel100Wide'] sm:text-[28px] items-center font-[900] text-black uppercase bg-primary sm:rounded-b-none md:rounded-b-none lg:rounded-b-none h-[180px] w-full  pt-[-1em] sm:pl-0 md:pl-0 lg:pl-0 sm:text-center md:text-center lg:text-center rounded-3xl">
            Prize Information {formattedDateTime}
          </h1>
          <div className="sm:border-2 md:border-2 lg:border-2 competitionDetails sm:rounded-t-none md:rounded-t-none lg:rounded-t-none rounded-3xl sm:border-primary md:border-primary lg:border-primary font-[Sequel100Wide] w-full p-[5em] px-[10%] text-white flex flex-col gap-[3em] sm:text-center md:text-center lg:text-center">
            <div>
              <h4 className="text-[40px] font-bold text-white sm:text-[22px]">
                Competition Details
              </h4>
              <h1 className="text-[50px] font-[900] sm:text-[28px]">
                {competition?.competitionname}
              </h1>
            </div>
            <div className="flex flex-col gap-4 font-[MontBlancRegular] text-[20px] sm:text-[16px]">
              <span>{competition?.competitioninformation}</span>{" "}
            </div>
          </div>
        </div>
      </div>
      <HowToPlay />
      <div
        id="faqDiv"
        className="bg-gradient-to-br from-[#a51269] to-[#2b2b2b] via-[#2b2b2b] bg-[length:100%_100%] //py-12"
      >
        <LiveCompetitionFAQDivNew
          className={"faq"}
          fontSize={"text-[60px] sm:text-[28px]"}
          isInstant={true}
        />
        <div className="flex flex-row justify-center py-12">
          <AdvertBoxNew
            connectWalletFunction={connectWalletFunction}
            instant={true}
          />
        </div>
        <PhoneStoresNew
          instant={true}
          background="bg-gradient-to-r from-[#a51269] to-[#2b2b2b] h-screen "
        />
      </div>

      {/* <PhoneStores background={"phoneStores"} /> */}
    </div>
  );
};

export default WithNavbarAndFooter(CompetitionDetailPage);
