import Countdown from "react-countdown";
import { Text } from "../../../react-responsive-utilities/src";
import VSpacer from "../../../common/Spacer/VSpacer";
import { useEffect } from "react";

interface TimerProps {
  competitionEndDate?: Date;
}

const Unit = ({
  value,
  unit,
  isLast = false,
}: {
  value: string;
  unit: string;
  isLast?: boolean;
}) => (
  <div className="flex flex-col items-center">
    <div className="flex flex-row items-center justify-center gap-1">
      <div className="px-3 py-2 bg-white rounded-lg text-center">
        <Text
          textSize={[16, 21.93, 27.61]}
          className="text-[#2E2122] font-['MontBlancBlack'] leading-[29px] font-[218]"
        >
          {value}
        </Text>
      </div>
      {!isLast && (
        <Text
          textSize={[16, 21.93, 27.61]}
          className="text-[#FFFFFF] font-['MontBlancBlack'] leading-[29px] font-[218] block"
        >
          :
        </Text>
      )}
    </div>
    <Text
      textSize={[0, 14.93, 16.0]}
      className="text-[#FFFFFF] sm_md:text-center w-full block font-['MontBlancRegular'] font-semibold leading-[20px] uppercase"
    >
      {unit}
    </Text>
  </div>
);

export default function Timer({ competitionEndDate }: TimerProps) {
  const date = new Date(competitionEndDate || new Date());
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();

  useEffect(() => {
    console.log("Competition End Date: ", competitionEndDate);
  }, [competitionEndDate]);

  return (
    <div className="w-fit">
      <Text
        textSize={[18, 22.0, 24.52]}
        className="text-[#FFFFFF] font-['Sequel100Wide95'] leading-[24px] font-[60] block sm_md:text-center uppercase"
      >
        Finished date
      </Text>
      <VSpacer small={15.5} big={22.11} />
      <div className="flex sm_md:justify-center gap-2">
        <Unit value={day} unit="Day" />
        <Unit value={month} unit="Month" />
        <Unit value={year} unit="Year" isLast />
      </div>
    </div>
  );
}
