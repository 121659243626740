import React, { useEffect, useState } from "react";
import VSpacer from "../../../Components/common/Spacer/VSpacer";
import Category from "../../../Components/User Pages Components/My Orders/Category/Category";
import OrdersTableSmall from "../../../Components/User Pages Components/My Orders/OrdersTable/OrdersTableSmall";
import WithNonAbsolutedSidebarNavbarFooter from "../../../Layout/WithNonAbsolutedSidebarNavbarFooter";
import OrdersTable from "../../../Components/User Pages Components/My Orders/OrdersTable/OrdersTable";
import { useAppDispatch } from "../../../redux/hooks";
import {
  getMyOrdersTicketsData,
  userTransactions,
} from "../../../Services/userService";
import moment from "moment";
import _ from "lodash";
import OrdersTableTickets from "../../../Components/User Pages Components/My Orders/OrdersTableTickets/OrdersTableTickets";
import OrdersTableTicketsSmall from "../../../Components/User Pages Components/My Orders/OrdersTableTickets/OrdersTableTicketsSmall";
import { LinearProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useWallet } from "../../../Contexts/WalletContext";

export default function MyOrders() {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    document.getElementById("my_orders_main")?.scrollIntoView();
    setIsMounted(true);
  }, []);
  const [activeCategory, setActiveCategory] = React.useState("Purchases");
  const getIsActive = (category: string) => category === activeCategory;
  const handleCategoryClick = (category: string) => setActiveCategory(category);

  const [transactionData, setTransactionData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [isFetching, setIsFetching] = useState(false);

  const { token } = useWallet();

  const fetchTransactions = async (pageNo: number) => {
    if (isFetching) return;
    setIsFetching(true);
    console.log("pageNo", pageNo);
    try {
      const response = await userTransactions(pageNo);
      console.log("transactions", response);
      if (response.data.length > 0) {
        const sortedArray = _.orderBy(
          response.data,
          (o: any) => {
            return moment(o.DATE_TIME).format("lll");
          },
          ["desc"]
        );

        console.log("sortedArray", sortedArray);
        setTransactionData(sortedArray);
        setPage(parseInt(response.page));
        setTotalPages(response.totalPages);
        setTotalRecords(response.totalRecords);
      }
      setIsFetching(false);
    } catch (error) {
      console.log(error);
      setIsFetching(false);
    }
  };

  const isLastPage = () => {
    console.log("page", page);
    let condition = true;
    if (page < totalPages) {
      condition = false;
    } else {
      condition = true;
    }
    return condition;
  };

  // const isAuthenticated = (): boolean => {
  //   const token = localStorage.getItem("token"); // Or your preferred method of storing the token
  //   return !!token; // Return true if token exists, otherwise false
  // };

  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (!isAuthenticated()) {
  //     navigate("/"); // Redirect to the homepage if not authenticated
  //   }
  // }, [navigate]);

  const [table, setTable] = useState<any[]>([]);
  useEffect(() => {
    setTable((prev) =>
      transactionData !== prev
        ? [
            ...prev,
            ...transactionData.map((obj) => {
              console.log("obj: ", obj);
              const uid = obj?.UNIQUE_IDENTIFIER;
              const uidParts = uid?.split("-");
              const uidEndNumber = uidParts
                ? uidParts[uidParts.length - 1]
                : undefined;

              return {
                uid: obj?.UNIQUE_IDENTIFIER,
                competitionName: obj?.NAME || "N/A",
                Amount: parseFloat(obj?.price)?.toFixed(2) || "N/A",
                ticketsbought: uidEndNumber || "N/A",
                network: obj?.network || "N/A",
                TransactionHash: obj?.transactionhash || "N/A",
                DateTime:
                  moment(obj?.DATE_TIME).format("M/D/YYYY") +
                    " " +
                    moment(obj?.DATE_TIME).format("h:mm A") || "N/A",
              };
            }),
          ]
        : prev
    );

    console.log("Table", table);
  }, [transactionData]);

  const onScrollToBottom = () => {
    if (page >= totalPages) return;
    fetchTransactions(page + 1);
  };

  useEffect(() => {
    if (token) {
      fetchTransactions(page);
    }
  }, [token]);

  useEffect(() => {
    console.log("table", table);
  }, [table]);

  const [ticketsTableData, setTicketsTableData] = useState<any[]>([]);
  const [ticketPage, setTicketPage] = useState(1);
  const [totalTicketPages, setTicketTotalPages] = useState(0);
  const [isFetchingTickets, setIsFetchingTickets] = useState(false);

  const handleGetMyOrdersTicketsData = async (page: number) => {
    try {
      setIsFetchingTickets(true);
      const response = await getMyOrdersTicketsData(page);

      console.log("response ticket ", response?.data);
      if (!isMounted) {
        setTicketsTableData(response.data);
      } else {
        setTicketsTableData((prev) => [...prev, ...response.data]);
      }
      setTicketPage(response.page);
      setTicketTotalPages(response.totalPages);
      setIsFetchingTickets(false);
    } catch (error) {
      console.log(error);
      setIsFetchingTickets(false);
    }
  };

  const isLastPageTicket = () => {
    console.log("ticketPage", ticketPage);
    let condition = true;
    if (ticketPage < totalTicketPages) {
      condition = false;
    } else {
      condition = true;
    }
    return condition;
  };

  const onScrollToBottomOrderTicket = () => {
    if (ticketPage >= totalTicketPages) return;
    handleGetMyOrdersTicketsData(ticketPage + 1);
  };

  useEffect(() => {
    if (token) {
      handleGetMyOrdersTicketsData(ticketPage);
    }
  }, [token]);

  return (
    // Main View
    <WithNonAbsolutedSidebarNavbarFooter
      Component={
        // Main container
        <div
          id="my_orders_main"
          className="bg-[#181818] w-full sm_md_lg:mx-auto sm_md_lg:w-[calc(100%-20px)] sm_md_lg:my-[0px] xl:my-8 h-full mx-[43px] rounded-[30px] py-[63px] px-[78px] sm_md:py-[44px] sm_md:px-[31px] flex flex-col items-center justify-center "
        >
          {/* 1 - Title */}
          <p className="font-[Sequel100Wide95] uppercase text-[45px] sm_md_lg:text-[30px] text-white ">
            Orders
          </p>
          {/* 2 - description */}
          <p className="font-[Sequel100WideVF45] text-[20px] sm_md_lg:text-[14px] text-white text-center">
            Click on the order numbers to view more information
          </p>
          {/* 3 - Spacer description and navbar */}
          <VSpacer small={31} big={59} />
          {/* 4 - Navbar */}
          <div className="flex gap-[27.16px] sm_md_lg:gap-[10px] w-full justify-center sm_md_lg:flex-col">
            {/* 4.1 - Purchases */}
            <Category
              text="Purchases"
              onClick={() => handleCategoryClick("Purchases")}
              isActive={getIsActive("Purchases")}
            />
            {/* 4.2 - Tickets */}
            <Category
              text="Entries"
              onClick={() => handleCategoryClick("Tickets")}
              isActive={getIsActive("Tickets")}
            />
          </div>
          {/* 5 - Spacer navbar and orders */}
          <VSpacer small={31} big={59} />
          {/* 6 - Orders */}
          {activeCategory === "Purchases" ? (
            <>
              <OrdersTableSmall
                isLastPage={isLastPage()}
                isFetching={isFetching}
                onScrolledToBottom={() => {}}
                loadmoreFunction={onScrollToBottom}
                table={table}
              />
              <OrdersTable
                isLastPage={isLastPage()}
                isFetching={isFetching}
                onScrolledToBottom={() => {}}
                loadmoreFunction={onScrollToBottom}
                table={table}
              />
            </>
          ) : (
            <>
              <OrdersTableTicketsSmall
                isLastPage={isLastPageTicket()}
                loadmoreFunction={onScrollToBottomOrderTicket}
                isFetching={isFetchingTickets}
                onScrolledToBottom={() => {}}
                table={ticketsTableData}
              />
              <OrdersTableTickets
                isLastPage={isLastPageTicket()}
                loadmoreFunction={onScrollToBottomOrderTicket}
                isFetching={isFetchingTickets}
                onScrolledToBottom={() => {}}
                table={ticketsTableData}
              />
            </>
          )}
        </div>
      }
    />
  );
}
