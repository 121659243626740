import React from "react";
import { Common } from "../../../common/Common";
import { useNavigate } from "react-router-dom";

interface UserDetailProps {
  name: string;
  tickets: any;
  logoutButtonHandler: any;
}
export default function UserDetail(props: UserDetailProps) {
  const navigate = useNavigate();
  const handleNavigateToUserPage = () => {
    navigate("/buytickets");
  };

  const handleNavigateToAccountsPage = () => {
    navigate("/account");
  };
  return (
    <div className="h-[62.99px] flex justify-between items-center //w-[calc(220px+45px)] bg-[#EF008F] rounded-[10px] //overflow-hidden sm_md_lg:w-full">
      {/* detail container */}
      <div
        onClick={handleNavigateToAccountsPage}
        className="
      cursor-pointer
      min-w-0 w-full rounded-[10px] max-w-[184.77px] //max-w-[220px] sm_md_lg:max-w-[calc(100%-45px)] h-full flex bg-[#DDE404] below-1280:justify-start justify-center items-center //sm_md_lg:justify-between px-[12px] gap-[11px]"
      >
        {localStorage?.getItem("network") === "ethereum" ? (
          <img
            src="/Images/icon_eth.svg"
            alt=""
            className="h-[26px] w-[17px]"
          />
        ) : localStorage?.getItem("network") === "base" ? (
          <img
            src="/Images/icon_base.svg"
            alt=""
            className="h-[26px] w-[26px]"
          />
        ) : localStorage?.getItem("network") === "solana" ? (
          <img
            src="/Images/icon_solana.svg"
            alt=""
            className="h-[26px] w-[27px]"
          />
        ) : (
          <img
            src="/Images/icon_btc.svg"
            alt=""
            className="h-[26px] w-[17px]"
          />
        )}
        {/* details */}
        <div className="flex flex-col justify-center items-center">
          {/* name */}
          <Common.typography
            fontFamily="Sequel100Wide95"
            fontSize="12.49px"
            fontSizeSmall="12.49px"
            color="#000000"
            className="text-center h-6 leading-none flex justify-center overflow-hidden items-center uppercase"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {/* {props.name.length > 12
              ? `${props.name.slice(0, 12)}...`
              : props.name} */}
            My Account
          </Common.typography>

          <hr className="bg-black border-0 h-[1.54px] w-full //w-[146.6px]" />
          {/* Ticket details */}
          <div className="flex justify-between gap-2 w-full items-center">
            {/* Tickets */}
            <div className="flex justify-center items-center h-6 ">
              <Common.typography
                fontFamily="Sequel100WideVF45"
                fontSize="9.76px"
                fontSizeSmall="9.76px"
                color="#000000"
                className="text-center uppercase"
              >
                Tickets
              </Common.typography>
            </div>
            {/* <hr className="bg-black border-0 w-[1.54px] h-[22px]" /> */}
            {/* ticket value */}
            <div className="flex justify-center items-center h-6">
              <Common.typography
                fontFamily="Sequel100Wide95"
                fontSize="11.55px"
                fontSizeSmall="11.55px"
                color="#000000"
                className="text-center"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {props?.tickets
                  ? props?.tickets >= 1000
                    ? `${(props.tickets / 1000).toFixed(1)}k`
                    : props.tickets
                  : 0}
              </Common.typography>
              {/* <Common.image src="/Images/ticketicon.svg" alt="ticket icon" /> */}
            </div>
            <img src="/Images/icon_ticket.svg" alt="ticket icon" />
          </div>
        </div>
        {/* navigate to user page btn */}
        {/* <div>
          <Common.image
            src="/Images/arrow_right_black.svg"
            alt="arrow next circle"
            onClick={handleNavigateToUserPage}
          />
        </div> */}
      </div>
      {/* power button */}
      <div className="h-full justify-center items-center flex">
        <Common.image
          className="cursor-pointer object-cover "
          src="/Images/powerbtn.svg"
          alt="power button"
          onClick={props?.logoutButtonHandler}
        />
      </div>
    </div>
  );
}
