import React, { useEffect } from "react";
import { useRef, useState } from "react";
import AdvertBox from "../Components/Page Components/AdvertBox";
import CompetitionDetails from "../Components/Page Components/CompetitionDetails";
import {
  CompetitionProps,
  Competitions,
} from "../Components/Page Components/Competitions";
import FAQ from "../Components/Page Components/FAQ";
import HowToPlay from "../Components/Page Components/HowToPlay";
import WithNavbarAndFooter from "../Layout/WithNavbarAndFooter";
import { useAppSelector } from "../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { CompetitionObject } from "../redux/reducers/competitionsSlice";
import { scrollTo } from "../utils/ScrollTo";
import { getCompetitionById } from "../Services/userService";
import PhoneStores from "../Components/Page Components/PhoneStores";
import moment from "moment";
import LiveCompetitionDetails from "../Components/Page Components/LiveCompetitionDetails";
import Carousel from "../Components/common/Carousel/Carousel";
import HowItWorksBoxSmall from "../Components/Page Components/HowItWorksBoxSmall";
import HowItWorksBox, {
  HowItWorksBoxProps,
} from "../Components/Page Components/HowItWorksBox";
import LiveCompetitionFAQ from "../Components/Page Components/LiveCompetitionFAQDiv";
import { howItWorks } from "./LandingPage";
import { Loader } from "../Components/common/loader/Loader";
import PhoneStoresNew from "../Components/Page Components/PhoneStoresNew";
import AdvertBoxNew from "../Components/Page Components/AdvertBoxNew";
import LiveCompetitionFAQDivNew from "../Components/Page Components/LiveCompetitionFAQDivNew";

const LiveCompetitionDetailPage = (props: any) => {
  const connectWalletFunction = props?.connectWalletFunction;
  const [competition, setCompetition] = useState<any>();
  const [showLiveDraw, setShowLiveDraw] = useState<boolean>();
  const params = useParams();
  const competitionid = params?.id;
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const enter = "/Images/enter-icon.svg";
  const crown = "/Images/crown-icon.svg";
  const nuclear = "/Images/nuclear-icon.svg";

  const getCompetition = async (competitionid: string) => {
    try {
      setLoading(true);
      const result = await getCompetitionById({ competitionid });
      console.log(result);
      setCompetition(result.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const formattedDateTime = moment(competition?.competitionenddate)
    .utcOffset("+00:00")
    .format("DD-MM-YYYY @ h:mmA");

  useEffect(() => {
    console.log("params", competitionid);
    if (!competition) getCompetition(competitionid as string);
    // ------------------ this timout is to handle an error of user tickets being zero if fetched the competition for the first time ----------------/
    // ------------------ please do not remove this timeout ----------------/
    setTimeout(() => {
      if (!competition) {
        getCompetition(competitionid as string);
      }
    }, 500);
  }, [competition, competitionid]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // ------------- this min and max state is used to handle the range of lucky dips and in turn the range of the numberpicker component ----------------/
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  // ------------- end of min and max state ----------------/

  return (
    <div className="hero mt-[16.87px]">
      <Loader show={loading} />
      <div className="w-full flex flex-col items-center ">
        {loading ? (
          <div className="loader"></div>
        ) : (
          <div className="flex flex-col items-center liveCompetition w-full">
            <LiveCompetitionDetails
              connectWalletFunction={connectWalletFunction}
              min={min}
              max={max}
              setMin={setMin}
              setMax={setMax}
              getCompetition={getCompetition}
              competition={competition}
              affiliatecompetition={competition?.affiliatecompetition}
            />
          </div>
        )}
      </div>
      <div className="ml-[3%] mr-[3%] mt-[3%]">
        <div className="bg-black rounded-3xl ">
          <h1
            onClick={() => {
              setShowLiveDraw(!showLiveDraw);
            }}
            className="flex text-[45px] md:text-[35px] justify-between font-['Sequel100Wide'] sm:text-[28px] items-center font-[900] text-black uppercase bg-primary  h-[180px] w-full  pt-[-1em] sm:pl-0 md:pl-0 lg:pl-0 sm:text-center md:text-center lg:text-center rounded-3xl xl:pl-[140px] xl:pr-[140px] lg:pl-[90px] lg:pr-[90px] md:pl-[60px] md:pr-[60px] sm:pl-[30px] sm:pr-[30px]"
          >
            Draw {formattedDateTime}
            <div className="align-right pr-[40px]"></div>
            <img src="/Images/arrow-down.svg" alt="" />
          </h1>

          {showLiveDraw ? (
            <div className="sm:rounded-t-none md:rounded-t-none lg:rounded-t-none rounded-3xl sm:border-primary md:border-primary lg:border-primary font-[Sequel100Wide] w-full p-[5em] px-[10%] text-white flex flex-col gap-[3em] sm:text-center md:text-center lg:text-center">
              {/* First Line: Competition Name */}
              <div>
                <h4 className="text-[40px] font-bold text-white sm:text-[22px]">
                  {competition?.competitionname ||
                    "Competition Name Unavailable"}
                </h4>
              </div>

              {/* Second Line: Competition Description */}
              {/* <div className="flex flex-col gap-4 font-[MontBlancBold] text-[20px] sm:text-[16px]">
                <span>
                  {competition?.competitioninformation ||
                    "Competition Description Unavailable"}
                </span>
              </div> */}

              <div className="flex flex-col gap-4 font-[MontBlancBold] text-[20px] sm:text-[16px]">
                <div
                  className="quill_renderer quill_renderer_dark"
                  dangerouslySetInnerHTML={{
                    __html:
                      competition?.competitioninformationlong !== null
                        ? `
                    <div class="quill_renderer">
                    ${competition?.competitioninformationlong}
                    </div>`
                        : "",
                  }}
                ></div>
              </div>

              {/* Third Line: Optional Legal Information */}
              {competition?.legalinformation && (
                <div className="flex flex-col gap-4 font-[MontBlancBold] text-[20px] sm:text-[16px]">
                  <p>{competition.legalinformation}</p>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="w-full flex flex-col items-center max-w-[1400px] m-auto pl-[3%] pr-[3%] ">
        <h1
          className={`pageHeading font-['Sequel100Wide95']`}
          style={{
            fontSize: "clamp(1px,5.529128597vw,45px)",
          }}
        >
          HOW IT WORKS
        </h1>
        {/* <div className="above-1367:scale-[1] flex font-['Sequel100WideVF'] gap-[1em] sm:hidden md:hidden xl:scale-[0.85] lg:scale-[0.80]"> */}
        {/* <div className="grid-cols-8 w-full  place-content-center place-items-center xl:grid lg:grid md:grid hidden">
            {howItWorks.map((item, index) => (
              <>
                <div className="col-span-2">
                  <HowItWorksBox
                    icon={item.icon}
                    title={item.title}
                    content={item.content}
                  />
                </div>
                <div className="flex justify-center items-center col-span-1">
                  {index + 1 !== howItWorks.length && (
                    <img
                      src="/Images/next_white.png"
                      alt=""
                      className="sm:hidden md:hidden"
                    />
                  )}
                </div>
              </>
            ))}
          </div> */}

        <div className="flex container flex-shrink max-w-[100%] sm:hidden  mb-[49.55px]">
          {howItWorks.map((item, index) => (
            <>
              <HowItWorksBox
                icon={item.icon}
                title={item.title}
                content={item.content}
                key={index}
              />
              {index + 1 !== howItWorks.length && (
                <div
                  className="min-w-[5%] flex justify-center items-center"
                  key={index}
                >
                  <img
                    src="/Images/next_white.png"
                    alt=""
                    className="object-scale-down "
                  />
                </div>
              )}
            </>
          ))}
        </div>

        {/* how it works mobile */}
        <div className="lg:hidden xl:hidden md:hidden">
          {/* <Slider slides={howItWorks} options={OPTIONS} /> */}
          <Carousel>
            {howItWorks.map((item, index) => (
              <HowItWorksBoxSmall
                icon={item.icon}
                title={item.title}
                content={item.content}
                key={index}
              />
            ))}
          </Carousel>
        </div>
      </div>

      <div
        id="faqDiv"
        className="bg-gradient-to-br from-[#a51269] to-[#2b2b2b] via-[#2b2b2b] bg-[length:100%_100%] //py-12"
      >
        <LiveCompetitionFAQDivNew
          className={"faq"}
          fontSize={"text-[60px] sm:text-[28px]"}
          isInstant={competition?.instant === 0 ? false : true}
        />
        <div className="flex flex-row justify-center py-12">
          <AdvertBoxNew
            connectWalletFunction={connectWalletFunction}
            instant={true}
          />
        </div>
        <PhoneStoresNew
          instant={true}
          background="bg-gradient-to-r from-[#a51269] to-[#2b2b2b] h-screen "
        />
      </div>
    </div>
  );
};

export default WithNavbarAndFooter(LiveCompetitionDetailPage);
