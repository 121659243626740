import { useEffect, useState } from "react";
import { CompetitionProps } from "../Competitions";
import {
  finishedCompetition,
  getCompetitions,
  getImages,
} from "./heroFunctionalities";
import HeroSlide from "./HeroSlide";
import { Text } from "../../react-responsive-utilities/src";
import VSpacer from "../../common/Spacer/VSpacer";
import TrustPilot from "../../common/trustpilot/TrustPilot";
import { Common } from "../../common/Common";

const Achievements = () => (
  <>
    {/* FOR DESKTOP VERSION */}
    <div className="mx-[7.5%] flex xl:flex-row sm_md_lg:flex-col xl:justify-between sm_md_lg:justify-center sm_md_lg:items-center xl:items-center xl:gap-[20.78px] sm_md_lg:h-[120px] sm_md_lg:mt-[16px]">
      <div className="flex flex-col justify-center items-center sm_md_lg:hidden">
        <Text
          textSize={[0, 12.49, 40]}
          className="font-[Sequel100Wide] block text-white xl:leading-[50px] sm:leading-[10px]"
        >
          $100k+
        </Text>
        <Text
          textSize={[0, 10, 19.79]}
          className="font-[MontBlancRegular] block text-white text-center xl:leading-[30px] lg:leading-[25px] md:leading-[22px] sm:leading-[17px]"
        >
          GIVEN IN PRIZES
        </Text>
      </div>

      <img
        src="/Images/x_wheel.png"
        alt=""
        className="xl:block hidden ml-[5%] h-[95px] w-[95px] sm_md_lg:hidden"
      />

      <TrustPilot input={"/trustpilot.js"} />

      <img
        src="/Images/x_wheel.png"
        alt=""
        className="xl:block hidden mr-[5%] h-[95px] w-[95px] sm_md_lg:hidden"
      />

      <div className="flex flex-col justify-center items-center sm_md_lg:hidden">
        <Text
          textSize={[0, 12.49, 40]}
          className="font-[Sequel100Wide] block text-white xl:leading-[50px] sm:leading-[10px]"
        >
          200+
        </Text>
        <Text
          textSize={[0, 10, 19.79]}
          className="font-[MontBlancRegular] block text-white text-center xl:leading-[30px] lg:leading-[25px] md:leading-[22px] sm:leading-[17px]"
        >
          HAPPY WINNERS
        </Text>
      </div>
    </div>
    {/* for mobile */}
    <div className="flex flex-row justify-center xl:hidden sm_md_lg:flex w-fit mx-auto gap-8">
      {/* <div className="flex flex-col justify-center items-center w-full"> */}
      {/* <div className="ml-[-50px]"> */}
      <div>
        {/* <Text
          textSize={[20, 34.95, 51.48]}
          className="font-[Sequel100Wide] block text-white sm:leading-[20px]  sm:leading-[10px] text-right"
        >
          $50K+
        </Text>
        <VSpacer small={15} big={10} />
        <Text
          textSize={[15, 13.98, 25.48]}
          className="font-[MontBlancRegular] block text-white text-center sm:leading-[10px]  lg:leading-[25px] md:leading-[22px] sm:leading-[17px] text-left"
        >
          GIVEN IN
          <br />
          PRIZES
        </Text> */}
        <Common.typography
          fontSize={"34.95px"}
          fontSizeSmall={"34.95px"}
          color="white"
          fontFamily="MontBlancBlack"
          className="text-center "
        >
          $100K+
        </Common.typography>
        <VSpacer small={1} big={1} />
        <Common.typography
          fontSize={"15px"}
          fontSizeSmall={"13.98gpx"}
          color="white"
          fontFamily="MontBlancRegular"
          className="text-center"
        >
          GIVEN IN PRIZES
        </Common.typography>
      </div>

      {/* <div className="lg:w-[145px] md:w-[130px] sm:w-[1px]"></div> */}

      {/* <div className="flex flex-col justify-center items-center w-full"> */}
      {/* <div className="ml-[-30px]"> */}
      <div>
        {/* <Text
          textSize={[20, 34.95, 51.48]}
          className="font-[Sequel100Wide] block text-white sm:leading-[20px] xl:leading-[50px] sm:leading-[10px]"
        >
          40+
        </Text>
        <VSpacer small={15} big={10} />
        <Text
          textSize={[15, 13.98, 25.48]}
          className="font-[MontBlancRegular] block text-white text-center sm:leading-[10px] xl:leading-[30px] lg:leading-[25px] md:leading-[22px] sm:leading-[17px]"
        >
          HAPPY
          <br />
          WINNERS
        </Text> */}
        <Common.typography
          fontSize={"34.95px"}
          fontSizeSmall={"34.95px"}
          color="white"
          fontFamily="MontBlancBlack"
          className="text-center"
        >
          200+
        </Common.typography>
        <VSpacer small={1} big={1} />
        <Common.typography
          fontSize={"15px"}
          fontSizeSmall={"13.98px"}
          color="white"
          fontFamily="MontBlancRegular"
          className="text-center"
        >
          HAPPY WINNERS
        </Common.typography>
      </div>
    </div>
  </>
);

export default function HeroSlider() {
  const [index, setIndex] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>();
  const [competitionsList, setCompetitionsList] =
    useState<CompetitionProps[]>();
  const [images, setImages] = useState<any>();
  const [sliders, setSliders] = useState<CompetitionProps[]>();

  const handleGetCompetitions = async () => {
    await getCompetitions(setLoader, setCompetitionsList, setSliders);
  };
  useEffect(() => {
    handleGetCompetitions();
  }, []);

  useEffect(() => {
    if (competitionsList) {
      // getImages(competitionsList[index]?.UID as string, setImages);
      getImages(setImages, competitionsList[index]?.imageUrls as any);
    }
  }, [competitionsList, index]);

  const handleNext = () => {
    if (competitionsList && index < competitionsList?.length - 1) {
      setIndex(index + 1);
    } else {
      setIndex(0);
    }
  };

  const handlePrevious = () => {
    if (index > 0) {
      setIndex(index - 1);
    } else {
      if (competitionsList) setIndex(competitionsList?.length - 1);
    }
  };

  const achievements = [
    {
      title: "given in prizes",
      value: "$62M+",
    },
    {
      title: "happy winners",
      value: "30K",
    },
    {
      title: "social followers",
      value: "250K+",
    },
    {
      title: "donated to charities",
      value: "$598K+",
    },
  ];

  return (
    competitionsList && (
      <div className="relative rounded-t-[30px] overflow-hidden">
        {/* Next & Previous Buttons */}
        <div className="relative">
          <img
            className="xl:block lg:block hidden absolute left-0 top-1/2 transform -translate-y-1/2 z-10"
            src="/Images/previous.png"
            alt="previous"
            onClick={handlePrevious}
          />
          <img
            className="xl:block lg:block hidden absolute right-0 top-1/2 transform -translate-y-1/2 z-10"
            src="/Images/next.png"
            alt="next"
            onClick={handleNext}
          />
          {/* Slide */}
          <HeroSlide
            competitionEndDate={
              competitionsList[index]?.competitionenddate as Date
            }
            competitionTicketSize={
              competitionsList[index]?.competitionticketsize as number
            }
            competitionUID={competitionsList[index]?.UID as string}
            competitionEnded={finishedCompetition(
              competitionsList[index]?.competitionenddate
            )}
            costPerTicket={0.99}
            description={
              competitionsList[index]?.competitioninformation as string
            }
            image={images && images[0]?.imageurl}
            title={competitionsList[index]?.competitionname as string}
            totalTicketsBought={
              competitionsList[index]?.totalticketsbought as number
            }
            competition={competitionsList[index]}
            isInstant={Boolean(competitionsList[index]?.instant)}
            key={competitionsList[index]?.UID}
          />
        </div>

        {/* Index indicator */}
        <div className="relative flex w-full bg-[#DDE404] xl:h-[52px] h-[54.44px] justify-center items-center gap-[18.81px]">
          <img
            className="xl:hidden lg:hidden block"
            src="/Images/mobile_prev.png"
            alt=""
            onClick={handlePrevious}
          />
          {sliders?.map((slider, i) => (
            <div
              key={i}
              className={` ${
                index === i ? "bg-[#000000]" : "bg-[#D24848]"
              } w-full max-w-[65.25px] h-[5px]`}
            ></div>
          ))}
          <img
            className="xl:hidden lg:hidden block"
            src="/Images/mobile_next.png"
            alt=""
            onClick={handleNext}
          />
        </div>
        {/* Achievements */}
        {/* <div className="xl:py-[67.91px] py-[21.8px] bg-[#1A1A1A] relative ">
                    <img
                        src="/Images/THEPRIZE_TokenLogo_EH-03.svg"
                        alt=""
                        className="absolute -bottom-4 right-0 w-[255px] h-[clamp(40px,70vw,255px)]  object-cover hidden sm_md_lg:block"
                    /> */}
        {/* <div className="grid xl:grid-cols-4 grid-cols-2 gap-[30px] w-fit m-auto place-content-center place-items-center ">
                        {achievements.map((achievement, i) => (
                            <div className="flex  justify-between items-center gap-[24.78px]">
                                <Achievements
                                    key={i}
                                    title={achievement.title}
                                    value={achievement.value}
                                />
                                {achievements?.length !== i + 1 && (
                                    <img
                                        src="/Images/x_wheel.png"
                                        alt=""
                                        className="xl:block hidden"
                                    />
                                )}
                            </div>
                        ))}
                    </div> */}
        {/* </div> */}

        <div className="xl:pt-[37.91px] xl:pb-[27px] py-[21.8px] bg-[#1A1A1A] relative  z-[20]">
          <img
            src="/Images/THEPRIZE_TokenLogo_EH-03.svg"
            alt=""
            className="absolute -bottom-4 right-0 w-[255px] h-[clamp(40px,70vw,255px)]  object-cover hidden md_lg:block"
          />
          <Achievements />
          <VSpacer small={20} big={30} />
          <img
            src="/Images/spinner-with-lines.svg"
            alt=""
            className="mx-auto mb-[24.61px] sm_md_lg:block hidden"
          />
          <Text
            textSize={[30, 30.0, 42.0]}
            // textSize={[30, 30.0, 50.0]}
            className="text-[#FFFFFF] font-['Sequel100Wide95']  leading-normal xl:font-[60] md:font-[40] sm:font-[20] block text-center"
          >
            FEATURED IN
          </Text>

          {/* FOR WEB VERSION */}
          <div className="flex mt-[1%] mb-[1%] flex-row flex-nowrap justify-center items-center sm:hidden block ">
            <a
              className="w-[18%] h-auto "
              href="https://ambcrypto.com/uniting-blockchain-innovation-with-the-raffle-industry-an-interview-with-the-prize-team/"
            >
              <img src="/Images/ambcrypto.png" alt="" />{" "}
            </a>
            <a
              className="w-[18%] h-auto "
              href="https://thebittimes.com/the-prize-redefining-raffles-with-blockchain-tbt107752.html"
            >
              <img src="/Images/Featuredin_web_2.png" alt="" />{" "}
            </a>
            <img
              className="w-[18%] h-auto "
              src="/Images/Featuredin_web_3.png"
              alt=""
            />{" "}
            <a
              className="w-[18%] h-auto "
              href="https://crypto.news/the-prize-redefining-raffles-with-blockchain/"
            >
              <img
                //className="w-[18%] h-auto "
                src="/Images/Featuredin_web_4.png"
                alt=""
              />
            </a>{" "}
            <a
              className="w-[18%] h-auto "
              href="https://u.today/press-releases/the-prize-redefining-raffles-with-blockchain"
            >
              <img
                //className="w-[18%] h-auto "
                src="/Images/Featuredin_web_5.png"
                alt=""
              />
            </a>
          </div>

          {/* FOR MOBILE VERSION */}

          <div className="sm:grid grid-cols-2 hidden place-content-center place-items-center mx-auto">
            <a href="https://ambcrypto.com/uniting-blockchain-innovation-with-the-raffle-industry-an-interview-with-the-prize-team/">
              <img src="/Images/ambcrypto.png" alt="" />
            </a>
            <a href="https://thebittimes.com/the-prize-redefining-raffles-with-blockchain-tbt107752.html">
              <img src="/Images/thebittimes.png" alt="" />
            </a>
          </div>

          <div className="sm:grid grid-cols-2 hidden place-content-center place-items-center mx-auto h-[80px]">
            <img src="/Images/bitcoinist.png" alt="" />
            <a href="https://crypto.news/the-prize-redefining-raffles-with-blockchain/">
              <img src="/Images/cryptonews.png" alt="" />
            </a>
          </div>

          <div className="h-[4%] sm:block hidden "></div>
          <div className="sm:grid grid-cols-1 hidden place-content-center place-items-center mx-auto h-[80px] w-[50%]">
            <a
              href="https://u.today/press-releases/the-prize-redefining-raffles-with-blockchain"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="mx-auto w-[100%] h-[100%] sm:block hidden"
                src="/Images/Featuredin_mobile_3.png"
                alt=""
              />
            </a>
          </div>
          {/*                     <Text
                        textSize={[10, 10, 19.79]}
                        className="font-[MontBlancRegular] block text-white text-center mt-[-35] font-[20px] sm:mt-[1%]"
                    >
                        PRESS ARTICLES COMING SOON
                    </Text> */}
        </div>

        {/* Quote */}
        <div className="xl:min-h-[120px] bg-primary flex flex-col  justify-between items-center //absolute w-full //bottom-[-150px]  -z-10 rounded-b-[30px] h-full">
          <div className="w-full bg-[#1A1A1A]  h-[15px] rounded-b-[30px]" />
          <VSpacer small={25} big={5} />
          <Text
            textSize={[0, 15, 22.59]}
            className="text-center font-[Sequel100Wide95] pb-[30.07px] px-[22px]"
          >
            “A DOLLAR WON IS TWICE AS SWEET AS A DOLLAR EARNED”
            <span className=" font-[Sequel100Wide-65]">- PAUL NEWMAN</span>
          </Text>
          <img
            src="/Images/paulNewman.png"
            alt=""
            className="absolute w-[50rem] sm:bottom-[1rem] bottom-[-4rem] -z-1 opacity-10"
          />
        </div>
      </div>
    )
  );
}
