import { useEffect, useState } from "react";
import useTrustpilot from "./useTrustpilot"; // Import the custom hook
import { CircularProgress } from "@mui/material";

declare global {
  interface Window {
    Trustpilot?: {
      loadFromElement: (element: HTMLElement, trigger: boolean) => void;
    };
  }
}

const TrustBoxContainerHero = ({ isMobile }: { isMobile?: boolean }) => {
  const [trustpilotLoaded, setTrustpilotLoaded] = useState(false);
  useTrustpilot(); // Ensure the widget reloads on navigation

  useEffect(() => {
    const scriptSrc = "/trustpilot.js";

    const loadTrustpilotScript = () => {
      if (!document.querySelector(`script[src="${scriptSrc}"]`)) {
        const script = document.createElement("script");
        script.src = scriptSrc;
        script.async = true;
        script.onload = () => {
          setTrustpilotLoaded(true);
        };
        document.body.appendChild(script);
      } else {
        setTrustpilotLoaded(true); // Script already exists, just reinitialize
      }
    };

    loadTrustpilotScript();
  }, []);

  return trustpilotLoaded ? (
    <div
      key={isMobile ? "mobile" : "desktop"}
      className="trustpilot-widget"
      data-locale="en-GB"
      data-template-id={"5419b6ffb0d04a076446a9af"}
      data-businessunit-id="6756b5e9e34ca87255d4b63e"
      data-style-height={"22px"}
      data-style-width="100%"
      data-theme="dark"
    >
      <a
        href="https://uk.trustpilot.com/review/theprize.io"
        target="_blank"
        rel="noopener"
      >
        Trustpilot
      </a>
    </div>
  ) : (
    <CircularProgress />
  );
};

export default TrustBoxContainerHero;
