/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";

const getEthereumSignature = async (body: {
  chain: string;
  contractAddress: string;
  userWalletAddress: string;
}) => {
  try {
    const response = await axios.post("/getethereumsignature", body);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getBaseSignature = async (body: {
  chain: string;
  contractAddress: string;
  userWalletAddress: string;
  paymentTokenAddress: string;
  payment: string;
}) => {
  try {
    const response = await axios.post("/getethereumsignature", body);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getTronSignature = async (body: {
  contractAddress: string;
  userWalletAddress: string;
  paymentTokenAddress: string;
  payment: number;
}) => {
  try {
    const response = await axios.post("/gettronsignature", body);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getUnipaymentAccessToken = async () => {
  const encodedParams = new URLSearchParams();
  encodedParams.set("grant_type", "client_credentials");
  encodedParams.set(
    "client_id",
    process.env.REACT_APP_UNIPAYMENT_CLIENT_ID as string
  ); // remember to hide this in .env
  encodedParams.set(
    "client_secret",
    process.env.REACT_APP_UNIPAYMENT_CLIENT_SECRET as string
  ); // remember to hide this in .env

  const options = {
    method: "POST",
    url: "https://api.unipayment.io/connect/token",
    headers: {
      accept: "application/json",
      "content-type": "application/x-www-form-urlencoded",
    },
    data: encodedParams,
  };

  // axios
  //   .request(options)
  //   .then((res) => console.log(res.data?.access_token))
  //   .catch((err) => console.error(err));
  const response = await axios.request(options);
  const accessToken = response.data?.access_token;
  console.log(accessToken);
  return accessToken;
};

const getNowPaymentInvoiceLink = async ({
  price_amount,
}: {
  price_amount: number;
}) => {
  // Create properly typed data object
  type DataObjectType = {
    price_amount: number;
    order_id: string;
    pay_currency?: string;
    price_currency: string; // Optional property
    //order_description?: string;
  };

  const dataObj: DataObjectType = {
    price_amount: price_amount,
    order_id: `ORDER_${Math.floor(Math.random() * 1000000)}`,
    price_currency: "USD",
    //order_description: "theprize.io",
  };
  
  // Add price_currency only if price_amount is >= 25
  if (price_amount >= 25) {
    dataObj.pay_currency = "USD";
  }

  const options = {
    method: "POST",
    url: "https://api.nowpayments.io/v1/invoice",
    headers: {
      accept: "application/json",
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_NOWPAYMENTS_API_KEY,
    },
    data: dataObj,
  };
  
  const response = await axios.request(options);
  console.log("response of invoice link: ", response.data);
  const url = response?.data?.invoice_url;
  const invoiceId = response?.data?.order_id;
  console.log("url for invoice::::", url);
  return { url, invoiceId };
};

const getUnipaymentInvoiceLink = async ({
  price_amount,
}: {
  price_amount: number;
}) => {
  const options = {
    method: "POST",
    url: "https://api.unipayment.io/v1.0/invoices",
    headers: {
      accept: "application/json",
      "content-type": "application/json",
      authorization: `Bearer ${await getUnipaymentAccessToken()}`,
    },
    data: {
      host_to_host_mode: false,
      // notify_url: "https://example.com/payment/callback",
      // redirect_url: "http://example.com/pay/result",
      order_id: `ORDER_${Math.floor(Math.random() * 1000000)}`,
      title: "theprize.io",
      app_id: process.env.REACT_APP_UNIPAYMENT_APP_ID,
      price_amount: price_amount,
      price_currency: "USD",
    },
  };
  const response = await axios.request(options);
  console.log("response of invoice link: ", response.data);
  const url = response?.data?.data?.invoice_url;
  const invoiceId = response?.data?.data?.invoice_id;
  console.log("url for invoice::::", url);
  return { url, invoiceId };
};

export {
  getEthereumSignature,
  getTronSignature,
  getBaseSignature,
  getUnipaymentAccessToken,
  getUnipaymentInvoiceLink,
  getNowPaymentInvoiceLink,
};
