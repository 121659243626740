import VSpacer from "../../common/Spacer/VSpacer";
import { tableData } from "./FinishedCompetitionDetails";
import TableSmallTitle from "./texts/TableSmallTitle";
import TableSmallValue from "./texts/TableSmallValue";

const Data = ({
  TXHash,
  Min,
  Max,
  Result,
  WinningNumber,
  winnerTxHash,
}: tableData) => (
  <>
    <tr>
      <th className="text-left w-full">
        <TableSmallTitle text="TX HASH" />
      </th>
      <td
        className="text-right w-full"
        style={{
          color: TXHash === winnerTxHash ? "#FFD700" : "#FFFFFF",
        }}
      >
        <TableSmallValue
          text={TXHash}
          copyable
          isTXHash
          isWinner={TXHash === winnerTxHash}
        />
      </td>
    </tr>
    <tr>
      <th className="text-left w-full">
        <TableSmallTitle text="MIN" />
      </th>
      <td className="text-right w-full">
        <TableSmallValue
          text={Min?.toString()}
          isWinner={TXHash === winnerTxHash}
        />
      </td>
    </tr>
    <tr>
      <th className="text-left w-full">
        <TableSmallTitle text="MAX" />
      </th>
      <td className="text-right w-full">
        <TableSmallValue
          text={Max?.toString()}
          isWinner={TXHash === winnerTxHash}
        />
      </td>
    </tr>
    <tr>
      <th className="text-left w-full">
        <TableSmallTitle text="WINNING NUMBER" />
      </th>
      <td className="text-right w-full">
        <TableSmallValue
          text={WinningNumber?.toString()}
          isWinner={TXHash === winnerTxHash}
        />
      </td>
    </tr>
    <tr>
      <th className="text-left w-full">
        <TableSmallTitle text="RESULT" />
      </th>
      <td className="text-right w-full">
        <TableSmallValue
          text={Result?.toString()}
          isWinner={TXHash === winnerTxHash}
        />
      </td>
    </tr>
    <VSpacer treatLgAsSmall big={0} small={29} />
  </>
);
export default function TableSmall({ tableData }: { tableData: tableData[] }) {
  return (
    <table className="hidden sm_md_lg:block w-full px-[29px]">
      {/* <Data
        walletAddress="ASKCJA28189SQWERTYQWERTYQWERTYQW"
        txHash="ASKCJA28189SQWERTYQWERTYQWERTYQWASKCJA28189SQWERTYQWERTYQWERTYQWqw"
        min="1"
        max="999,999,999,999"
        winningNumber="432,329,000"
      /> */}
      {tableData?.map((data, index) => (
        <Data
          key={index}
          TXHash={data?.TXHash}
          Min={data?.Min}
          Max={data?.Max}
          Result={data?.Result}
          WinningNumber={data?.WinningNumber}
          winnerTxHash={data?.winnerTxHash}
        />
      ))}
      <hr className="m-auto  border-0 h-[3px] w-full bg-primary" />
    </table>
  );
}
