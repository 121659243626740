import * as React from "react";
import { Box, LinearProgressProps } from "@mui/material";
import { styled, keyframes } from "@mui/material/styles";

const stripes = keyframes`
  0% { background-position: 0px 0%; }
  50% { background-position: -100px 0%; }
  100% { background-position: -200px 0%; }
`;

const CompetitionStripesStyles = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "999%",
  backgroundImage: `repeating-linear-gradient(
      -45deg, 
      #f00090 0px,
      #f00090 12px,
      #f00090 12px,  
      #ffffff 12px,
      #ffffff 20px,
      #f00090 0px,
      #f00090 16px,  
      #ffffff 16px,
      #ffffff 20px,
      #f00090 0px
    )`,
  backgroundSize: "300% 100%",
  animation: `${stripes} 2s linear infinite`,
}));

const CompetitionStripes: React.FC<LinearProgressProps> = (props) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        borderRadius: "999px",
        overflow: "hidden",
      }}
    >
      <CompetitionStripesStyles {...props} />
    </Box>
  );
};

export default CompetitionStripes;
