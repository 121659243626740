import { ComponentType, useContext, useEffect, useState } from "react";
import { Loader } from "../Components/common/loader/Loader";
import Footer from "../Components/Footer";
import { PageLinkProps } from "../Components/Link";
import Navbar from "../Components/Navbar";
import WalletConnectPopup from "../Components/others/WalletConnectPopup/WalletConnectPopup";
import OverlayLoader from "../Components/OverlayLoader/OverlayLoader";
import { supportedWalletProviders } from "../Config/blockchain.config";
import { ErrorContext } from "../Contexts/ErrorContext";
import { useWallet } from "../Contexts/WalletContext";

type WalletConnector = Array<{
  id: number;
  icon: string;
  title: string;
  key: string;
  label: string;
  newTitle: string;
  buttuonHandler: (provider: string, network: string) => Promise<void>;
}>;

const WithNavbarAndFooter = <Props extends object>(
  WrappedComponent: ComponentType<Props>
) => {
  const ComponentWithNavbarAndFooter = (props: Props) => {
    const {
      isConnecting,
      setIsConnecting,
      connect,
      token,
      account,
      disconnect,
      network = "-1",
      setNetwork,
    } = useWallet();
    const [showWallet, setShowWallet] = useState(false);
    const [walletConnector, setWalletConnector] = useState<WalletConnector>([]);
    const { addError } = useContext(ErrorContext) || {};
    const storedToken = localStorage.getItem("token");
    const windowObj = window as any;
    const isPhantomBrowser = windowObj.phantom?.solana?.isPhantom;
    const isMetaMask = windowObj.ethereum?.isMetaMask;
    const isMobileBrowser = () => {
      return (
        navigator.maxTouchPoints > 0 &&
        /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent)
      );
    };

    const toSentenceCase = (text: string) =>
      text.charAt(0).toUpperCase() + text.slice(1);

    const connectWalletFunction = (showState: boolean) => {
      setShowWallet(showState);
    };

    const connectToWallet = async (provider: string, network: string) => {
      try {
        await connect(provider, network);
      } catch (err) {
        console.log(err, "error in navbar");
        addError?.(`Error reconnecting: ${(err as Error).message}`);
      }
    };

    const quickLinks: PageLinkProps[] = [
      {
        src: "/",
        content: "Home",
      },
      {
        src: "/competitions/all",
        content: "Competitions",
      },
      {
        src: "/howtoplay",
        content: "How to Play",
      },
      {
        src: "/winners",
        content: "Winners",
      },
      {
        src: "/rng",
        content: "RNG",
      },
      {
        src: "/howtoplay#faqs",
        content: "faq",
      },
    ];

    const bottomLinks: PageLinkProps[] = [
      {
        src: "/policies/cookies",
        content: "Cookie Policy",
      },
      {
        src: "/policies/privacy",
        content: "Privacy Policy",
      },
      {
        src: "/policies/t&c",
        content: "Terms & Conditions",
      },
      {
        src: "/policies/termsofuse",
        content: "Terms of Use",
      },
      {
        src: "/policies/acceptableuse",
        content: "Acceptable Use Policy",
      },
    ];

    const walletConnectorList = [
      {
        id: 1,
        icon: "/Images/metamask.svg",
        title: "METAMASK",
        key: "metamask",
        label: "MetaMask",
        newTitle: "",
        buttuonHandler: connectToWallet,
      },
      {
        id: 2,
        icon: "/Images/phantom.svg",
        title: "PHANTOM",
        key: "phantom",
        label: "Phantom",
        newTitle: "",
        buttuonHandler: connectToWallet,
      },
      {
        id: 3,
        icon: "/Images/walletconnect.svg",
        title: "WALLET CONNECT",
        key: "walletconnect",
        label: "WalletConnect",
        newTitle: "",
        buttuonHandler: connectToWallet,
      },
    ];

    const dynamicWalletList = (network: string) => {
      if (network === "ethereum") {
        return walletConnectorList;
      } else if (network === "base") {
        return walletConnectorList;
      } else if (network === "solana") {
        // const windowObj = window as any;

        // const isPhantomBrowser = windowObj.phantom?.solana?.isPhantom;
        // if (
        //   isPhantomBrowser &&
        //   /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        //     navigator.userAgent
        //   )
        // ) {
        //   return walletConnectorList.filter((i) => i.key === "phantom");
        // }
        return walletConnectorList.filter(
          (i) => i.key === "phantom" || i.key === "walletconnect"
        );
      } else if (network === "tron") {
        return walletConnectorList.filter((i) => i.key === "walletconnect");
      } else if (network === "btc") {
        return walletConnectorList.filter((i) => i.key === "phantom");
      } else if (network === "") {
        return [];
      } else {
        return walletConnectorList?.filter(
          (i) => i.key === "phantom" || i.key === "walletconnect"
        );
      }
    };

    useEffect(() => {
      if (isMobileBrowser()) {
        setWalletConnector([
          walletConnectorList[0],
          walletConnectorList[1],
          walletConnectorList[2],
        ]);
        if (isMetaMask || isPhantomBrowser) {
          setShowWallet(true);
        }
      } else {
        const filteredWalletProviders = walletConnectorList.filter((i) =>
          supportedWalletProviders.includes(i.key)
        );
        setWalletConnector([...filteredWalletProviders]);
      }
    }, []);

    return (
      <>
        <div className="w-[100%] flex xl:justify-end sm_md_lg:justify-center relative z-[81]">
          {/* <div
              style={{ zIndex: 88 }}
              className="flex flex-wrap flex-col items-start absolute bg-primary xl:mr-[5em] sm_md_lg:mx-[1%] xl:mt-[10em] sm_md_lg:mt-[27em] xl:pb-[10px] text-lg font-semibold font-[MontBlancBold] xl:w-[432px] sm_md_lg:w-[95%] h-auto rounded-[20px] z-30 shadow-[6px_6px_16px_rgba(0,0,0,1)]"
            >
              <div className="flex w-full flex-wrap justify-center">
                <p className="text-black font-['Sequel100Wide85'] uppercase text-xl my-[17px]">
                  CONNECT WALLET
                </p>
              </div>
              <hr className="w-[80%] mx-auto border-b-[3px] border-t-0 border-black rounded-full" />

              {!token ? (
                <>
                  <div className="w-full px-[30px] mt-[10px]">
                    <label className="text-black text-[18px] font-['Sequel100Wide85'] mb-[5px] block">
                      Select Network
                    </label>
                    <select
                      defaultValue=""
                      className="w-full bg-white border border-black rounded-full h-[40px] px-4 text-black text-[14px] font-['Sequel100Wide85'] focus:outline-none focus:ring-2 focus:ring-black"
                      onChange={(e) => setNetwork(e.target?.value)}
                    >
                      <option value="" disabled>
                        Select a network
                      </option>
                      {supportedNetworks.map((networkName, i) => (
                        <option key={i} value={networkName}>
                          {toSentenceCase(networkName)}
                        </option>
                      ))}
                    </select>
                    <p className="text-[0.8rem] text-center w-full">
                      Please ensure you have selected the correct network inside
                      your wallet
                    </p>
                  </div>

                  {dynamicWalletList(network || "")?.map((item, index) => (
                    <>
                      <div
                        key={item.id}
                        className="flex flex-row items-start justify-start ml-[30px] gap-x-[20px] my-[17px]"
                      >
                        <img
                          src={item.icon}
                          className="h-[50px] w-[70px]"
                          alt=""
                        />
                        <div className="flex flex-col items-start justify-center gap-1">
                          <p className="text-[18px] font-['Sequel100Wide85'] text-black">
                            {item.title}
                          </p>

                          {item.newTitle == "" ? (
                            <button
                              disabled={false}
                              className={
                                "bg-primary border-white border-[2px] text-black h-[29px] w-[143px] text-[12px] font-['Sequel100Wide85'] rounded-full shadow-[3px_3px_6px_rgba(0,0,0,0.5)] //custom-shadow"
                              }
                              onClick={() => {
                                if (!network) {
                                  alert(
                                    "Please select a network to connect to"
                                  );
                                  return;
                                }
                                item.buttuonHandler(
                                  item.key,
                                  network ?? "ethereum"
                                );
                                setShowWallet(false);
                              }}
                            >
                              CONNECT
                            </button>
                          ) : (
                            <div className="bg-primary border-white border-[2px] text-black h-[29px] w-[143px] text-center text-[12px] font-['Sequel100Wide85'] rounded-full custom-shadow">
                              {item.newTitle}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  ))}
                </>
              ) : (
                account
              )}

              {token && (
                <button style={{ margin: "0 auto" }} onClick={disconnect}>
                  Disconnect
                </button>
              )}
            </div> */}
          {!token ? (
            <WalletConnectPopup
              showWallet={showWallet}
              selectedNetwork={network || ""}
              setSelectedNetwork={setNetwork as any}
              dynamicWalletList={dynamicWalletList}
              connectWalletFunction={connectWalletFunction}
            />
          ) : (
            // <button style={{ margin: "0 auto" }} onClick={disconnect}>
            //   Disconnect
            // </button>
            <></>
          )}
        </div>

        <Navbar
          connectWalletFunction={connectWalletFunction}
          showConnectWallet={showWallet}
          // account={account}
        />
        {storedToken && !token && (
          <OverlayLoader zIndex={9999} message="Reconnecting to wallet" />
        )}
        {!storedToken && !token && (
          // <PopupWithIcon
          //   open={isConnecting}
          //   onClose={() => {
          //     setIsConnecting(false);
          //   }}
          //   children={
          //     <>
          //       <Common.typography
          //         fontFamily="Sequel100Wide95"
          //         fontSize="20px"
          //         fontSizeSmall="20px"
          //         color="#fff"
          //         className="uppercase text-center"
          //       >
          //         CONNECT A WALLET
          //       </Common.typography>
          //       <hr className="max-w-[478.99px] m-auto w-full mt-4 mb-6 border-[1px] border-[#fff] " />
          //       <div className="w-full flex flex-col flex-1 gap-4 items-center justify-center">
          //         <div className="flex flex-col items-center mt-5">
          //           {/* animate image it to spining */}
          //           <img
          //             className="animate-spin"
          //             src={"/Images/Loader.svg"}
          //             alt={""}
          //           />
          //         </div>
          //         <Common.typography
          //           fontFamily="Sequel100Wide95"
          //           fontSize="22px"
          //           fontSizeSmall="22px"
          //           color="#fff"
          //           className="uppercase text-center"
          //         >
          //           WE’RE WORKING ON IT
          //         </Common.typography>
          //       </div>
          //     </>
          //   }
          // ></PopupWithIcon>
          <Loader
            onClose={() => {
              setIsConnecting(false);
            }}
            showCloseButton={true}
            show={isConnecting}
            upperComponent={<></>}
          />
          // <Loader
          //   upperComponent={
          //     <div className="flex flex-col items-center mb-5">
          //       <h2 className="text-white text-[2rem] font-black">
          //         NEARLY THERE
          //       </h2>
          //     </div>
          //   }
          //   show={isConnecting}
          //   lowerComponent={
          //     <div className="flex flex-col items-center mt-5">
          //       <p className="text-white text-2xl font-semibold text-left">
          //         Step 1: Connect your Wallet to our Application
          //       </p>
          //       <p className="text-white text-2xl font-semibold text-left">
          //         Step 2: Accept the Signature Request in your Wallet
          //       </p>
          //       <p className="text-white text-2xl font-semibold text-left">
          //         Step 3: Wait for your account to load
          //       </p>
          //     </div>
          //   }
          //   // message="Wallet is connecting, please check your wallet for signing request."
          // />
        )}
        <WrappedComponent
          {...(props as Props)}
          connectWalletFunction={connectWalletFunction}
        />
        <Footer quickLinks={quickLinks} bottomLinks={bottomLinks} />
      </>
    );
  };

  ComponentWithNavbarAndFooter.displayName = `WithNavbarAndFooter(${
    WrappedComponent.displayName || WrappedComponent.name || "Component"
  })`;

  return ComponentWithNavbarAndFooter;
};

export default WithNavbarAndFooter;
