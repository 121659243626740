import React from "react";
import VSpacer from "../../common/Spacer/VSpacer";
import LeftContainer from "./Containers/LeftContainer";
import MainContainer from "./Containers/MainContainer";
import RightContainer from "./Containers/RightContainer";
import WinnerContainer from "./Containers/WinnerContainer";
import WinnerItem from "./others/WinnerItem";
import Description from "./Typography/Description";
import Title from "./Typography/Title";
import { IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { copyToClipboard } from "../../../utils/CopyToClipboard";
import "./PrizeCardNew.css";
import { Common } from "../../common/Common";

interface PrizeCardProps {
  title: string;
  description: string;
  image: string;
  date?: string;
  winningTicket?: string;
  winner?: string;
}
export default function PrizeCardNew(props: PrizeCardProps) {
  return (
    <div className={`${props?.winner ? "prizeCardWonBox" : "prizeCardBox"}`}>
      <div className="flex flex-row w-[170px] xs_sm:w-[80px] h-[100%] xl:translate-y-[0.5rem] lg:translate-y-[0.5rem] sm_md:translate-y-[0.1rem] items-center justify-center">
        <Common.typography
          fontFamily="Sequel100Wide95"
          fontSize="18px"
          fontSizeSmall="14px"
          color="#fff"
          className={`uppercase text-center`}
        >
          {props?.winningTicket}
        </Common.typography>
      </div>
    </div>
    // <MainContainer
    //   varient={
    //     props?.date && props?.date < new Date().toISOString()
    //       ? "winner"
    //       : "normal"
    //   }
    //   left={
    //     <LeftContainer>
    //       <img
    //         className="h-full w-full object-cover absolute top-0 left-0"
    //         src={props?.image}
    //         // load "/Images/No Image.svg" if image is not loaded
    //         onError={(e) => {
    //           e.currentTarget.src = "/Images/No Image.svg";
    //         }}
    //         onErrorCapture={(e) => {
    //           e.currentTarget.src = "/Images/No Image.svg";
    //         }}
    //         alt="placeholder"
    //       />
    //     </LeftContainer>
    //   }
    //   right={
    //     <RightContainer>
    //       {props?.date && props?.date < new Date().toISOString() ? (
    //         <VSpacer big={16.52} small={12.43} />
    //       ) : (
    //         <VSpacer big={34.71} small={15.26} />
    //       )}
    //       <Title title={props?.title} fontSizeSmall={"12px"} />
    //       <VSpacer big={9.73} small={5.21} />
    //       <Description
    //         description={
    //           props?.description.length > 100
    //             ? props?.description.slice(0, 100) + "..."
    //             : props?.description
    //         }
    //       />
    //       <VSpacer big={14.86} small={11.88} />
    //       {props?.date &&
    //         props?.date < new Date().toISOString() &&
    //         props?.winningTicket && (
    //           <WinnerContainer>
    //             <WinnerItem
    //               itemType="ticket"
    //               itemValue={props?.winningTicket}
    //             />
    //             <WinnerItem
    //               itemType="winner"
    //               itemValue={
    //                 props?.winner?.length && props?.winner?.length > 8
    //                   ? props?.winner?.slice(0, 8) + ".."
    //                   : props?.winner || "N/A"
    //               }
    //             />
    //             <div className="flex gap-1 min-w-0">
    //               <IconButton
    //                 onClick={() => {
    //                   // navigator.clipboard.writeText(text);
    //                   copyToClipboard(props?.winner ?? "");
    //                 }}
    //                 className="text-white"
    //                 style={{ marginTop: "10px" }}
    //               >
    //                 <ContentCopyIcon className="text-white" />
    //               </IconButton>
    //             </div>
    //           </WinnerContainer>
    //         )}
    //     </RightContainer>
    //   }
    // />
  );
}
