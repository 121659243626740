import React, { useEffect, useState } from "react";
import VSpacer from "./common/Spacer/VSpacer";
import { Text } from "./react-responsive-utilities/src";
import {
  buySelectedTickets,
  getAvailableTickets,
  getWallet,
  joinCompetition,
} from "../Services/userService";
import { set } from "lodash";
import { CircularProgress } from "@mui/material";
import BuyTicketsPopup from "./Dashboard Components/BuyTicketsPopup";
import HSpacer from "./common/Spacer/HSpacer";
import PopupPrizes, {
  PopupPrizeData,
} from "./Page Components/PopupPrizes/PopupPrizes";
import { useAuth } from "../Contexts/AuthContext";
import { useWallet } from "../Contexts/WalletContext";
import { useNavigate } from "react-router-dom";
import { openGlobalModal } from "./Page Components/ClipboardModalContext";
import PaymentModal from "./Page Components/PaymentModal";
import PopupWithIcon from "./common/PopupWithIcon/PopupWithIcon";
import WalletConnectPopup from "./others/WalletConnectPopup/WalletConnectPopup";

export interface TicketData {
  Ticket: number;
  Name: string | null;
  Date: string | null;
}

export interface TicketResponse {
  data: TicketData[];
  currentPage: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

export const NumberPickerNew = ({
  connectWalletFunction,
  minimumTickets = 0,
  maximumTickets = 0,
  competitionID,
  competition,
  isMaxReached,
  getCompetition,
}: {
  connectWalletFunction?: any;
  competitionID: string;
  minimumTickets: number;
  maximumTickets?: number;
  competition: any;
  isMaxReached: boolean;
  getCompetition: (competitonId: string) => void;
}): JSX.Element => {
  const enterNowButtonRef = React.useRef<HTMLButtonElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showMinimumPopup, setShowMinimumPopup] = useState<boolean>(false);
  // const competitionId = window.location.pathname.split("/")[4];

  const [isSelectedMoreThanMinimum, setIsSelectedMoreThanMinimum] =
    useState(false);
  const [isSelectedMoreThanMaximum, setIsSelectedMoreThanMaximum] =
    useState(false);
  const competitionId = competitionID;
  const [iteration, setIteration] = useState(1);
  const [pageContents, setPageContents] = useState<TicketResponse[]>([]);
  const [displayedPageContents, setDisplayedPageContents] = useState<
    TicketResponse[]
  >([]);
  const [displayedPageContentsSmall, setDisplayedPageContentsSmall] =
    useState<TicketResponse[]>();
  const [isInsufficientAmount, setIsInsufficientAmount] = useState(false);
  const [isFull, setIsFull] = useState(false);
  const [value, setValue] = useState(minimumTickets);
  const [error, setError] = useState("");
  const initialCurrentPageContents: TicketResponse = {
    data: [],
    currentPage: 0,
    pageSize: 0,
    totalItems: 0,
    totalPages: 0,
  };
  const [currentPageContents, setCurrentPageContents] =
    useState<TicketResponse>(initialCurrentPageContents);
  const [currentPageContentsSmall, setCurrentPageContentsSmall] =
    useState<TicketResponse>(initialCurrentPageContents);
  const [items, setItems] = useState<number[]>([]);
  const [buyTicketsPopup, setBuyTicketsPopup] = useState(false);
  const [noOfTicket, setNoOfTicket] = useState(0);
  const [buying, setBuying] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const { token } = useWallet();

  useEffect(() => {
    if (
      competition?.totalticketsbought === competition?.competitionticketsize
    ) {
      setIsFull(true);
    }
  }, [competition]);

  const fetchTickets = async (startFrom: number) => {
    setLoading(true);
    const response1 = await getAvailableTickets(competitionId, {
      page: startFrom,
      limit: 100,
    });
    const response2 = await getAvailableTickets(competitionId, {
      page: startFrom + 1,
      limit: 100,
    });
    const response3 = await getAvailableTickets(competitionId, {
      page: startFrom + 2,
      limit: 100,
    });
    const response4 = await getAvailableTickets(competitionId, {
      page: startFrom + 3,
      limit: 100,
    });
    const response5 = await getAvailableTickets(competitionId, {
      page: startFrom + 4,
      limit: 100,
    });
    const response6 = await getAvailableTickets(competitionId, {
      page: startFrom + 5,
      limit: 100,
    });
    setPageContents([
      response1.data,
      response2.data?.data?.length > 0 ? response2.data : null,
      response3.data?.data?.length > 0 ? response3.data : null,
      response4.data?.data?.length > 0 ? response4.data : null,
      response5.data?.data?.length > 0 ? response5.data : null,
      response6.data?.data?.length > 0 ? response6.data : null,
    ]);
    if (currentPageContents === initialCurrentPageContents)
      setCurrentPageContents(response1?.data);
    if (currentPageContentsSmall === initialCurrentPageContents)
      setCurrentPageContentsSmall(response1?.data);
    console.log("Page function called " + response1?.data);
    setCurrentPageContents(response1?.data);
    setCurrentPageContentsSmall(response1?.data);
    setLoading(false);
  };

  useEffect(() => {
    if (iteration === 1) {
      fetchTickets(1);
    } else fetchTickets(5 * iteration - 5);
  }, [iteration]);

  // useEffect(() => {
  //     console.log("currentPageContents:", currentPageContents);
  //     console.log("items:", items);
  //     console.log("displayedPageContents:", displayedPageContents);
  // }, [currentPageContents, items, displayedPageContents]);

  useEffect(() => {
    if (pageContents.length > 0) {
      setDisplayedPageContents(pageContents.slice(0, 5));
      setDisplayedPageContentsSmall(pageContents?.slice(0, 6));
    }
  }, [pageContents]);

  const handleNext = () => {
    if (
      displayedPageContents?.indexOf(currentPageContents) + 1 ===
      displayedPageContents?.length
    ) {
      const isLastIteration = displayedPageContents
        ?.map((page) => page.totalPages)
        .includes(iteration);
      if (isLastIteration) {
        return;
      }
      setIteration(iteration + 1);
    } else {
      const currentPageIndex =
        displayedPageContents?.indexOf(currentPageContents);
      setCurrentPageContents(displayedPageContents[currentPageIndex + 1]);
    }
  };

  const handleNextSmall = () => {
    if (
      (displayedPageContentsSmall?.indexOf(currentPageContentsSmall) ?? -1) +
        1 ===
      displayedPageContentsSmall?.length
    ) {
      const isLastIteration = displayedPageContentsSmall
        ?.map((page) => page.totalPages)
        .includes(iteration);
      if (isLastIteration) {
        return;
      }
      setIteration(iteration + 1);
    } else {
      const currentPageIndex = displayedPageContentsSmall?.indexOf(
        currentPageContentsSmall
      );
      if (currentPageIndex !== undefined) {
        setCurrentPageContentsSmall(
          displayedPageContentsSmall?.[currentPageIndex + 1] ??
            currentPageContentsSmall
        );
      }
    }
  };

  const { user, account } = useWallet();

  const navigate = useNavigate();

  const handleIsInsufficientTickets = () => {
    console.log("user:", user, competition, items, value, minimumTickets);
    if (user && competition) {
      if (user?.numberOfTickets === competition?.maxticketsperuser) {
        setIsInsufficientAmount(true);
        if (enterNowButtonRef.current) {
          enterNowButtonRef.current.disabled = true;
          enterNowButtonRef.current.style.backgroundColor = "#B6B6B6";
        }
      } else if (user?.numberOfTickets === 0) {
        setIsInsufficientAmount(true);
        if (enterNowButtonRef.current) {
          enterNowButtonRef.current.disabled = true;
          enterNowButtonRef.current.style.backgroundColor = "#B6B6B6";
        }
      } else if (user?.numberOfTickets < minimumTickets) {
        setIsInsufficientAmount(true);
        if (enterNowButtonRef.current) {
          enterNowButtonRef.current.disabled = true;
          enterNowButtonRef.current.style.backgroundColor = "#B6B6B6";
        }
      } else if (items?.length > maximumTickets) {
        setIsInsufficientAmount(true);
        if (enterNowButtonRef.current) {
          enterNowButtonRef.current.disabled = true;
          enterNowButtonRef.current.style.backgroundColor = "#B6B6B6";
        }
      } else {
        setIsInsufficientAmount(false);
        if (enterNowButtonRef.current) {
          enterNowButtonRef.current.removeAttribute("disabled");
          enterNowButtonRef.current.removeAttribute("style");
        }
      }
    }
  };

  useEffect(() => {
    handleIsInsufficientTickets();
  }, [items, user, value, competition, minimumTickets]);

  const handleIsSelectedMoreThanMinimum = () => {
    if (items.length > minimumTickets) {
      setIsSelectedMoreThanMinimum(true);
    } else {
      setIsSelectedMoreThanMinimum(false);
    }
  };

  const handleIsSelectedMoreThanMaximum = () => {
    // if (items.length > maximumTickets) {
    //   setIsSelectedMoreThanMaximum(true);
    // } else {
    //   setIsSelectedMoreThanMaximum(false);
    // }
    if (
      items.length >
      Math.min(
        competition?.maxticketsperuser,
        competition?.competitionticketsize - competition?.totalticketsbought,
        competition?.maxticketsperuser - competition?.userTickets
      )
    ) {
      setIsSelectedMoreThanMaximum(true);
      if (enterNowButtonRef.current) {
        enterNowButtonRef.current.disabled = true;
        enterNowButtonRef.current.style.backgroundColor = "#B6B6B6";
      }
    } else {
      setIsSelectedMoreThanMaximum(false);
      enterNowButtonRef.current?.removeAttribute("disabled");
      enterNowButtonRef.current?.removeAttribute("style");
    }
  };

  useEffect(() => {
    handleIsSelectedMoreThanMinimum();
    handleIsSelectedMoreThanMaximum();
  }, [items]);

  const handlePrev = () => {
    if (displayedPageContents?.indexOf(currentPageContents) === 0) {
      if (iteration === 1) {
        return;
      }
      setIteration(iteration - 1);
    } else {
      const currentPageIndex =
        displayedPageContents?.indexOf(currentPageContents);
      setCurrentPageContents(displayedPageContents[currentPageIndex - 1]);
    }
  };

  const handlePrevSmall = () => {
    if (
      (displayedPageContentsSmall?.indexOf(currentPageContentsSmall) ?? -1) ===
      0
    ) {
      if (iteration === 1) {
        return;
      }
      setIteration(iteration - 1);
    } else {
      const currentPageIndex = displayedPageContentsSmall?.indexOf(
        currentPageContentsSmall
      );
      setCurrentPageContentsSmall(
        displayedPageContentsSmall?.[(currentPageIndex ?? 0) - 1] ??
          currentPageContentsSmall
      );
    }
  };

  const changePage = (page: TicketResponse) => {
    setCurrentPageContents(page);
  };

  const changePageSmall = (page: TicketResponse) => {
    setCurrentPageContentsSmall(page);
  };

  const addItem = (item: number) => {
    setItems([...items, item]);
  };

  const deleteItem = (num: number) => {
    setItems(items.filter((item) => item !== num));
  };

  const isUserSelected = (item: number) => {
    return items.includes(item);
  };

  const isCurrentPage = (page: TicketResponse) => {
    return page === currentPageContents;
  };

  const isCurrentPageSmall = (page: TicketResponse) => {
    return page === currentPageContentsSmall;
  };

  // const isCurrentPage = (page: TicketResponse) => {
  //     return (
  //         page?.data?.[0]?.Ticket === currentPageContents?.data?.[0]?.Ticket
  //     );
  // };

  const isPreSelected = (content: TicketData) => {
    return content?.Name !== null;
  };

  const [popupPrizes, setPopupPrizes] = useState(false);
  const [success, setSuccess] = useState("");
  const [openMcq, setOpenMcq] = useState(false);

  const [prizes, setPrizes] = useState<PopupPrizeData[]>([]);

  const checkout = async () => {
    // setOpenMcq(false);
    setLoading(true);
    try {
      const walletResponse = await getWallet();
      const response = await buySelectedTickets({
        competitionid: competitionId,
        tickets: items,
        walletaddress: account || "",
      });
      if (response?.message === "Successfully joined the competition") {
        console.log("Successfully joined the competition");
        if (response?.data?.length > 0) {
          setPopupPrizes(true);
          setPrizes(response?.data);
        } else {
          // window?.location.reload();
          setSuccess(response?.message);
          setTimeout(() => {
            setOpenMcq(false);
            setSuccess("");
            window?.location.reload();
          }, 3000);
        }
      } else if (response?.error?.length > 0) {
        // alert(response?.error);
        alert(response?.error);
      } else {
        alert(response?.message);
        console.log("Failed to buy tickets ");
      }
    } catch (error: any) {
      // alert(error?.response?.data?.error);
      setError(
        "Tickets: " +
          error?.response?.data?.unavailableTickets +
          " are already purchased by other users"
      );

      console.log("Failed to buy tickets ", error);
    }
    setLoading(false);
  };

  const closePopupPrizes = () => {
    window.location.reload();
    setPopupPrizes(false);
  };

  const handleMinimumPopup = () => {
    setShowMinimumPopup(true);
  };

  const pageContainer = () => {
    console.log("displayedPageContents:::::", displayedPageContents);

    return (
      <>
        <div className="sm:hidden ">
          <div className="flex flex-row  flex-wrap   gap-y-3 gap-x-2 justify-center mx-[1.5%]">
            {displayedPageContents?.map(
              (item, index) =>
                item?.data[0]?.Ticket && (
                  <div
                    key={index}
                    onClick={() => {
                      changePage(item);
                    }}
                    className={`xl:w-[225px] lg:w-[215px] md:w-[215px]  h-[47px] flex flex-wrap items-center justify-center rounded-[13px] border ${
                      isCurrentPage(item) ? "border-[#FFFFFF]" : "border-none"
                    } ${isCurrentPage(item) ? "bg-primary" : ""}`}
                    style={{
                      backgroundColor: !isCurrentPage(item)
                        ? "rgba(255, 255, 255, 0.15)"
                        : undefined,
                    }}
                  >
                    <p
                      className={`font-['Sequel100Wide95'] text-[12px] font-normal ${
                        isCurrentPage(item)
                          ? "text-[#280508]"
                          : "text-[#FFFFFF]"
                      }`}
                      style={{
                        fontSize: "clamp(1px, 2.815621311vw, 14.18px)",
                      }}
                    >
                      {item?.data[0]?.Ticket}-
                      {item?.data[item?.data.length - 1]?.Ticket}
                    </p>
                  </div>
                )
            )}
          </div>

          <div className="xl:h-[35px] lg: h-[35px]"></div>
          <div className="flex  flex-row align-center items-center justify-between mx-[1.5%]">
            <img
              //src="/Images/THEPRIZE_TokenLogo_EH-03.svg"
              src="/Images/left_arrow.svg"
              alt=""
              className="h-[21px] w-[16px] "
              onClick={() => handlePrev()}
            />
            <p className="font-['Sequel100WideVF45'] text-[20px] text-center text-white text-opacity-50 ">
              PAGE {currentPageContents && currentPageContents?.currentPage}
            </p>

            <img
              //src="/Images/THEPRIZE_TokenLogo_EH-03.svg"
              src="/Images/right_arrow.svg"
              alt=""
              onClick={() => handleNext()}
              className="h-[21px] w-[16px] "
            />
          </div>
        </div>
      </>
    );
  };

  const numContainer = (currentPageContents: TicketResponse) => {
    console.log("currentPageContents:", currentPageContents);
    return (
      <>
        <div className="xl:grid grid-cols-10 lg:flex flex flex-row flex-wrap gap-y-3 gap-x-2 justify-center ml-[10px] mr-[10px] sm:hidden">
          {Array?.isArray(currentPageContents?.data) &&
            currentPageContents?.data?.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  if (isMaxReached) return;
                  if (isPreSelected(item)) return;
                  if (isUserSelected(item?.Ticket)) {
                    deleteItem(item?.Ticket);
                  } else {
                    addItem(item?.Ticket);
                  }
                }}
                className={`relative m-auto max-w-[109px] min-w-0 w-full h-[48px] rounded-[13px] flex items-center justify-center overflow-hidden 
                      ${
                        isUserSelected(item?.Ticket)
                          ? "bg-primary"
                          : "bg-[rgba(255,255,255,0.15)]"
                      }  ${
                  !isPreSelected(item) ? "border border-[#DDE404]" : ""
                }
               ${isMaxReached ? "cursor-not-allowed" : ""}
              `}
              >
                {isPreSelected(item) && (
                  <div
                    className="absolute top-[14px] right-[16px] w-[0.5px] h-[170%] bg-white bg-opacity-50"
                    style={{
                      transform: "rotate(75deg)",
                      transformOrigin: "top right",
                    }}
                  ></div>
                )}

                <p
                  className={`font-['Sequel100Wide'] text-[16px] text-center 
                      ${
                        !isPreSelected(item) && !isUserSelected(item?.Ticket)
                          ? "text-[#DDE404]"
                          : isUserSelected(item?.Ticket)
                          ? "text-[#393939]"
                          : "text-[#280508]"
                      }`}
                >
                  {item?.Ticket}
                </p>
              </div>
            ))}
        </div>

        <div className="grid-cols-10 flex flex-row flex-wrap gap-y-3 gap-x-2 justify-center ml-[10px] mr-[10px] lg:hidden md:hidden xl:hidden">
          {Array?.isArray(currentPageContentsSmall?.data) &&
            currentPageContentsSmall?.data?.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  if (isMaxReached) return;
                  if (isPreSelected(item)) return;
                  if (isUserSelected(item?.Ticket)) {
                    deleteItem(item?.Ticket);
                  } else {
                    addItem(item?.Ticket);
                  }
                }}
                className={`relative m-auto max-w-[109px] min-w-0 w-full h-[48px] rounded-[13px] flex items-center justify-center overflow-hidden 
                      ${
                        isUserSelected(item?.Ticket)
                          ? "bg-primary"
                          : "bg-[rgba(255,255,255,0.15)]"
                      }  ${
                  !isPreSelected(item) ? "border border-[#DDE404]" : ""
                }
               ${isMaxReached ? "cursor-not-allowed" : ""}
              `}
              >
                {isPreSelected(item) && (
                  <div
                    className="absolute top-[14px] right-[16px] w-[0.5px] h-[170%] bg-white bg-opacity-50"
                    style={{
                      transform: "rotate(75deg)",
                      transformOrigin: "top right",
                    }}
                  ></div>
                )}

                <p
                  className={`font-['Sequel100Wide'] text-[16px] text-center 
                      ${
                        !isPreSelected(item) && !isUserSelected(item?.Ticket)
                          ? "text-[#DDE404]"
                          : isUserSelected(item?.Ticket)
                          ? "text-[#393939]"
                          : "text-[#280508]"
                      }`}
                >
                  {item?.Ticket}
                </p>
              </div>
            ))}
        </div>
      </>
    );
  };

  const renderCheckouts = () => {
    const text = "ENTER NOW";
    if (isFull) {
      return "COMPETITION IS FULL";
    } else if (isMaxReached) {
      return "LIMIT REACHED";
    } else if (isInsufficientAmount) {
      return "ENTER NOW";
    }
    return text;
  };

  const handleButtonClick = () => {
    // min !== 0 && !isInsufficientAmount && buyTicket();

    if (user && user.numberOfTickets < items.length) {
      if (items.length < minimumTickets) {
        if (competition?.userTickets < minimumTickets) {
          handleMinimumPopup();
        }
        return;
      }
      setBuyTicketsPopup(true);
    } else {
      buyTicket();
    }
  };

  const ticketSold = (
    totalticketsbought?: number,
    competitionticketsize?: number
  ) => {
    if (totalticketsbought && competitionticketsize) {
      const sold = ((totalticketsbought / competitionticketsize) * 100).toFixed(
        0
      );
      return sold;
    } else {
      return 0;
    }
  };

  const isNoTicketLeft = () => {
    if (
      Number(
        ticketSold(
          competition?.totalticketsbought,
          competition?.competitionticketsize
        )
      ) === 100
    ) {
      console.log("No ticket left");
      return true;
    } else {
      return false;
    }
  };

  const buyTicket = () => {
    {
      if (!token) {
        // scroll to top
        window.scrollTo(0, 0);
        if (connectWalletFunction) {
          connectWalletFunction(true);
        }
        // alert for login
        // if (openGlobalModal) {
        //   openGlobalModal({
        //     text: "Please connect your wallet to enter competitions.",
        //   });
        // }

        // alert("Please connect your wallet to enter competitions");
        return;
      } else {
        if (user && user.numberOfTickets < items.length) {
          setBuyTicketsPopup(true);
        } else setOpenMcq(true);
      }
      // if (items.length < minimumTickets) {
      //   return;
      // }
      // if (isInsufficientAmount && !isMaxReached) {
      //   navigate("/buytickets");
      // }
      // if (isSelectedMoreThanMaximum) {
      //   return;
      // }
      // if (isMaxReached) {
      //   return;
      // }
      // setOpenMcq(true);
    }
  };

  const buyTicketsPopupClose = () => {
    setBuyTicketsPopup(false);
  };

  useEffect(() => {
    if (isMaxReached) {
      if (enterNowButtonRef.current) {
        enterNowButtonRef.current.disabled = true;
        enterNowButtonRef.current.style.backgroundColor = "#B6B6B6";
      }
    }
  }, [isMaxReached]);

  return (
    <>
      <VSpacer big={34} small={34} />
      <div className="relative w-auto h-auto flex flex-col justify-center align-center items-center  bg-[#111111] //bg-[rgba(35,35,35,0.6)]  ml-[3%] mr-[3%] rounded-3xl">
        {loading ? (
          <CircularProgress
            color="primary"
            className="absolute z-50"
            size={50}
          />
        ) : null}
        <div className="xl:h-[35px] lg:h-[35px] md:h-[30px] sm:h-[25px]"></div>
        <PopupWithIcon
          showCloseButton
          open={showMinimumPopup}
          onClose={() => setShowMinimumPopup(false)}
        >
          <div className="text-center text-white p-8 font-[Sequel100Wide95]">
            The minimum number of tickets you can buy is {minimumTickets}
          </div>
        </PopupWithIcon>
        <Text
          textSize={[30, 30.0, 50.0]}
          className="text-primary text-center xl:font-['Sequel100Wide95'] lg:font-['Sequel100Wide95'] md:font-['Sequel100Wide'] sm:font-['Sequel100Wide']   xl:font-[60px] lg:font-[60px] md:font-[45px] sm:font-[30px]   "
        >
          NUMBER PICKER
        </Text>

        {/* Numer Picker Box */}

        <div className="xl:hidden lg:hidden md:hidden">
          <div className="//flex //flex-row grid grid-cols-1 sm_md_lg:grid-cols-2 //flex-wrap   gap-y-3 gap-x-2 justify-center ml-[20px] mr-[20px]">
            {displayedPageContentsSmall?.map(
              (item, index) =>
                item?.data[0]?.Ticket && (
                  <div
                    key={index}
                    // onClick={() => setCurrentPageContents(item)}
                    onClick={() => {
                      changePageSmall(item);
                    }}
                    className={`max-w-[200px] min-w-[148.29px] w-full h-[40px] flex flex-wrap items-center justify-center rounded-[5px] border ${
                      isCurrentPageSmall(item)
                        ? "border-[#FFFFFF]"
                        : "border-none"
                    } ${isCurrentPageSmall(item) ? "bg-primary" : ""} `}
                    style={{
                      backgroundColor: !isCurrentPageSmall(item)
                        ? "rgba(255, 255, 255, 0.15)"
                        : undefined,
                    }}
                  >
                    <p
                      className={`font-['Sequel100Wide-65'] text-[8px]  ${
                        isCurrentPageSmall(item)
                          ? "text-[#280508]"
                          : "text-[#FFFFFF]"
                      }`}
                      style={{
                        fontSize: "clamp(1px, 2.815621311vw, 14.18px)",
                      }}
                    >
                      {item?.data[0]?.Ticket}-
                      {item?.data[item?.data.length - 1]?.Ticket}
                    </p>
                  </div>
                )
            )}
          </div>

          <div className="xl:h-[58.65px] lg:h-[58.65px] md:h-[40px] sm:h-[25px]"></div>
          <div className="flex  flex-row align-center justify-center ml-[12px] mr-[12px]">
            <img
              src="/Images/left_arrow.svg"
              onClick={() => handlePrevSmall()}
              alt=""
              className="h-[21px] w-[16px] "
            />
            <p className="font-['Sequel100WideVF45'] text-[15px] text-center text-white text-opacity-50 ml-8 mr-8">
              PAGE{" "}
              {currentPageContentsSmall &&
                currentPageContentsSmall?.currentPage}
            </p>

            <img
              src="/Images/right_arrow.svg"
              alt=""
              onClick={() => handleNextSmall()}
              className="h-[21px] w-[16px] "
            />
          </div>
        </div>
        <div className="xl:h-[58.65px] lg:h-[58.65px] md:h-[40px] sm:h-[25px]"></div>

        <div
          className="              
  w-[90%] 
  border border-primary 
  rounded-3xl  
"
        >
          <div
            className="                        
  md:p-8 
  lg:p-8 
  xl:p-8 
  sm:p-0                      
  h-auto 
  overflow-hidden
  
"
          >
            {pageContainer()}

            <div className="xl:h-[25px] lg: h-[25px]"></div>

            <div className="flex flex-row justify-center"></div>
            <div
              className="overflow-y-auto
              max-h-[300px] 
              xl:max-h-[911px]
  custom-scrollbar"
            >
              {numContainer(currentPageContents as TicketResponse)}
            </div>
            <div className="xl:h-[20px] lg: h-[20px]"></div>

            <hr className="mx-auto  sm:w-[400px] w-full border-b-[1px] border-t-0 border-primary  sm:block md:block h-[3.14px] rounded-full" />

            <div className="xl:h-[27px] lg: h-[27px]"></div>

            <div className="flex flex-row  mx-[1.5%] justify-between xl:flex-nowrap gap-4 flex-wrap justify-center items-center">
              <div className="sm:w-full md:w-full lg:w-full xl:w-[710px] h-[76.21px] rounded-[13px] border border-primary flex items-center pl-[30px] pr-[30px] overflow-x-auto overflow-y-hidden">
                <p className="font-['Sequel100Wide'] text-[15px] text-left text-white text-opacity-50 font-normal whitespace-nowrap">
                  Selected Tickets: {items.map((item) => item).join(", ")}
                </p>
              </div>
              <button
                onClick={() => handleButtonClick()}
                ref={enterNowButtonRef}
                className={`
                  ${!token && "bg-[#dde406] !cursor-pointer"}
                  ${
                    items.length === 0 ||
                    items.length < 1 ||
                    items.length > maximumTickets
                      ? "bg-[#B6B6B6]"
                      : "bg-[#dde406] "
                  }
                    ${
                      isInsufficientAmount && !isMaxReached
                        ? " *:text-[20px] bg-[#dde406] !cursor-auto"
                        : ""
                    }
                    ${
                      isMaxReached
                        ? "cursor-not-allowed *:text-[20px] bg-[#B6B6B6]"
                        : ""
                    }
                    ${
                      isSelectedMoreThanMaximum
                        ? "cursor-not-allowed *:text-[20px] bg-[#B6B6B6]"
                        : ""
                    }
                  sm:mt-[15px] sm:mb-[15px] md:mt-[15px] md:mb-[15px] lg:mt-[15px] lg:mb-[15px] sm:w-full md:w-full lg:w-full xl:w-[421px]   h-[77.2px] rounded-[13px] flex items-center justify-center `}
              >
                <p className="text-[#000000] text-[29px] sm:text-[18px] font-['Sequel100Wide'] text-center">
                  {/* {isInsufficientAmount ? "INSUFFICIENT AMOUNT" : "CHECKOUT"} */}
                  {renderCheckouts()}
                </p>

                <HSpacer big={10} small={10} />
                {isFull || isMaxReached ? (
                  <></>
                ) : minimumTickets !== 0 &&
                  !isInsufficientAmount &&
                  // items.length <= minimumTickets ? (
                  items.length > competition?.minnumberoftickets ? (
                  <p>
                    ({items.length}/
                    {competition?.maxticketsperuser - competition?.userTickets})
                  </p>
                ) : parseInt(competition?.userTickets) <
                  competition?.minnumberoftickets ? (
                  <p>
                    {/* ({items.length}/{competition?.maxticketsperuser}) */}
                    {items.length}/
                    {user ? maximumTickets : competition?.competitionticketsize}
                  </p>
                ) : (
                  <p>
                    ({items.length}/
                    {/* {competition?.maxticketsperuser - competition?.userTickets} */}
                    {/* {Math.min(
                      competition?.competitionticketsize,
                      competition?.maxticketsperuser,
                      competition?.competitionticketsize -
                        competition?.totalticketsbought,
                      competition?.maxticketsperuser - competition?.userTickets
                    )} */}
                    {user ? maximumTickets : competition?.competitionticketsize}
                    )
                  </p>
                )}
              </button>
            </div>
            <div className="text-center mt-2 sm:mb-4">
              <a
                href="/policies/t&c#3.11"
                className="text-white underline text-[13px] sm:text-[13px] font-['Sequel100Wide']"
              >
                {"Click for free entry details".toUpperCase()}
              </a>
            </div>
          </div>
        </div>
        <div className="h-[15px]"></div>
      </div>

      {popupPrizes && <PopupPrizes onClose={closePopupPrizes} data={prizes} />}
      <BuyTicketsPopup
        open={openMcq}
        confirmationTitle={`CONFIRM YOU ACCEPT OUR <a href="/policies/t&c" class="text-blue-500 underline">TERMS & CONDITIONS</a> AND ARE HAPPY TO SPEND THE FOLLOWING AMOUNT OF TICKETS ON THE ENTRY TO:`}
        loading={loading}
        handleClose={() => {
          setOpenMcq(false);
          getCompetition(competition?.UID);
          setShowPopUp(false);
          setSuccess("");
          setError("");
          setBuying(false);
        }}
        noOfTickets={items.join(", ")}
        competitionName=""
        success={success}
        error={error}
        rejectClick={() => {
          setOpenMcq(false);
        }}
        acceptClick={() => {
          checkout();
        }}
        buying={false}
      />
      {buyTicketsPopup && (
        <PaymentModal
          setShowPopUp={setOpenMcq}
          openPaymentModal={handleButtonClick}
          isOpen={buyTicketsPopup}
          // value={value - (user?.numberOfTickets ?? 0)}
          value={items.length - (user?.numberOfTickets ?? 0)}
          onClose={buyTicketsPopupClose}
          enterCompetition={buyTicket}
        />
      )}

      {/* <WalletConnectPopup
        showWallet={showWallet}
        selectedNetwork={network || ""}
        setSelectedNetwork={setNetwork as any}
        dynamicWalletList={dynamicWalletList}
        connectWalletFunction={connectWalletFunction}
      /> */}
    </>
  );
};
