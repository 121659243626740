import React from "react";
import { useNavigate } from "react-router-dom";
import { openGlobalModal } from "./ClipboardModalContext";

interface AdvertBoxProps {
  instant?: boolean;
  connectWalletFunction?: any;
}

const AdvertBoxNew = ({ instant, connectWalletFunction }: AdvertBoxProps) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    const token = localStorage.getItem("token");

    if (token) {
      navigate("/buytickets");
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      if (connectWalletFunction) {
        connectWalletFunction(true);
      }
    }
  };

  return (
    <div
      className={` items-center bg-black xl:h-[700px] h-[860px] ${
        instant ? "w-[80%]" : "w-full"
      } py-[12%] sm:h-[411px] sm:flex sm:flex-col md:h-screen md:flex md:flex-col md:py-0 md:gap-5 md:pt-4 
      relative rounded-[28.59px]`}
      style={{
        backgroundImage: `url('/Images/btc-desk-resized.svg')`, // ✅ Use direct URL path
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="flex flex-col gap-1 ml-[5em] sm:text-center sm:w-full sm:ml-0 sm:items-center h-full sm:p-5 md:text-center md:w-full md:ml-0 md:items-center md:p-5">
        <h3
          className="text-white sm:text-[18px] md:text-[25px]"
          style={{
            fontSize: "clamp(1rem, 4.260140417vw, 35.47px)",
          }}
        >
          GET YOUR TICKETS FAST!
        </h3>
        <h1
          className="text-white sm:text-[35px] md:text-[50px] font-[Sequel100Wide95]"
          style={{
            fontSize: "clamp(1rem, 7.459207459vw, 72.41px)",
          }}
        >
          BEFORE THE <br />
          NEXT DRAW!
        </h1>
        <button
          className=" sm:mt-[36.79px] shadow-black shadow-lg bg-primary h-[80px] sm:h-[45.87px] sm:text-[1.3rem] px-10 sm:px-3 sm:w-max sm:px-[22px] rounded-full font-[Sequel100Wide95] text-black text-[28.53px] font-[900] w-fit sm:text-base border-2 border-white relative z-[87]"
          style={{
            fontSize: "clamp(1rem, 4.267120842vw, 35.53px)",
          }}
          onClick={handleButtonClick}
        >
          ENTER NOW
        </button>
      </div>
    </div>
  );
};

export default AdvertBoxNew;
