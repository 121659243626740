import React, { useEffect, useState } from "react";
import { Text } from "../react-responsive-utilities/src";
import { TicketData, TicketResponse } from "../NumberPicker";
import { getAvailableTickets } from "../../Services/userService";
import Entries from "../common/Entries/Entries";
import { Common } from "../common/Common";

const Title = ({ title }: { title: string }) => (
  <Text
    textSize={[30, 30.0, 25]}
    className="text-[#FFFFFF] sm:!text-[24px] font-['Sequel100Wide95'] //leading-[35px] //leading-normal font-[60] block sm:text-center md:text-center lg:text-center leading-none"
  >
    {title}
  </Text>
);

const Description = ({ description }: { description: string }) => (
  <Text
    dangerouslySetInnerHTML={{ __html: description }}
    textSize={[10, 14.0, 19.96]}
    className="
    text-[#FFFFFF] font-['Sequel100WideVF45'] sm:!leading-[19px] sm:!text-[12px] leading-[29px] font-[10] block sm:text-center md:text-center lg:text-center"
  ></Text>
);

const InstantCompetitionDetails = ({ competition }: { competition: any }) => {
  return (
    <div className="bg-[#1E1E1E] py-6 flex flex-col justify-center items-center w-full text-center">
      {/* <h1
        className={`pageHeading2 font-[Sequel100Wide95]`}
        style={{
          fontSize: "clamp(1px, 6.527976190476191vw, 35px)",
        }}
      >
        COMPETITION DETAILS
      </h1> */}

      <div
        className="gap-4 flex flex-col pr-[36px] sm_md:pr-2 sm_md:mt-[16.58px] max-h-[48rem] scrollbar-none overflow-auto m-12 mt-[20.63px] sm_md:m-3"
        style={{ paddingRight: "0px", overscrollBehavior: "none" }}
      >
        <div className="w-[936px] sm_md:w-[366px]">
          {/* <hr className="border-gray-400 border-2 my-4 mx-2" /> */}
          <Title title={competition?.competitionname} />
          <div className="mt-6">
            {/* <Description
              description={competition?.competitioninformationlong}
            /> */}
            <div
              className="quill_renderer"
              dangerouslySetInnerHTML={{
                __html:
                  competition?.competitioninformationlong !== null
                    ? `
                    <div class="quill_renderer quill_renderer_dark">
                    ${competition?.competitioninformationlong}
                    </div>`
                    : "",
              }}
            ></div>
          </div>
          {/* <div className="mt-6">
            <Common.typography
              fontFamily="Sequel100Wide95"
              fontSize="18px"
              fontSizeSmall="10px"
              color="#fff"
              className={`uppercase`}
            >
              Every ticket will win a minimum of £1.00 site credit!
            </Common.typography>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default InstantCompetitionDetails;
