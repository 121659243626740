import React from "react";
import { Common } from "../Common";
import VSpacer from "../Spacer/VSpacer";

interface PopupBoxProps {
  text: string;
  buttonText: string;
  onClick: () => void;
}
export default function PopupBox(props: PopupBoxProps) {
  return (
    <div className="h-screen w-screen fixed top-0 left-0 flex justify-center items-center z-[999]">
      <div className="p-3 rounded-[12.71px] xl:rounded-[16.11px] border-[1.27px] border-white max-w-[315px] min-h-[135px] xl:max-w-[400px] xl:min-h-[170px] flex flex-col justify-center items-center bg-primary">
        <Common.typography
          fontFamily="Sequel100WideVF45"
          fontSize="16.74px"
          fontSizeSmall="13px"
          color="#1A1A1A"
          className="text-center"
        >
          {props.text}
        </Common.typography>
        <VSpacer big={19.89} small={18.83} />
        <Common.button
          fontSize="16.11px"
          fontSizeSmall="12.71"
          fontFamily="Sequel100Wide95"
          roundness="4.03px"
          maxWidthBig="215.34px"
          maxWidthSmall="169.08px"
          heightBig="50.76px"
          heightSmall="40.04px"
          color="#FFFFFF"
          backgroundColor="#1A1A1A"
          onClick={props.onClick}
          border="0.81px solid white"
          className="uppercase"
        >
          {props.buttonText}
        </Common.button>
      </div>
    </div>
  );
}
