/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnchorProvider, Program, Wallet } from "@project-serum/anchor";
import {
  Connection,
  Keypair,
  PublicKey,
  SystemProgram,
  Transaction,
  VersionedTransaction,
  clusterApiUrl,
} from "@solana/web3.js";
import { WalletConnectModal } from "@walletconnect/modal";
import UniversalProvider from "@walletconnect/universal-provider";
import bs58 from "bs58";
import nacl from "tweetnacl";
import { solanaConfigs } from "../../../Config/blockchain.config";
import idl from "../../idl.json";

export enum SolanaChains {
  MainnetBeta = "5eykt4UsFv8P8NJdTREpY1vzqKqZKvdp",
  Devnet = "EtWTRABZaYq6iMfeYKouRu166VU2xqa1",
}
const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID as string;
const events: string[] = [];
const chains = [`solana:${SolanaChains.MainnetBeta}`];
const methods = ["solana_signMessage", "solana_signTransaction"];
const modal = new WalletConnectModal({
  projectId,
  chains,
});
const handleModalOpen = async (uri: string) => {
  await modal.openModal({
    uri,
  });
};

// Todo: add typescript method descriptors to control the flow, init should be called before any method invocation
export class WCSolanaAdapter {
  private walletState:
    | {
        connected: boolean;
        account: string | null;
        network: string | null;
        providerType: string | null;
      }
    | undefined;
  private provider?: UniversalProvider;

  constructor() {}

  async init() {
    if (!this.provider) {
      this.provider = await UniversalProvider.init({
        logger: "error",
        projectId: projectId,
        metadata: {
          name: "THEPRIZE",
          description:
            "THE PRIZE - The world's first crypto and luxury raffles platform.",
          url: "https://theprize.io/",
          icons: ["https://theprize.io/logo512.png"],
        },
      });
      this.provider.removeListener("display_uri", handleModalOpen);
      this.provider.on("display_uri", handleModalOpen);
      // this.provider.on("session_update", (session: any) => {
      //   localStorage.setItem("walletconnect-session", JSON.stringify(session));
      // });
      // @ts-ignore
      // this.provider.on("session_update", ({ topic, params }) => {
      //   console.log("session_update", topic, params);
      // });
    }
    return this.provider;
  }

  async connect(storedAccount?: string) {
    if (!this.provider) throw new Error("Solana adapter not initialised");
    try {
      const { RPC } = solanaConfigs;

      console.log(this.provider.session, "SESSION RESTORED");
      if (this.provider.session && !storedAccount) {
        await this.provider.disconnect();
      }
      if (!this.provider.session)
        await this.provider.connect({
          namespaces: {
            solana: {
              methods,
              chains,
              events,
              rpcMap: {
                101: RPC,
              },
            },
          },
        });

      const connectedAccount =
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        this.provider.session?.namespaces.solana?.accounts[0].split(":")[2];

      this.walletState = {
        connected: true,
        account: connectedAccount ?? "",
        network: "solana",
        providerType: "walletconnect",
      };
    } catch (err) {
      console.log(err, "error in connection of solana");
      throw new Error(err as any);
    }
    modal.closeModal();
  }

  async disconnect() {
    if (!this.provider) throw new Error("Solana adapter not initialised");
    await this.provider!.disconnect();
    this.walletState = undefined;
  }

  verifyMessageSignature(address: string, signature: string, message: string) {
    return nacl.sign.detached.verify(
      bs58.decode(message),
      bs58.decode(signature),
      bs58.decode(address)
    );
  }

  isVersionedTransaction = (
    transaction: Transaction | VersionedTransaction
  ): transaction is VersionedTransaction => "version" in transaction;

  async signMessage(message: string) {
    if (!this.provider) throw new Error("Solana adapter not initialised");
    try {
      if (this.walletState) {
        const senderPublicKey = new PublicKey(this.walletState.account ?? "");

        const msg = bs58.encode(new TextEncoder().encode(message));
        // Sign a message with the selected account
        const result = await this.provider!.request<{ signature: string }>({
          method: "solana_signMessage",
          params: {
            pubkey: senderPublicKey.toBase58(),
            message: msg,
          },
        });

        const valid = this.verifyMessageSignature(
          senderPublicKey.toBase58(),
          result.signature,
          message
        );

        console.log(valid, "Is valid");

        if (result.signature) {
          return result.signature;
        } else {
          throw new Error("Failed to generate signature.");
        }
      } else {
        throw new Error("Wallet not initialized");
      }
    } catch (err) {
      throw new Error(err as any);
    }
  }

  getWalletState() {
    return this.walletState;
  }

  async readContractMethod(
    contractAddress: string,
    abi: any[],
    methodName: string,
    args: any[] = []
  ): Promise<any> {
    console.log(contractAddress);
    console.log(abi);
    console.log(methodName);
    console.log(args);
  }

  private createAnchorWallet(walletProvider: any): Wallet {
    const dummyKeypair = Keypair.generate(); // Placeholder keypair for compatibility with Anchor Wallet
    return {
      payer: dummyKeypair,
      publicKey: new PublicKey(this.walletState?.account ?? ""), // Assumes a single connected account
      signTransaction: async (transaction: any) => {
        // Serialize the transaction for signing
        console.log(transaction, "trx here");
        const senderPublicKey = new PublicKey(this.walletState?.account ?? "");

        const rawTransaction = transaction
          .serialize({
            requireAllSignatures: false,
            verifySignatures: false,
          })
          .toString("base64");

        // Use WalletConnect JSON-RPC method to sign the transaction
        const signedTransaction = await walletProvider.request({
          method: "solana_signTransaction",
          params: { transaction: rawTransaction },
        });
        console.log(signedTransaction?.signature, "signature ");

        transaction.addSignature(
          senderPublicKey,
          Buffer.from(bs58.decode(signedTransaction.signature))
        );

        return transaction;
      },
      signAllTransactions: async (transactions: any[]) => {
        // Serialize all transactions for signing
        console.log("Transactions", transactions);
        throw new Error(
          "solana_signAllTransactions is not supported at the moment."
        );
      },
    };
  }

  private buildAccounts(
    accounts: { pubkey: PublicKey; propertyName: string }[]
  ): Record<string, PublicKey> {
    console.log(accounts, "acount");
    const accountMap: Record<string, PublicKey> = {};
    accounts.forEach(({ pubkey, propertyName }) => {
      accountMap[propertyName] = pubkey;
    });
    return accountMap;
  }

  async writeContractMethod(
    contractAddress: string,
    abi: any[],
    methodName: string,
    args: any[] = [],
    value: string = "0",
    additionalKeys: {
      pubkey: PublicKey;
      propertyName: string;
    }[] = []
  ): Promise<any> {
    console.log("Solana write contract method initialized", value);
    if (!this.provider || !this.walletState) {
      throw new Error("Wallet is not connected");
    }

    const { RPC } = solanaConfigs;
    const connection = new Connection(RPC);

    // Wrap WalletConnectProvider as an Anchor-compatible wallet
    const wallet = this.createAnchorWallet(this.provider);

    // Initialize the Anchor provider
    const anchorProvider = new AnchorProvider(connection, wallet, {
      commitment: "confirmed",
    });

    const programPublicKey = new PublicKey(contractAddress);
    const signerPublicKey = new PublicKey(this.walletState.account ?? "");
    // @ts-ignore
    const program = new Program(idl, programPublicKey, anchorProvider);

    const transaction = await program.methods[methodName](...args)
      .accounts({
        payer: signerPublicKey,
        systemProgram: SystemProgram.programId,
        ...this.buildAccounts(additionalKeys),
      })
      .transaction();

    transaction.recentBlockhash = (
      await connection.getLatestBlockhash("finalized")
    ).blockhash;
    transaction.feePayer = signerPublicKey;

    // Sign the transaction
    const signedTransaction = await wallet.signTransaction(transaction);

    // Send and confirm the transaction
    const txId = await connection.sendEncodedTransaction(
      signedTransaction.serialize().toString("base64"),
      {
        maxRetries: 3,
        skipPreflight: false,
        preflightCommitment: "confirmed",
      }
    );

    console.log("Transaction confirmed:", txId);

    return txId;
  }

  async sendTransaction(
    to: string,
    value: string,
    data?: string
  ): Promise<any> {
    console.log(to);
    console.log(value);
    console.log(data);
  }

  async getBalance(): Promise<number> {
    if (!this.walletState || !this.walletState.account) {
      throw new Error("Wallet not connected");
    }

    try {
      // Connect to the Solana network (using the Devnet or Mainnet as configured)
      const networkUrl =
        this.walletState.network === "solana"
          ? clusterApiUrl("devnet")
          : clusterApiUrl("mainnet-beta");

      const connection = new Connection(networkUrl, "confirmed");

      // Fetch the balance in lamports (smallest unit of SOL)
      const publicKey = new PublicKey(this.walletState.account);
      const balance = await connection.getBalance(publicKey);

      // Convert lamports to SOL (1 SOL = 1,000,000,000 lamports)
      const solBalance = balance / 1_000_000_000;
      console.log(`Balance: ${solBalance} SOL`);

      return solBalance;
    } catch (error) {
      console.error("Failed to fetch balance", error);
      throw new Error("Error fetching balance");
    }
  }

  async listenToContractEvent(
    contractAddress: string,
    abi: any[],
    eventName: string,
    callback: (eventData: any) => void
  ): Promise<void> {
    try {
      console.log(contractAddress);
      console.log(abi);
      console.log(eventName);
      console.log(callback);
    } catch (err) {
      console.log(err);
    }
  }

  async stopListeningToContractEvent(
    contractAddress: string,
    abi: any[],
    eventName: string
  ): Promise<void> {
    try {
      console.log(contractAddress);
      console.log(abi);
      console.log(eventName);
    } catch (err) {
      console.log(err);
    }
  }
}
