import React from "react";
import { Container, Text } from "../react-responsive-utilities/src";

export interface HowItWorksBoxProps {
  icon: string;
  title: string;
  content: string;
  index?: number;
}

const HowItWorksBox2: React.FC<HowItWorksBoxProps> = ({
  icon,
  title,
  content,
  index = 0,
}) => {
  const page = index + 1;
  return (
    <div className="font-['Sequel100WideVF'] overflow-hidden min-w-0 h-[15rem] sm_md:h-[15rem] xs:!h-[8rem] bg-[#454545] relative flex flex-col items-center justify-center //max-w-[465px] w-full gap-6 p-[1em] sm:w-[465px] rounded-3xl shadow-2xl shadow-black">
      <div className="rounded-full border-2 sm:border-[1px] border-white sm:w-[18px] sm:h-[18px] w-[2rem] h-[2rem] flex justify-center items-center text-[#fff] text-[12px] font-thin absolute left-4 top-4 sm:left-3 sm:top-3">
        {page}
      </div>
      <div className="flex flex-col w-full justify-center items-center sm:gap-0 gap-4 mt-2">
        <Container
          width={[0, 21.73, 92.64]}
          height={[0, 21.9, 60.56]}
          className="//sm:w-[64px] //h-[5rem] relative z-[80] sm:bottom-2"
        >
          <img src={icon} alt="" className="h-full w-full" />
        </Container>
        {/* <img
          src={"/Images/wheel_big.png"}
          className="absolute opacity-[0.2] w-[30rem] bottom-0 sm:top-10"
        /> */}

        <Text
          textSize={[0, 13, 25]}
          className="//text-[25px] leading-tight //sm:text-[15px] sm:bottom-6 text-white text-center md:w-full z-[80] font-['Sequel100Wide95'] md:!text-[2vw] mx-14 sm:mx-8"
        >
          {title}
        </Text>
      </div>
    </div>
  );
};

export default HowItWorksBox2;
