import React, { useEffect, useState } from "react";
import { Text } from "../react-responsive-utilities/src";
import VSpacer from "../common/Spacer/VSpacer";
import { Common } from "../common/Common";
import Carousel from "../common/Carousel/Carousel";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import AdvertBox from "./AdvertBox";
import AdvertBoxNew from "./AdvertBoxNew";

const PhoneStoresNew = ({
  background,
  instant,
}: {
  background: string;
  instant?: boolean;
}) => {
  // Define the BeforeInstallPromptEvent type if it's not available globally
  interface BeforeInstallPromptEvent extends Event {
    prompt: () => void;
    userChoice: Promise<{ outcome: string }>;
  }

  const [deferredPrompt, setDeferredPrompt] =
    useState<BeforeInstallPromptEvent | null>(null);
  const [isInstallable, setIsInstallable] = useState(false);

  const isXlScreen = useMediaQuery({ query: "(min-width: 1280px)" });

  // useEffect(() => {
  //     // Listen for the 'beforeinstallprompt' event
  //     const handleBeforeInstallPrompt = (event: {
  //         preventDefault: () => void;
  //     }) => {
  //         event.preventDefault(); // Prevent the mini-infobar from appearing
  //         setDeferredPrompt(event); // Save the event to trigger later
  //         setInstallable(true); // Show the install button
  //     };

  //     window.addEventListener(
  //         "beforeinstallprompt",
  //         handleBeforeInstallPrompt
  //     );

  //     return () => {
  //         window.removeEventListener(
  //             "beforeinstallprompt",
  //             handleBeforeInstallPrompt
  //         );
  //     };
  // }, []);

  const staticWinMobil = [
    {
      mobile: "/Images/game.png",
      text: "SMASH.",
      desc: "Step into the THEPRIZE.IO Safe Smash Telegram Game! Smash safes, earn coins, climb the leaderboard, and unlock epic prizes.",
      btnText: "PLAY THE GAME",
      link: "http://t.me/theprize_bot",
    },
    {
      mobile: "/Images/theprize.png",
      text: "SCORE.",
      btnText: "DOWNLOAD THE APP",
      desc: "Download our platform app to your home screen for instant access to competitions, live updates, and your shot at massive wins.",
      link: "",
    },
    {
      mobile: "/Images/telegram.png",
      text: "WIN.",
      btnText: "JOIN TELEGRAM GROUP",
      desc: "Join our Telegram channel to be the first to know about new draws, instant wins, and exclusive rewards.",
      link: "https://t.me/theprizeannouncements",
    },
  ];

  const staticWinWeb = [
    {
      desc: "Step into the THEPRIZE.IO Safe Smash Telegram Game! Smash safes, earn coins, climb the leaderboard, and unlock epic prizes.",
      btnText: "COMING SOON",
      btnHandler: () => {},
    },
    {
      desc: "Download our platform app to your home screen for instant access to competitions, live updates, and your shot at massive wins.",
      btnText: "COMING SOON",
      btnHandler: () => {},
    },
    {
      desc: "Join our Telegram channel to be the first to know about new draws, instant wins, and exclusive rewards.",
      btnText: "COMING SOON",
      btnHandler: () => {},
    },
  ];

  // const handleInstallClick = async () => {
  //     if (deferredPrompt) {
  //         deferredPrompt.prompt(); // Show the install prompt
  //         const { outcome } = await deferredPrompt.userChoice; // Wait for the user to respond
  //         if (outcome === "accepted") {
  //             console.log("User accepted the install prompt");
  //         } else {
  //             console.log("User dismissed the install prompt");
  //         }
  //         setDeferredPrompt(null); // Clear the deferred prompt
  //         setInstallable(false); // Hide the install button
  //     }
  // };

  const Title = ({ title }: { title: string }) => (
    <h1 className="text-[#323232] font-['Sequel100Wide85'] leading-[36px] sm:text-[30px] md:text-[42px] lg:text-[47px] xl:text-[60px] block text-center">
      {title}
    </h1>
  );

  const Description = ({ description }: { description: string }) => (
    <Text
      textSize={[14, 14.0, 19.96]}
      className="
        text-[#1A1A1A] font-['Sequel100WideVF45'] //leading-[17px] font-[16] block sm:text-center md:text-center"
    >
      {description}
    </Text>
  );

  useEffect(() => {
    // This is called when the 'beforeinstallprompt' event is fired
    const handleBeforeInstallPrompt = (event: Event) => {
      event.preventDefault(); // Prevent the default install prompt from appearing
      setDeferredPrompt(event as BeforeInstallPromptEvent); // Cast event to BeforeInstallPromptEvent
      setIsInstallable(true); // Show the install button
    };

    // Listen for 'beforeinstallprompt' event
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      // Show the install prompt
      deferredPrompt.prompt();

      // Handle the user's response
      deferredPrompt.userChoice
        .then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("User accepted the install prompt");
          } else {
            console.log("User dismissed the install prompt");
          }

          // Reset the prompt state after the user interacts
          setDeferredPrompt(null);
          setIsInstallable(false); // Optionally hide the button after installation
        })
        .catch((err) => {
          console.error("Error during installation prompt:", err);
        });
    }
  };

  return (
    <>
      <div
        style={{
          backgroundImage: isXlScreen
            ? "url('/Images/never_miss_bg.svg')"
            : undefined,
        }}
        className={` relative  bg-cover bg-center !h-fit xl:!h-[1368px] pt-[40px] w-full flex items-end justify-between sm:flex-col-reverse  md:flex-col-reverse  md:h-full md:pt-8 lg:flex-col-reverse  lg:h-full exact-1280:!flex-col-reverse  exact-1280:!h-full lg:pt-8`}
      >
        <div className=" w-full flex justify-center xl:-translate-y-[500px] px-[60px] z-[50]">
          <div className="xl:mb-20 h-full sm:flex sm:flex-col  md:flex md:flex-col md:items-center sm:items-center lg:flex lg:flex-col lg:items-center exact-1280:!flex exact-1280:!flex-col exact-1280:!items-center ">
            <Common.typography
              fontFamily="Sequel100Wide95"
              fontSize="66.1px"
              fontSizeSmall="clamp(22px,8vw,66px)"
              color="#fff"
              className="leading-none sm:w-full sm:text-center sm:max-h-[0px]  md:text-center lg:text-center xl:text-center exact-1280:!text-center uppercase //xl:mb-[56px] //lg_xl:mb-[23px]"
            >
              Never Miss A Win!
            </Common.typography>
            <div className="sm:h-[70px]"></div>
            {/* FOR DESKTOP VERSION */}

            <div className="flex  flex-col xl:block hidden">
              <img
                src="/Images/mobiles_xl_image.png"
                alt="Small Phones"
                className="h-[615px] w-full justify-self-start m-0 p-0 translate-y-32"
              />

              {/* <div className="bg-primary xl:h-[655px] xl:w-[100%] xl:block hidden rounded-[28.6px] pt-[62px] pb-[53px] px-[52.5px] shadow-[16px_16px_16px_rgba(0,0,0,0.5)]">
                                <Title title="SMASH. SCORE. WIN." />
                                <div className="h-[45px]"></div>
                                <div className="w-[331px] h-[272px]">
                                    <p className=" w-[331px] top-0 left-0 [font-family:'Sequel100Wide-45',Helvetica] font-normal text-[#1a1a1a] text-[28.6px] text-center tracking-[0] leading-[normal]">
                                        Step into the THEPRIZE.IO Safe Smash
                                        Telegram Game! Smash safes, earn coins,
                                        climb the leaderboard, and unlock epic
                                        prizes.
                                    </p>
                                </div>
                               
                            </div> */}

              <div className="main-container w-[1246px]  relative mx-auto my-0">
                <div className="w-[1246px]  text-[0px] bg-[#dde404] rounded-[28.596px] absolute top-0 left-1/2 translate-x-[-50%] translate-y-0 shadow-[20px_20px_40px_0_rgba(0,0,0,0.25)] z-[1]">
                  <span className="flex w-[814px] h-[71px] justify-center items-start font-['Sequel100Wide'] text-[60px] font-[50] leading-[70.8px] text-[#323232] relative text-center whitespace-nowrap z-[23] mt-[62px] mr-0 mb-0 ml-[215px]">
                    SMASH. SCORE. WIN.
                  </span>
                  {/* <img
                    src="/Images/vertical_line.svg"
                    className="w-[0.18%] h-[285px] mt-[180px] mb-[189px] bg-[length:100%_100%] bg-no-repeat absolute top-0 left-[431px] z-[4]"
                  />
                  <img
                    src="/Images/vertical_line.svg"
                    className="w-[0.18%] h-[285px] mt-[180px] mb-[189px] bg-[length:100%_100%] bg-no-repeat absolute top-0 left-[818px] z-[4]"
                  /> */}
                  <div className="flex w-[1140.438px] h-[77.63px] justify-between items-center relative z-[18] mt-[58.233px] mr-0 mb-0 ml-[52.562px]">
                    <div className="w-[365.923px] h-[77.626px] shrink-0 bg-[#212121] rounded-[19.51px] relative z-[8]">
                      <Link
                        to="http://t.me/theprize_bot"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="w-[365.923px] h-[77.626px] bg-[#212121] rounded-[19.51px] border-solid border-[1.744px] border-[#fff] relative shadow-[6.978px_6.978px_21.805px_0_rgba(0,0,0,0.3)] z-10 mt-0 mr-0 mb-0 ml-0">
                          <span className="flex w-[228px] h-[24px] justify-center items-start font-['Sequel100Wide'] text-[20px] font-[60] leading-[23.6px] text-[#fff] absolute top-[25.742px] left-[65.888px] text-center whitespace-nowrap z-[11]">
                            PLAY THE GAME
                          </span>
                        </div>{" "}
                      </Link>
                    </div>

                    <div
                      // onClick={handleInstallClick}
                      className="w-[365.923px] h-[77.626px] shrink-0 bg-[#212121] rounded-[19.51px] relative z-[13]"
                    >
                      <Link
                        to="https://t.me/theprizeannouncements"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="w-[365.923px] h-[77.626px] bg-[#212121] rounded-[19.51px] border-solid border-[1.744px] border-[#fff] relative shadow-[6.978px_6.978px_21.805px_0_rgba(0,0,0,0.3)] z-[15] mt-0 mr-0 mb-0 ml-0">
                          <span className="flex w-[295px] h-[24px] justify-center items-start font-['Sequel100Wide'] text-[20px] font-[60] leading-[23.6px] text-[#fff] absolute top-[25.742px] left-[32.888px] text-center whitespace-nowrap z-[16]">
                            JOIN TELEGRAM GROUP
                          </span>
                        </div>
                      </Link>
                    </div>

                    <div className="w-[365.923px] h-[77.626px] shrink-0 bg-[#dde404] rounded-[19.51px] relative z-[18]">
                      {/* <Link
                        to="https://t.me/theprizeannouncements"
                        target="_blank"
                        rel="noopener noreferrer"
                      > */}
                      <div className="w-[365.923px] h-[77.626px] bg-[#212121] rounded-[19.51px] border-solid border-[1.744px] border-[#fff] relative shadow-[6.978px_6.978px_21.805px_0_rgba(0,0,0,0.3)] z-20 mt-0 mr-0 mb-0 ml-0">
                        <span className="flex w-[294px] h-[24px] justify-center items-start font-['Sequel100Wide'] text-[20px] font-[60] leading-[23.6px] text-[#fff] absolute top-[25.742px] left-[32.888px] text-center whitespace-nowrap z-[21]">
                          {/* DOWNLOAD THE APP */}
                          COMING SOON
                        </span>
                      </div>{" "}
                      {/* </Link> */}
                    </div>
                  </div>
                  <div className="w-[1101px] h-[200px] relative z-[6] mt-[25.768px] mr-0 mb-0 ml-[69px]">
                    <span className="flex w-[331px] h-[272px] justify-center items-start font-['Sequel100WideVF45'] text-[28px] font-[10] leading-[33.743px] text-[#1a1a1a] absolute top-[12px] left-[calc(50%-550.5px)] text-center z-[2]">
                      Step into the THEPRIZE.IO Safe Smash Telegram Game!
                    </span>
                    <span className="flex w-[301px] h-[272px] justify-center items-start font-['Sequel100WideVF45'] text-[28px] font-[10] leading-[33.743px] text-[#1a1a1a] absolute top-[12px] left-[calc(50%-143.5px)] text-center z-[5]">
                      Join our Telegram channel to be the first to know about
                      exclusive offers.
                    </span>
                    <span className="flex w-[311px] h-[204px] justify-center items-start font-['Sequel100WideVF45'] text-[28px] font-[10] leading-[33.743px] text-[#1a1a1a] absolute top-[12px] left-[calc(50%--239.5px)] text-center z-[6]">
                      Download our platform app to your home screen for instant
                      access.
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* FOR MOBILE VERSION */}

            <div className="h-[1150px] sm:h-[880px] w-[85%] center xl:hidden ">
              <Carousel>
                {staticWinMobil.map((item, index) => (
                  <div>
                    <img
                      src={item.mobile}
                      alt="mobile"
                      className=" h-[600px] //lg:w-full sm:min-w-[350px] lg:w-[600px] md:w-[550px] mx-auto md: mt-0 sm:h-[400px]"
                    />
                    <div className="relative overflow-hidden text-center m-0 bg-primary h-[357px] mx-[7.5%]  w-[85%] px-[50px]  rounded-[18.61px] flex flex-col justify-center items-center">
                      {/* <VSpacer big={50} small={30} /> */}
                      {/* <Title title={item.text} /> */}
                      <Common.typography
                        className="uppercase"
                        fontSize="47.08px"
                        fontSizeSmall="47.08px"
                        color="#323232"
                        fontFamily="Sequel100Wide"
                      >
                        {item.text}
                      </Common.typography>
                      <VSpacer big={50} small={30} />
                      <Description description={item.desc} />
                      {/* <div className="h-[10%]"></div> */}
                      <VSpacer big={50} small={30} />
                      <button
                        className="bg-[#212121] xl:py-[13px] w-full rounded-[14px] border-2 border-white font-[Sequel100wide] text-white h-[56px]"
                        onClick={() => {
                          if (item?.text === "SCORE.") {
                            handleInstallClick();
                          } else {
                            window.open(
                              item.link,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }
                        }}
                      >
                        {item.btnText}
                      </button>
                    </div>
                    <div className="sm:h-[15px]"></div>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhoneStoresNew;
