import React, { useEffect } from "react";
import { Common } from "../../../common/Common";
import { copyToClipboard } from "../../../../utils/CopyToClipboard";

interface TableValueProps {
  copyable?: boolean;
  truncated?: boolean;
  title: string;
  isTXHash?: boolean;
  isWinner?: boolean;
}

export default function TableValue(props: TableValueProps) {
  const handleCopy = () => {
    if (props?.isTXHash) {
      copyToClipboard(`https://basescan.org/tx/${props.title}`);
      //alert("Copied to clipboard");
    } else {
      // navigator.clipboard.writeText(props.title);
      copyToClipboard(props?.title ?? "");
      // alert("Copied to clipboard");
    }
  };
  // Truncate logic
  const truncatedTitle =
    props.title.length > 10 ? props.title.slice(0, 10) + "..." : props.title;

  const displayTitle = props.truncated ? truncatedTitle : props.title;

  return (
    <div className="flex justify-center items-center gap-x-[18px]">
      <Common.typography
        fontFamily="Sequel100WideVF55"
        fontSize="22px"
        fontSizeSmall="16px"
        color={props?.isWinner ? "#DDE404" : "#ffffff"}
        className="uppercase"
      >
        {displayTitle}
      </Common.typography>
      {props.copyable && (
        <Common.image
          src="/Images/copy-white.svg"
          alt="copy"
          width="24px"
          height="24px"
          heightSmall="24px"
          widthSmall="24px"
          objectFit="contain"
          objectPosition="center"
          onClick={handleCopy}
        />
      )}
    </div>
  );
}
